.ratio {
  position: relative;
  display: block;
  overflow: hidden;
}

.ratio::before {
  display: block;
  width: 100%;
  content: "";
}

.ratio-1\:1::before {
  padding-bottom: 100%;
}

.ratio-2\:1::before {
  padding-bottom: 50%;
}

.ratio-3\:2::before {
  padding-bottom: 66.66667%;
}

.ratio-3\:4::before {
  padding-bottom: 133.33333%;
}

.ratio-4\:3::before {
  padding-bottom: 75%;
}

.ratio-4\:1::before {
  padding-bottom: 25%;
}

.ratio-5\:4::before {
  padding-bottom: 80%;
}

.ratio-8\:5::before {
  padding-bottom: 62.5%;
}

.ratio-15\:9::before {
  padding-bottom: 60%;
}

.ratio-16\:9::before {
  padding-bottom: 56.25%;
}

.ratio-64\:45::before {
  padding-bottom: 70.3125%;
}

.ratio-6\:5::before {
  padding-bottom: 83.33333%;
}

.ratio-23\:18::before {
  padding-bottom: 78.26087%;
}

.ratio-41\:35::before {
  padding-bottom: 85.36585%;
}

.ratio-41\:45::before {
  padding-bottom: 109.7561%;
}

.ratio-63\:55::before {
  padding-bottom: 87.30159%;
}

:root {
  --font-primary: "Jost", sans-serif;
}

:root {
  --text-4: 4px;
  --text-7: 7px;
  --text-10: 10px;
  --text-12: 12px;
  --text-13: 13px;
  --text-14: 14px;
  --text-15: 15px;
  --text-16: 16px;
  --text-18: 18px;
  --text-20: 20px;
  --text-22: 22px;
  --text-24: 24px;
  --text-25: 25px;
  --text-26: 26px;
  --text-30: 30px;
  --text-40: 40px;
  --text-50: 50px;
  --text-60: 60px;
  --text-80: 80px;
}

:root {
  --color-primary: #005f59;
  --color-secondary: #3d8581;
  --color-terracotta: #bc5f37;
  --color-ocre: #d09a36;
  --color-ocre-light: #fee7c0;
  --color-ocre-dark: #c68713;
  --color-white: #ffffff;
  --color-black: #000000;
  --color-border: #dddddd;
  --color-bg: #e3eae7;
  --color-bg-light: #e3eae7;
  --color-bg-dark: #c3d2d0;
  --color-titre-light: #e3eae7;
  --color-bloc: #f4f6f6;
  --color-sub: #a8cbc7;
  --color-text: #1b706a;
  --color-primary-1: #e1f0e8;
  --color-primary-2: #badccc;
  --color-primary-3: #5f9c99;
  --color-primary-4: #1b706a;
  --color-primary-l: #a4ccc5;
  --color-primary-d: #004541;
  --color-dark-1: #051036;
  --color-dark-2: #0d2857;
  --color-dark-3: #13357b;
  --color-dark-4: #163c8c;
  --color-light-1: #939cad;
  --color-light-2: #f5f5f5;
  --color-light-3: #fbfcff;
  --color-blue-1: #3554d1;
  --color-blue-2: #e5f0fd;
  --color-green-1: #ebfcea;
  --color-green-2: #008009;
  --color-yellow-1: #f8d448;
  --color-yellow-2: #e1c03f;
  --color-yellow-3: #ffc700;
  --color-yellow-4: #fff8dd;
  --color-brown-1: #923e01;
  --color-purple-1: #7e53f9;
  --color-red-1: #d93025;
  --color-red-2: #f1416c;
  --color-red-3: #fff5f8;
  --color-info-1: #cde9f6;
  --color-info-2: #4780aa;
  --color-warning-1: #f7f3d7;
  --color-warning-2: #927238;
  --color-danger: #fbddd1;
  --color-error-1: #ecc8c5;
  --color-error-2: #ab3331;
  --color-success: #70bd8a;
  --color-success-1: #def2d7;
  --color-success-2: #5b7052;
}

[data-anim-wrap] {
  pointer-events: none;
}

[data-anim-wrap].animated {
  pointer-events: auto;
}

@keyframes reveal {
  100% {
    opacity: 1;
  }
}

[data-anim^="slide-"],
[data-anim-child^="slide-"] {
  opacity: 0;
  transition-property: opacity, transform;
  pointer-events: none;
  transition-duration: 1s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

[data-anim^="slide-"].is-in-view,
[data-anim-child^="slide-"].is-in-view {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  pointer-events: auto;
}

[data-anim^="slide-up"],
[data-anim-child^="slide-up"] {
  transform: translate3d(0, 40px, 0);
}

[data-anim^="slide-down"],
[data-anim-child^="slide-down"] {
  transform: translate3d(0, -40px, 0);
}

[data-anim^="slide-right"],
[data-anim-child^="slide-right"] {
  transform: translate3d(-40px, 0, 0);
}

[data-anim^="slide-left"],
[data-anim-child^="slide-left"] {
  transform: translate3d(40px, 0, 0);
}

[data-anim^="fade"],
[data-anim-child^="fade"] {
  opacity: 0;
  transition-property: opacity;
  pointer-events: none;
  transition-duration: 0.8s;
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}

[data-anim^="fade"].is-in-view,
[data-anim-child^="fade"].is-in-view {
  opacity: 1;
  pointer-events: auto;
}

[data-anim*="delay-1"],
[data-anim-child*="delay-1"] {
  transition-delay: 0.1s;
  animation-delay: 0.1s;
}

[data-anim*="delay-1"]::after,
[data-anim-child*="delay-1"]::after {
  animation-delay: 0.1s;
}

[data-anim*="delay-1"] > *,
[data-anim-child*="delay-1"] > * {
  animation-delay: 0.1s;
}

[data-anim*="delay-2"],
[data-anim-child*="delay-2"] {
  transition-delay: 0.2s;
  animation-delay: 0.2s;
}

[data-anim*="delay-2"]::after,
[data-anim-child*="delay-2"]::after {
  animation-delay: 0.2s;
}

[data-anim*="delay-2"] > *,
[data-anim-child*="delay-2"] > * {
  animation-delay: 0.2s;
}

[data-anim*="delay-3"],
[data-anim-child*="delay-3"] {
  transition-delay: 0.3s;
  animation-delay: 0.3s;
}

[data-anim*="delay-3"]::after,
[data-anim-child*="delay-3"]::after {
  animation-delay: 0.3s;
}

[data-anim*="delay-3"] > *,
[data-anim-child*="delay-3"] > * {
  animation-delay: 0.3s;
}

[data-anim*="delay-4"],
[data-anim-child*="delay-4"] {
  transition-delay: 0.4s;
  animation-delay: 0.4s;
}

[data-anim*="delay-4"]::after,
[data-anim-child*="delay-4"]::after {
  animation-delay: 0.4s;
}

[data-anim*="delay-4"] > *,
[data-anim-child*="delay-4"] > * {
  animation-delay: 0.4s;
}

[data-anim*="delay-5"],
[data-anim-child*="delay-5"] {
  transition-delay: 0.5s;
  animation-delay: 0.5s;
}

[data-anim*="delay-5"]::after,
[data-anim-child*="delay-5"]::after {
  animation-delay: 0.5s;
}

[data-anim*="delay-5"] > *,
[data-anim-child*="delay-5"] > * {
  animation-delay: 0.5s;
}

[data-anim*="delay-6"],
[data-anim-child*="delay-6"] {
  transition-delay: 0.6s;
  animation-delay: 0.6s;
}

[data-anim*="delay-6"]::after,
[data-anim-child*="delay-6"]::after {
  animation-delay: 0.6s;
}

[data-anim*="delay-6"] > *,
[data-anim-child*="delay-6"] > * {
  animation-delay: 0.6s;
}

[data-anim*="delay-7"],
[data-anim-child*="delay-7"] {
  transition-delay: 0.7s;
  animation-delay: 0.7s;
}

[data-anim*="delay-7"]::after,
[data-anim-child*="delay-7"]::after {
  animation-delay: 0.7s;
}

[data-anim*="delay-7"] > *,
[data-anim-child*="delay-7"] > * {
  animation-delay: 0.7s;
}

[data-anim*="delay-8"],
[data-anim-child*="delay-8"] {
  transition-delay: 0.8s;
  animation-delay: 0.8s;
}

[data-anim*="delay-8"]::after,
[data-anim-child*="delay-8"]::after {
  animation-delay: 0.8s;
}

[data-anim*="delay-8"] > *,
[data-anim-child*="delay-8"] > * {
  animation-delay: 0.8s;
}

[data-anim*="delay-9"],
[data-anim-child*="delay-9"] {
  transition-delay: 0.9s;
  animation-delay: 0.9s;
}

[data-anim*="delay-9"]::after,
[data-anim-child*="delay-9"]::after {
  animation-delay: 0.9s;
}

[data-anim*="delay-9"] > *,
[data-anim-child*="delay-9"] > * {
  animation-delay: 0.9s;
}

[data-anim*="delay-10"],
[data-anim-child*="delay-10"] {
  transition-delay: 1s;
  animation-delay: 1s;
}

[data-anim*="delay-10"]::after,
[data-anim-child*="delay-10"]::after {
  animation-delay: 1s;
}

[data-anim*="delay-10"] > *,
[data-anim-child*="delay-10"] > * {
  animation-delay: 1s;
}

[data-anim*="delay-11"],
[data-anim-child*="delay-11"] {
  transition-delay: 1.1s;
  animation-delay: 1.1s;
}

[data-anim*="delay-11"]::after,
[data-anim-child*="delay-11"]::after {
  animation-delay: 1.1s;
}

[data-anim*="delay-11"] > *,
[data-anim-child*="delay-11"] > * {
  animation-delay: 1.1s;
}

[data-anim*="delay-12"],
[data-anim-child*="delay-12"] {
  transition-delay: 1.2s;
  animation-delay: 1.2s;
}

[data-anim*="delay-12"]::after,
[data-anim-child*="delay-12"]::after {
  animation-delay: 1.2s;
}

[data-anim*="delay-12"] > *,
[data-anim-child*="delay-12"] > * {
  animation-delay: 1.2s;
}

[data-anim*="delay-13"],
[data-anim-child*="delay-13"] {
  transition-delay: 1.3s;
  animation-delay: 1.3s;
}

[data-anim*="delay-13"]::after,
[data-anim-child*="delay-13"]::after {
  animation-delay: 1.3s;
}

[data-anim*="delay-13"] > *,
[data-anim-child*="delay-13"] > * {
  animation-delay: 1.3s;
}

[data-anim*="delay-14"],
[data-anim-child*="delay-14"] {
  transition-delay: 1.4s;
  animation-delay: 1.4s;
}

[data-anim*="delay-14"]::after,
[data-anim-child*="delay-14"]::after {
  animation-delay: 1.4s;
}

[data-anim*="delay-14"] > *,
[data-anim-child*="delay-14"] > * {
  animation-delay: 1.4s;
}

[data-anim*="delay-15"],
[data-anim-child*="delay-15"] {
  transition-delay: 1.5s;
  animation-delay: 1.5s;
}

[data-anim*="delay-15"]::after,
[data-anim-child*="delay-15"]::after {
  animation-delay: 1.5s;
}

[data-anim*="delay-15"] > *,
[data-anim-child*="delay-15"] > * {
  animation-delay: 1.5s;
}

[data-anim*="delay-16"],
[data-anim-child*="delay-16"] {
  transition-delay: 1.6s;
  animation-delay: 1.6s;
}

[data-anim*="delay-16"]::after,
[data-anim-child*="delay-16"]::after {
  animation-delay: 1.6s;
}

[data-anim*="delay-16"] > *,
[data-anim-child*="delay-16"] > * {
  animation-delay: 1.6s;
}

[data-anim*="delay-17"],
[data-anim-child*="delay-17"] {
  transition-delay: 1.7s;
  animation-delay: 1.7s;
}

[data-anim*="delay-17"]::after,
[data-anim-child*="delay-17"]::after {
  animation-delay: 1.7s;
}

[data-anim*="delay-17"] > *,
[data-anim-child*="delay-17"] > * {
  animation-delay: 1.7s;
}

[data-anim*="delay-18"],
[data-anim-child*="delay-18"] {
  transition-delay: 1.8s;
  animation-delay: 1.8s;
}

[data-anim*="delay-18"]::after,
[data-anim-child*="delay-18"]::after {
  animation-delay: 1.8s;
}

[data-anim*="delay-18"] > *,
[data-anim-child*="delay-18"] > * {
  animation-delay: 1.8s;
}

[data-anim*="delay-19"],
[data-anim-child*="delay-19"] {
  transition-delay: 1.9s;
  animation-delay: 1.9s;
}

[data-anim*="delay-19"]::after,
[data-anim-child*="delay-19"]::after {
  animation-delay: 1.9s;
}

[data-anim*="delay-19"] > *,
[data-anim-child*="delay-19"] > * {
  animation-delay: 1.9s;
}

[data-anim*="delay-20"],
[data-anim-child*="delay-20"] {
  transition-delay: 2s;
  animation-delay: 2s;
}

[data-anim*="delay-20"]::after,
[data-anim-child*="delay-20"]::after {
  animation-delay: 2s;
}

[data-anim*="delay-20"] > *,
[data-anim-child*="delay-20"] > * {
  animation-delay: 2s;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
}

body {
  padding: 0;
  font-family: var(--font-primary);
  width: 100%;
  background-color: white;
  color: var(--color-dark-1);
  font-size: 16px;
  line-height: 1.875;
}

select,
input:not([type="range"]),
textarea {
  border: 0;
  outline: none;
  width: 100%;
  background-color: transparent;
}

main {
  overflow: hidden;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  transition: color 0.2s ease-in-out;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

ul, li {
  list-style: none;
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
  font-size: 16px;
  color: var(--color-light-1);
}

button {
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
  background-color: transparent;
  text-decoration: none;
}

button:focus {
  outline: 0;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  text-align: center;
  line-height: 1;
  font-weight: 500;
  font-size: 15px;
  line-height: 1.2;
  border-radius: 4px;
  border: 1px solid transparent;
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.button.-md {
  padding: 14px 30px;
}

.button.-lg {
  padding: 19px 30px;
}

.button.-min-180 {
  min-width: 180px;
}

.button.-underline:hover {
  text-decoration: underline;
}

.button.-primary:hover {
  border-color: var(--color-primary);
  background-color: var(--color-primary) !important;
  color: white !important;
}

.button.-outline-primary {
  border-color: var(--color-primary);
}

.button.-outline-primary:hover {
  background-color: var(--color-primary);
  border-color: transparent;
  color: white !important;
}

.button.-secondary:hover {
  border-color: var(--color-secondary);
  background-color: var(--color-secondary) !important;
  color: white !important;
}

.button.-outline-secondary {
  border-color: var(--color-secondary);
}

.button.-outline-secondary:hover {
  background-color: var(--color-secondary);
  border-color: transparent;
  color: white !important;
}

.button.-terracotta:hover {
  border-color: var(--color-terracotta);
  background-color: var(--color-terracotta) !important;
  color: white !important;
}

.button.-outline-terracotta {
  border-color: var(--color-terracotta);
}

.button.-outline-terracotta:hover {
  background-color: var(--color-terracotta);
  border-color: transparent;
  color: white !important;
}

.button.-ocre:hover {
  border-color: var(--color-ocre);
  background-color: var(--color-ocre) !important;
  color: white !important;
}

.button.-outline-ocre {
  border-color: var(--color-ocre);
}

.button.-outline-ocre:hover {
  background-color: var(--color-ocre);
  border-color: transparent;
  color: white !important;
}

.button.-ocre-light:hover {
  border-color: var(--color-ocre-light);
  background-color: var(--color-ocre-light) !important;
  color: white !important;
}

.button.-outline-ocre-light {
  border-color: var(--color-ocre-light);
}

.button.-outline-ocre-light:hover {
  background-color: var(--color-ocre-light);
  border-color: transparent;
  color: white !important;
}

.button.-ocre-dark:hover {
  border-color: var(--color-ocre-dark);
  background-color: var(--color-ocre-dark) !important;
  color: white !important;
}

.button.-outline-ocre-dark {
  border-color: var(--color-ocre-dark);
}

.button.-outline-ocre-dark:hover {
  background-color: var(--color-ocre-dark);
  border-color: transparent;
  color: white !important;
}

.button.-white:hover {
  border-color: var(--color-white);
  background-color: var(--color-white) !important;
  color: white !important;
}

.button.-outline-white {
  border-color: var(--color-white);
}

.button.-outline-white:hover {
  background-color: var(--color-white);
  border-color: transparent;
  color: white !important;
}

.button.-black:hover {
  border-color: var(--color-black);
  background-color: var(--color-black) !important;
  color: white !important;
}

.button.-outline-black {
  border-color: var(--color-black);
}

.button.-outline-black:hover {
  background-color: var(--color-black);
  border-color: transparent;
  color: white !important;
}

.button.-border:hover {
  border-color: var(--color-border);
  background-color: var(--color-border) !important;
  color: white !important;
}

.button.-outline-border {
  border-color: var(--color-border);
}

.button.-outline-border:hover {
  background-color: var(--color-border);
  border-color: transparent;
  color: white !important;
}

.button.-bg:hover {
  border-color: var(--color-bg);
  background-color: var(--color-bg) !important;
  color: white !important;
}

.button.-outline-bg {
  border-color: var(--color-bg);
}

.button.-outline-bg:hover {
  background-color: var(--color-bg);
  border-color: transparent;
  color: white !important;
}

.button.-bg-light:hover {
  border-color: var(--color-bg-light);
  background-color: var(--color-bg-light) !important;
  color: white !important;
}

.button.-outline-bg-light {
  border-color: var(--color-bg-light);
}

.button.-outline-bg-light:hover {
  background-color: var(--color-bg-light);
  border-color: transparent;
  color: white !important;
}

.button.-bg-dark:hover {
  border-color: var(--color-bg-dark);
  background-color: var(--color-bg-dark) !important;
  color: white !important;
}

.button.-outline-bg-dark {
  border-color: var(--color-bg-dark);
}

.button.-outline-bg-dark:hover {
  background-color: var(--color-bg-dark);
  border-color: transparent;
  color: white !important;
}

.button.-titre-light:hover {
  border-color: var(--color-titre-light);
  background-color: var(--color-titre-light) !important;
  color: white !important;
}

.button.-outline-titre-light {
  border-color: var(--color-titre-light);
}

.button.-outline-titre-light:hover {
  background-color: var(--color-titre-light);
  border-color: transparent;
  color: white !important;
}

.button.-bloc:hover {
  border-color: var(--color-bloc);
  background-color: var(--color-bloc) !important;
  color: white !important;
}

.button.-outline-bloc {
  border-color: var(--color-bloc);
}

.button.-outline-bloc:hover {
  background-color: var(--color-bloc);
  border-color: transparent;
  color: white !important;
}

.button.-sub:hover {
  border-color: var(--color-sub);
  background-color: var(--color-sub) !important;
  color: white !important;
}

.button.-outline-sub {
  border-color: var(--color-sub);
}

.button.-outline-sub:hover {
  background-color: var(--color-sub);
  border-color: transparent;
  color: white !important;
}

.button.-text:hover {
  border-color: var(--color-text);
  background-color: var(--color-text) !important;
  color: white !important;
}

.button.-outline-text {
  border-color: var(--color-text);
}

.button.-outline-text:hover {
  background-color: var(--color-text);
  border-color: transparent;
  color: white !important;
}

.button.-primary-1:hover {
  border-color: var(--color-primary-1);
  background-color: var(--color-primary-1) !important;
  color: white !important;
}

.button.-outline-primary-1 {
  border-color: var(--color-primary-1);
}

.button.-outline-primary-1:hover {
  background-color: var(--color-primary-1);
  border-color: transparent;
  color: white !important;
}

.button.-primary-2:hover {
  border-color: var(--color-primary-2);
  background-color: var(--color-primary-2) !important;
  color: white !important;
}

.button.-outline-primary-2 {
  border-color: var(--color-primary-2);
}

.button.-outline-primary-2:hover {
  background-color: var(--color-primary-2);
  border-color: transparent;
  color: white !important;
}

.button.-primary-3:hover {
  border-color: var(--color-primary-3);
  background-color: var(--color-primary-3) !important;
  color: white !important;
}

.button.-outline-primary-3 {
  border-color: var(--color-primary-3);
}

.button.-outline-primary-3:hover {
  background-color: var(--color-primary-3);
  border-color: transparent;
  color: white !important;
}

.button.-primary-4:hover {
  border-color: var(--color-primary-4);
  background-color: var(--color-primary-4) !important;
  color: white !important;
}

.button.-outline-primary-4 {
  border-color: var(--color-primary-4);
}

.button.-outline-primary-4:hover {
  background-color: var(--color-primary-4);
  border-color: transparent;
  color: white !important;
}

.button.-primary-l:hover {
  border-color: var(--color-primary-l);
  background-color: var(--color-primary-l) !important;
  color: white !important;
}

.button.-outline-primary-l {
  border-color: var(--color-primary-l);
}

.button.-outline-primary-l:hover {
  background-color: var(--color-primary-l);
  border-color: transparent;
  color: white !important;
}

.button.-primary-d:hover {
  border-color: var(--color-primary-d);
  background-color: var(--color-primary-d) !important;
  color: white !important;
}

.button.-outline-primary-d {
  border-color: var(--color-primary-d);
}

.button.-outline-primary-d:hover {
  background-color: var(--color-primary-d);
  border-color: transparent;
  color: white !important;
}

.button.-dark-1:hover {
  border-color: var(--color-dark-1);
  background-color: var(--color-dark-1) !important;
  color: white !important;
}

.button.-outline-dark-1 {
  border-color: var(--color-dark-1);
}

.button.-outline-dark-1:hover {
  background-color: var(--color-dark-1);
  border-color: transparent;
  color: white !important;
}

.button.-dark-2:hover {
  border-color: var(--color-dark-2);
  background-color: var(--color-dark-2) !important;
  color: white !important;
}

.button.-outline-dark-2 {
  border-color: var(--color-dark-2);
}

.button.-outline-dark-2:hover {
  background-color: var(--color-dark-2);
  border-color: transparent;
  color: white !important;
}

.button.-dark-3:hover {
  border-color: var(--color-dark-3);
  background-color: var(--color-dark-3) !important;
  color: white !important;
}

.button.-outline-dark-3 {
  border-color: var(--color-dark-3);
}

.button.-outline-dark-3:hover {
  background-color: var(--color-dark-3);
  border-color: transparent;
  color: white !important;
}

.button.-dark-4:hover {
  border-color: var(--color-dark-4);
  background-color: var(--color-dark-4) !important;
  color: white !important;
}

.button.-outline-dark-4 {
  border-color: var(--color-dark-4);
}

.button.-outline-dark-4:hover {
  background-color: var(--color-dark-4);
  border-color: transparent;
  color: white !important;
}

.button.-light-1:hover {
  border-color: var(--color-light-1);
  background-color: var(--color-light-1) !important;
  color: white !important;
}

.button.-outline-light-1 {
  border-color: var(--color-light-1);
}

.button.-outline-light-1:hover {
  background-color: var(--color-light-1);
  border-color: transparent;
  color: white !important;
}

.button.-light-2:hover {
  border-color: var(--color-light-2);
  background-color: var(--color-light-2) !important;
  color: white !important;
}

.button.-outline-light-2 {
  border-color: var(--color-light-2);
}

.button.-outline-light-2:hover {
  background-color: var(--color-light-2);
  border-color: transparent;
  color: white !important;
}

.button.-light-3:hover {
  border-color: var(--color-light-3);
  background-color: var(--color-light-3) !important;
  color: white !important;
}

.button.-outline-light-3 {
  border-color: var(--color-light-3);
}

.button.-outline-light-3:hover {
  background-color: var(--color-light-3);
  border-color: transparent;
  color: white !important;
}

.button.-blue-1:hover {
  border-color: var(--color-blue-1);
  background-color: var(--color-blue-1) !important;
  color: white !important;
}

.button.-outline-blue-1 {
  border-color: var(--color-blue-1);
}

.button.-outline-blue-1:hover {
  background-color: var(--color-blue-1);
  border-color: transparent;
  color: white !important;
}

.button.-blue-2:hover {
  border-color: var(--color-blue-2);
  background-color: var(--color-blue-2) !important;
  color: white !important;
}

.button.-outline-blue-2 {
  border-color: var(--color-blue-2);
}

.button.-outline-blue-2:hover {
  background-color: var(--color-blue-2);
  border-color: transparent;
  color: white !important;
}

.button.-green-1:hover {
  border-color: var(--color-green-1);
  background-color: var(--color-green-1) !important;
  color: white !important;
}

.button.-outline-green-1 {
  border-color: var(--color-green-1);
}

.button.-outline-green-1:hover {
  background-color: var(--color-green-1);
  border-color: transparent;
  color: white !important;
}

.button.-green-2:hover {
  border-color: var(--color-green-2);
  background-color: var(--color-green-2) !important;
  color: white !important;
}

.button.-outline-green-2 {
  border-color: var(--color-green-2);
}

.button.-outline-green-2:hover {
  background-color: var(--color-green-2);
  border-color: transparent;
  color: white !important;
}

.button.-yellow-1:hover {
  border-color: var(--color-yellow-1);
  background-color: var(--color-yellow-1) !important;
  color: white !important;
}

.button.-outline-yellow-1 {
  border-color: var(--color-yellow-1);
}

.button.-outline-yellow-1:hover {
  background-color: var(--color-yellow-1);
  border-color: transparent;
  color: white !important;
}

.button.-yellow-2:hover {
  border-color: var(--color-yellow-2);
  background-color: var(--color-yellow-2) !important;
  color: white !important;
}

.button.-outline-yellow-2 {
  border-color: var(--color-yellow-2);
}

.button.-outline-yellow-2:hover {
  background-color: var(--color-yellow-2);
  border-color: transparent;
  color: white !important;
}

.button.-yellow-3:hover {
  border-color: var(--color-yellow-3);
  background-color: var(--color-yellow-3) !important;
  color: white !important;
}

.button.-outline-yellow-3 {
  border-color: var(--color-yellow-3);
}

.button.-outline-yellow-3:hover {
  background-color: var(--color-yellow-3);
  border-color: transparent;
  color: white !important;
}

.button.-yellow-4:hover {
  border-color: var(--color-yellow-4);
  background-color: var(--color-yellow-4) !important;
  color: white !important;
}

.button.-outline-yellow-4 {
  border-color: var(--color-yellow-4);
}

.button.-outline-yellow-4:hover {
  background-color: var(--color-yellow-4);
  border-color: transparent;
  color: white !important;
}

.button.-brown-1:hover {
  border-color: var(--color-brown-1);
  background-color: var(--color-brown-1) !important;
  color: white !important;
}

.button.-outline-brown-1 {
  border-color: var(--color-brown-1);
}

.button.-outline-brown-1:hover {
  background-color: var(--color-brown-1);
  border-color: transparent;
  color: white !important;
}

.button.-purple-1:hover {
  border-color: var(--color-purple-1);
  background-color: var(--color-purple-1) !important;
  color: white !important;
}

.button.-outline-purple-1 {
  border-color: var(--color-purple-1);
}

.button.-outline-purple-1:hover {
  background-color: var(--color-purple-1);
  border-color: transparent;
  color: white !important;
}

.button.-red-1:hover {
  border-color: var(--color-red-1);
  background-color: var(--color-red-1) !important;
  color: white !important;
}

.button.-outline-red-1 {
  border-color: var(--color-red-1);
}

.button.-outline-red-1:hover {
  background-color: var(--color-red-1);
  border-color: transparent;
  color: white !important;
}

.button.-red-2:hover {
  border-color: var(--color-red-2);
  background-color: var(--color-red-2) !important;
  color: white !important;
}

.button.-outline-red-2 {
  border-color: var(--color-red-2);
}

.button.-outline-red-2:hover {
  background-color: var(--color-red-2);
  border-color: transparent;
  color: white !important;
}

.button.-red-3:hover {
  border-color: var(--color-red-3);
  background-color: var(--color-red-3) !important;
  color: white !important;
}

.button.-outline-red-3 {
  border-color: var(--color-red-3);
}

.button.-outline-red-3:hover {
  background-color: var(--color-red-3);
  border-color: transparent;
  color: white !important;
}

.button.-info-1:hover {
  border-color: var(--color-info-1);
  background-color: var(--color-info-1) !important;
  color: white !important;
}

.button.-outline-info-1 {
  border-color: var(--color-info-1);
}

.button.-outline-info-1:hover {
  background-color: var(--color-info-1);
  border-color: transparent;
  color: white !important;
}

.button.-info-2:hover {
  border-color: var(--color-info-2);
  background-color: var(--color-info-2) !important;
  color: white !important;
}

.button.-outline-info-2 {
  border-color: var(--color-info-2);
}

.button.-outline-info-2:hover {
  background-color: var(--color-info-2);
  border-color: transparent;
  color: white !important;
}

.button.-warning-1:hover {
  border-color: var(--color-warning-1);
  background-color: var(--color-warning-1) !important;
  color: white !important;
}

.button.-outline-warning-1 {
  border-color: var(--color-warning-1);
}

.button.-outline-warning-1:hover {
  background-color: var(--color-warning-1);
  border-color: transparent;
  color: white !important;
}

.button.-warning-2:hover {
  border-color: var(--color-warning-2);
  background-color: var(--color-warning-2) !important;
  color: white !important;
}

.button.-outline-warning-2 {
  border-color: var(--color-warning-2);
}

.button.-outline-warning-2:hover {
  background-color: var(--color-warning-2);
  border-color: transparent;
  color: white !important;
}

.button.-danger:hover {
  border-color: var(--color-danger);
  background-color: var(--color-danger) !important;
  color: white !important;
}

.button.-outline-danger {
  border-color: var(--color-danger);
}

.button.-outline-danger:hover {
  background-color: var(--color-danger);
  border-color: transparent;
  color: white !important;
}

.button.-error-1:hover {
  border-color: var(--color-error-1);
  background-color: var(--color-error-1) !important;
  color: white !important;
}

.button.-outline-error-1 {
  border-color: var(--color-error-1);
}

.button.-outline-error-1:hover {
  background-color: var(--color-error-1);
  border-color: transparent;
  color: white !important;
}

.button.-error-2:hover {
  border-color: var(--color-error-2);
  background-color: var(--color-error-2) !important;
  color: white !important;
}

.button.-outline-error-2 {
  border-color: var(--color-error-2);
}

.button.-outline-error-2:hover {
  background-color: var(--color-error-2);
  border-color: transparent;
  color: white !important;
}

.button.-success:hover {
  border-color: var(--color-success);
  background-color: var(--color-success) !important;
  color: white !important;
}

.button.-outline-success {
  border-color: var(--color-success);
}

.button.-outline-success:hover {
  background-color: var(--color-success);
  border-color: transparent;
  color: white !important;
}

.button.-success-1:hover {
  border-color: var(--color-success-1);
  background-color: var(--color-success-1) !important;
  color: white !important;
}

.button.-outline-success-1 {
  border-color: var(--color-success-1);
}

.button.-outline-success-1:hover {
  background-color: var(--color-success-1);
  border-color: transparent;
  color: white !important;
}

.button.-success-2:hover {
  border-color: var(--color-success-2);
  background-color: var(--color-success-2) !important;
  color: white !important;
}

.button.-outline-success-2 {
  border-color: var(--color-success-2);
}

.button.-outline-success-2:hover {
  background-color: var(--color-success-2);
  border-color: transparent;
  color: white !important;
}

.button.-white:hover {
  background-color: var(--color-light-3) !important;
  color: var(--color-dark-1) !important;
}

.button.-blue-1-05:hover {
  background-color: rgba(53, 84, 209, 0.05) !important;
  color: var(--color-blue-1) !important;
}

.button.-outline-white:hover {
  background-color: white !important;
  color: var(--color-dark-1) !important;
}

.contact-form select,
.contact-form input:not([type="range"]),
.contact-form textarea {
  border-radius: 4px;
  padding: 17px 20px;
  font-size: 15px;
  letter-spacing: 0.02em;
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.contact-form select:focus,
.contact-form input:not([type="range"]):focus,
.contact-form textarea:focus {
  outline: none;
}

.contact-form ::placeholder {
  font-weight: 400;
}

input.text-white::placeholder {
  color: white;
}

.form-checkbox {
  cursor: pointer;
  position: relative;
  display: flex;
}

.form-checkbox label {
  margin: 0;
  margin-left: 9px;
  font-size: 14px;
}

.form-checkbox__mark {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  border: 1px solid var(--color-dark-1);
  border-radius: 4px;
}

.form-checkbox__icon {
  display: none;
  position: absolute;
  font-size: 8px;
  color: white;
}

.form-checkbox input {
  cursor: pointer;
  z-index: 5;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
}

.form-checkbox input:checked ~ .form-checkbox__mark {
  background-color: var(--color-blue-1);
  border-color: var(--color-blue-1);
}

.form-checkbox input:checked ~ .form-checkbox__mark .form-checkbox__icon {
  display: block;
}

.form-checkbox:hover input ~ .form-checkbox__mark {
  background-color: var(--color-blue-1);
}

.form-checkbox:hover input:checked ~ .form-checkbox__mark {
  background-color: var(--color-blue-1);
}

.form-radio {
  display: flex;
  align-items: center;
}

.form-radio .radio {
  cursor: pointer;
  position: relative;
  display: block;
}

.form-radio .radio__mark {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 16px;
  width: 16px;
  border-radius: 100%;
  border: 1px solid var(--color-dark-1);
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.form-radio .radio__icon {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: var(--color-white);
  transform-origin: center;
  opacity: 1;
  transform: scale(0.2);
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.form-radio .radio input {
  cursor: pointer;
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
}

.form-radio .radio input:checked ~ .radio__mark {
  border-color: var(--color-blue-1);
  background-color: var(--color-blue-1);
}

.form-radio .radio input:checked ~ .radio__mark .radio__icon {
  opacity: 1;
  transform: scale(1);
}

.form-switch .switch {
  position: relative;
  width: 60px;
  height: 30px;
}

.form-switch .switch__slider {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: 0px 10px 50px rgba(13, 38, 59, 0.1);
  background-color: rgba(255, 255, 255, 0.2);
  transition: .3s;
  border-radius: 30px;
  border: 1px solid var(--color-blue-1);
}

.form-switch .switch__slider::before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 5px;
  bottom: 4px;
  background-color: var(--color-blue-1);
  border-radius: 50%;
  transition: .3s;
}

.form-switch .switch input {
  z-index: 5;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.form-switch .switch input:checked + .switch__slider {
  background-color: var(--color-blue-1);
}

.form-switch .switch input:checked + .switch__slider::before {
  transform: translateX(29px);
  background-color: white;
}

.single-field input {
  border-radius: 4px;
  padding: 17px 20px;
  font-size: 15px;
  letter-spacing: 0.02em;
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.single-field.-w-410 input {
  width: 410px;
}

@media (max-width: 991px) {
  .single-field.-w-410 input {
    width: 100%;
  }
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  line-height: 1.45;
  font-weight: 600;
}

.text-4 {
  font-size: var(--text-4) !important;
}

.text-7 {
  font-size: var(--text-7) !important;
}

.text-10 {
  font-size: var(--text-10) !important;
}

.text-12 {
  font-size: var(--text-12) !important;
}

.text-13 {
  font-size: var(--text-13) !important;
}

.text-14 {
  font-size: var(--text-14) !important;
}

.text-15 {
  font-size: var(--text-15) !important;
}

.text-16 {
  font-size: var(--text-16) !important;
}

.text-18 {
  font-size: var(--text-18) !important;
}

.text-20 {
  font-size: var(--text-20) !important;
}

.text-22 {
  font-size: var(--text-22) !important;
}

.text-24 {
  font-size: var(--text-24) !important;
}

.text-25 {
  font-size: var(--text-25) !important;
}

.text-26 {
  font-size: var(--text-26) !important;
}

.text-30 {
  font-size: var(--text-30) !important;
}

.text-40 {
  font-size: var(--text-40) !important;
}

.text-50 {
  font-size: var(--text-50) !important;
}

.text-60 {
  font-size: var(--text-60) !important;
}

.text-80 {
  font-size: var(--text-80) !important;
}

@media (max-width: 1199px) {
  .xl\:text-4 {
    font-size: var(--text-4) !important;
  }
  .xl\:text-7 {
    font-size: var(--text-7) !important;
  }
  .xl\:text-10 {
    font-size: var(--text-10) !important;
  }
  .xl\:text-12 {
    font-size: var(--text-12) !important;
  }
  .xl\:text-13 {
    font-size: var(--text-13) !important;
  }
  .xl\:text-14 {
    font-size: var(--text-14) !important;
  }
  .xl\:text-15 {
    font-size: var(--text-15) !important;
  }
  .xl\:text-16 {
    font-size: var(--text-16) !important;
  }
  .xl\:text-18 {
    font-size: var(--text-18) !important;
  }
  .xl\:text-20 {
    font-size: var(--text-20) !important;
  }
  .xl\:text-22 {
    font-size: var(--text-22) !important;
  }
  .xl\:text-24 {
    font-size: var(--text-24) !important;
  }
  .xl\:text-25 {
    font-size: var(--text-25) !important;
  }
  .xl\:text-26 {
    font-size: var(--text-26) !important;
  }
  .xl\:text-30 {
    font-size: var(--text-30) !important;
  }
  .xl\:text-40 {
    font-size: var(--text-40) !important;
  }
  .xl\:text-50 {
    font-size: var(--text-50) !important;
  }
  .xl\:text-60 {
    font-size: var(--text-60) !important;
  }
  .xl\:text-80 {
    font-size: var(--text-80) !important;
  }
}

@media (max-width: 991px) {
  .lg\:text-4 {
    font-size: var(--text-4) !important;
  }
  .lg\:text-7 {
    font-size: var(--text-7) !important;
  }
  .lg\:text-10 {
    font-size: var(--text-10) !important;
  }
  .lg\:text-12 {
    font-size: var(--text-12) !important;
  }
  .lg\:text-13 {
    font-size: var(--text-13) !important;
  }
  .lg\:text-14 {
    font-size: var(--text-14) !important;
  }
  .lg\:text-15 {
    font-size: var(--text-15) !important;
  }
  .lg\:text-16 {
    font-size: var(--text-16) !important;
  }
  .lg\:text-18 {
    font-size: var(--text-18) !important;
  }
  .lg\:text-20 {
    font-size: var(--text-20) !important;
  }
  .lg\:text-22 {
    font-size: var(--text-22) !important;
  }
  .lg\:text-24 {
    font-size: var(--text-24) !important;
  }
  .lg\:text-25 {
    font-size: var(--text-25) !important;
  }
  .lg\:text-26 {
    font-size: var(--text-26) !important;
  }
  .lg\:text-30 {
    font-size: var(--text-30) !important;
  }
  .lg\:text-40 {
    font-size: var(--text-40) !important;
  }
  .lg\:text-50 {
    font-size: var(--text-50) !important;
  }
  .lg\:text-60 {
    font-size: var(--text-60) !important;
  }
  .lg\:text-80 {
    font-size: var(--text-80) !important;
  }
}

@media (max-width: 767px) {
  .md\:text-4 {
    font-size: var(--text-4) !important;
  }
  .md\:text-7 {
    font-size: var(--text-7) !important;
  }
  .md\:text-10 {
    font-size: var(--text-10) !important;
  }
  .md\:text-12 {
    font-size: var(--text-12) !important;
  }
  .md\:text-13 {
    font-size: var(--text-13) !important;
  }
  .md\:text-14 {
    font-size: var(--text-14) !important;
  }
  .md\:text-15 {
    font-size: var(--text-15) !important;
  }
  .md\:text-16 {
    font-size: var(--text-16) !important;
  }
  .md\:text-18 {
    font-size: var(--text-18) !important;
  }
  .md\:text-20 {
    font-size: var(--text-20) !important;
  }
  .md\:text-22 {
    font-size: var(--text-22) !important;
  }
  .md\:text-24 {
    font-size: var(--text-24) !important;
  }
  .md\:text-25 {
    font-size: var(--text-25) !important;
  }
  .md\:text-26 {
    font-size: var(--text-26) !important;
  }
  .md\:text-30 {
    font-size: var(--text-30) !important;
  }
  .md\:text-40 {
    font-size: var(--text-40) !important;
  }
  .md\:text-50 {
    font-size: var(--text-50) !important;
  }
  .md\:text-60 {
    font-size: var(--text-60) !important;
  }
  .md\:text-80 {
    font-size: var(--text-80) !important;
  }
}

@media (max-width: 575px) {
  .sm\:text-4 {
    font-size: var(--text-4) !important;
  }
  .sm\:text-7 {
    font-size: var(--text-7) !important;
  }
  .sm\:text-10 {
    font-size: var(--text-10) !important;
  }
  .sm\:text-12 {
    font-size: var(--text-12) !important;
  }
  .sm\:text-13 {
    font-size: var(--text-13) !important;
  }
  .sm\:text-14 {
    font-size: var(--text-14) !important;
  }
  .sm\:text-15 {
    font-size: var(--text-15) !important;
  }
  .sm\:text-16 {
    font-size: var(--text-16) !important;
  }
  .sm\:text-18 {
    font-size: var(--text-18) !important;
  }
  .sm\:text-20 {
    font-size: var(--text-20) !important;
  }
  .sm\:text-22 {
    font-size: var(--text-22) !important;
  }
  .sm\:text-24 {
    font-size: var(--text-24) !important;
  }
  .sm\:text-25 {
    font-size: var(--text-25) !important;
  }
  .sm\:text-26 {
    font-size: var(--text-26) !important;
  }
  .sm\:text-30 {
    font-size: var(--text-30) !important;
  }
  .sm\:text-40 {
    font-size: var(--text-40) !important;
  }
  .sm\:text-50 {
    font-size: var(--text-50) !important;
  }
  .sm\:text-60 {
    font-size: var(--text-60) !important;
  }
  .sm\:text-80 {
    font-size: var(--text-80) !important;
  }
}

@media (max-width: 479px) {
  .xs\:text-4 {
    font-size: var(--text-4) !important;
  }
  .xs\:text-7 {
    font-size: var(--text-7) !important;
  }
  .xs\:text-10 {
    font-size: var(--text-10) !important;
  }
  .xs\:text-12 {
    font-size: var(--text-12) !important;
  }
  .xs\:text-13 {
    font-size: var(--text-13) !important;
  }
  .xs\:text-14 {
    font-size: var(--text-14) !important;
  }
  .xs\:text-15 {
    font-size: var(--text-15) !important;
  }
  .xs\:text-16 {
    font-size: var(--text-16) !important;
  }
  .xs\:text-18 {
    font-size: var(--text-18) !important;
  }
  .xs\:text-20 {
    font-size: var(--text-20) !important;
  }
  .xs\:text-22 {
    font-size: var(--text-22) !important;
  }
  .xs\:text-24 {
    font-size: var(--text-24) !important;
  }
  .xs\:text-25 {
    font-size: var(--text-25) !important;
  }
  .xs\:text-26 {
    font-size: var(--text-26) !important;
  }
  .xs\:text-30 {
    font-size: var(--text-30) !important;
  }
  .xs\:text-40 {
    font-size: var(--text-40) !important;
  }
  .xs\:text-50 {
    font-size: var(--text-50) !important;
  }
  .xs\:text-60 {
    font-size: var(--text-60) !important;
  }
  .xs\:text-80 {
    font-size: var(--text-80) !important;
  }
}

.lh-1 {
  line-height: 1 !important;
}

.lh-11 {
  line-height: 1.1 !important;
}

.lh-12 {
  line-height: 1.2 !important;
}

.lh-13 {
  line-height: 1.3 !important;
}

.lh-14 {
  line-height: 1.4 !important;
}

.lh-15 {
  line-height: 1.5 !important;
}

.lh-16 {
  line-height: 1.6 !important;
}

.lh-17 {
  line-height: 1.7 !important;
}

.lh-18 {
  line-height: 1.8 !important;
}

.lh-19 {
  line-height: 1.9 !important;
}

.lh-2 {
  line-height: 2 !important;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

@media (max-width: 1199px) {
  .xl\:text-center {
    text-align: center !important;
  }
  .xl\:text-right {
    text-align: right !important;
  }
  .xl\:text-left {
    text-align: left !important;
  }
}

@media (max-width: 991px) {
  .lg\:text-center {
    text-align: center !important;
  }
  .lg\:text-right {
    text-align: right !important;
  }
  .lg\:text-left {
    text-align: left !important;
  }
}

@media (max-width: 767px) {
  .md\:text-center {
    text-align: center !important;
  }
  .md\:text-right {
    text-align: right !important;
  }
  .md\:text-left {
    text-align: left !important;
  }
}

@media (max-width: 575px) {
  .sm\:text-center {
    text-align: center !important;
  }
  .sm\:text-right {
    text-align: right !important;
  }
  .sm\:text-left {
    text-align: left !important;
  }
}

.fw-300 {
  font-weight: 300;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.sectionTitle.-md .sectionTitle__title {
  font-size: var(--text-30);
}

@media (max-width: 767px) {
  .sectionTitle.-md .sectionTitle__title {
    font-size: var(--text-26);
  }
}

.sectionTitle.-lg .sectionTitle__title {
  font-size: var(--text-40);
}

.masthead.-type-1 {
  position: relative;
  padding-top: 330px;
  padding-bottom: 210px;
}

@media (max-width: 991px) {
  .masthead.-type-1 {
    padding-top: 260px;
    padding-bottom: 180px;
  }
}

@media (max-width: 575px) {
  .masthead.-type-1 {
    padding-top: 160px;
    padding-bottom: 20px;
  }
}

.masthead.-type-1 .masthead__bg {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.masthead.-type-1 .masthead__bg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.masthead.-type-1 .masthead__bg::after {
  content: "";
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(270deg, rgba(5, 16, 54, 0.5) 0%, #051036 72.43%);
  opacity: 0.85;
}

.masthead.-type-2 {
  position: relative;
}

.masthead.-type-2 .mainSearch {
  position: absolute;
  bottom: 30px;
}

@media (max-width: 1199px) {
  .masthead.-type-2 .mainSearch {
    position: relative;
    bottom: 0;
    width: 100%;
  }
}

.masthead.-type-2 .masthead__bg {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 70px);
}

@media (max-width: 1199px) {
  .masthead.-type-2 .masthead__bg {
    height: 100%;
  }
}

.masthead.-type-2 .masthead__bg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.masthead.-type-2 .masthead__tabs {
  padding-top: 90px;
}

@media (max-width: 767px) {
  .masthead.-type-2 .masthead__tabs {
    padding-top: 74px;
  }
}

.masthead.-type-2 .masthead__content {
  padding-top: 120px;
}

@media (max-width: 1199px) {
  .masthead.-type-2 .masthead__content {
    padding-top: 120px;
    padding-bottom: 80px;
  }
}

@media (max-width: 767px) {
  .masthead.-type-2 .masthead__content {
    padding-top: 40px;
  }
}

@media (max-width: 575px) {
  .masthead.-type-2 .masthead__content {
    padding-bottom: 20px;
  }
}

.masthead.-type-2 .masthead__images {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: 1fr 1fr;
  gap: 30px;
}

@media (max-width: 575px) {
  .masthead.-type-2 .masthead__images {
    gap: 12px;
  }
}

.masthead.-type-2 .masthead__images img {
  border-radius: 4px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.masthead.-type-2 .masthead__images > *:nth-child(1) {
  grid-row: span 2;
}

.masthead.-type-3 {
  position: relative;
  padding-top: 360px;
  padding-bottom: 100px;
}

@media (max-width: 991px) {
  .masthead.-type-3 {
    padding-top: 220px;
    padding-bottom: 80px;
  }
}

@media (max-width: 767px) {
  .masthead.-type-3 {
    padding-top: 160px;
    padding-bottom: 30px;
  }
}

.masthead.-type-3 .masthead__bg {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.masthead.-type-3 .masthead__bg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.masthead.-type-3 .masthead__bg::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(5, 16, 54, 0.5);
}

.masthead.-type-3 .masthead__tabs {
  margin-top: 110px;
}

@media (max-width: 767px) {
  .masthead.-type-3 .masthead__tabs {
    margin-top: 40px;
  }
}

.masthead.-type-3 .masthead__tabs .tabs__controls {
  bottom: -5px;
}

.masthead.-type-3 .masthead__tabs .tabs__button {
  height: 64px;
}

.masthead.-type-4 {
  position: relative;
  margin: 20px 20px 0 20px;
}

@media (max-width: 1199px) {
  .masthead.-type-4 {
    margin: 0;
  }
}

.masthead.-type-4 .masthead__content {
  position: relative;
  z-index: 1;
  padding-top: 360px;
  padding-bottom: 140px;
}

@media (max-width: 991px) {
  .masthead.-type-4 .masthead__content {
    padding-top: 220px;
    padding-bottom: 120px;
  }
}

@media (max-width: 767px) {
  .masthead.-type-4 .masthead__content {
    padding-top: 160px;
    padding-bottom: 100px;
  }
}

.masthead.-type-4 .masthead__scroll {
  position: absolute;
  z-index: 5;
  bottom: 18px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 767px) {
  .masthead.-type-4 .masthead__scroll {
    display: none;
  }
}

.masthead.-type-4 .masthead__scroll .-line {
  width: 1px;
  height: 48px;
  background-color: white;
  margin-top: 3px;
}

.masthead.-type-4 .masthead-slider {
  position: relative;
  z-index: 0;
}

.masthead.-type-4 .masthead-slider .masthead__image {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
}

.masthead.-type-4 .masthead-slider .masthead__image::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(5, 16, 54, 0.5);
}

.masthead.-type-4 .masthead-slider img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.masthead.-type-4 .masthead-slider__nav {
  position: absolute;
  top: 50%;
  z-index: 10;
  transform: translateY(-50%);
}

.masthead.-type-4 .masthead-slider__nav.-prev {
  left: 40px;
}

.masthead.-type-4 .masthead-slider__nav.-next {
  right: 40px;
}

@media (max-width: 767px) {
  .masthead.-type-4 .masthead-slider__nav {
    top: unset;
    bottom: 30px;
    transform: none;
  }
  .masthead.-type-4 .masthead-slider__nav.-prev {
    left: unset;
    right: calc(50% + 10px);
  }
  .masthead.-type-4 .masthead-slider__nav.-next {
    right: unset;
    left: calc(50% + 10px);
  }
}

.masthead.-type-4 .mainSearch {
  margin-top: 90px;
}

@media (max-width: 767px) {
  .masthead.-type-4 .mainSearch {
    margin-top: 40px;
  }
}

.masthead.-type-5 {
  position: relative;
  padding-top: 260px;
  padding-bottom: 200px;
}

@media (max-width: 991px) {
  .masthead.-type-5 {
    padding-top: 220px;
    padding-bottom: 160px;
  }
}

@media (max-width: 767px) {
  .masthead.-type-5 {
    padding-top: 160px;
    padding-bottom: 40px;
  }
}

.masthead.-type-5 .masthead__bg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 58%;
  z-index: -1;
}

@media (max-width: 767px) {
  .masthead.-type-5 .masthead__bg {
    width: 100%;
  }
}

.masthead.-type-5 .masthead__bg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.masthead.-type-5 .masthead__image {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 42%;
  z-index: -2;
}

.masthead.-type-5 .masthead__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.masthead.-type-5 .-line {
  position: absolute;
  bottom: -40%;
  left: 0;
  width: 100%;
}

.masthead.-type-5 .-line img {
  width: 100%;
  object-fit: cover;
}

.masthead.-type-6 {
  position: relative;
  padding-top: 360px;
  padding-bottom: 300px;
}

@media (max-width: 1199px) {
  .masthead.-type-6 {
    padding-top: 240px;
    padding-bottom: 180px;
  }
}

@media (max-width: 991px) {
  .masthead.-type-6 {
    padding-top: 200px;
    padding-bottom: 60px;
  }
}

@media (max-width: 767px) {
  .masthead.-type-6 {
    padding-top: 120px;
    padding-bottom: 40px;
  }
}

.masthead.-type-6 .masthead__bg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}

.masthead.-type-6 .masthead__bg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.masthead.-type-6 .masthead__bg::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  background-color: rgba(5, 16, 54, 0.5);
}

.masthead.-type-7 {
  position: relative;
  padding-top: 90px;
}

@media (max-width: 991px) {
  .masthead.-type-7 {
    padding-bottom: 200px;
  }
}

.masthead.-type-7 .masthead__bg {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.masthead.-type-7 .masthead__bg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.masthead.-type-7 .masthead__bg::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(5, 16, 54, 0.5);
}

.masthead.-type-7 .masthead__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 650px;
}

@media (max-width: 991px) {
  .masthead.-type-7 .masthead__content {
    height: auto;
    width: 400px;
    max-width: 100%;
    padding-top: 100px;
    padding-bottom: 220px;
  }
}

@media (max-width: 767px) {
  .masthead.-type-7 .masthead__content {
    padding-top: 60px;
    padding-bottom: 260px;
  }
}

.masthead.-type-7 .masthead-slider {
  overflow: hidden;
  position: relative;
  width: 1720px;
  max-width: 100%;
  margin: 0 auto;
  border-radius: 4px;
}

.masthead.-type-7 .masthead-slider__nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
}

.masthead.-type-7 .masthead-slider__nav.-prev {
  left: 40px;
}

.masthead.-type-7 .masthead-slider__nav.-next {
  right: 40px;
}

@media (max-width: 767px) {
  .masthead.-type-7 .masthead-slider__nav {
    top: 52%;
  }
  .masthead.-type-7 .masthead-slider__nav.-prev {
    left: unset;
    right: 51%;
  }
  .masthead.-type-7 .masthead-slider__nav.-next {
    right: unset;
    left: 51%;
  }
}

.masthead.-type-7 .mainSearch {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -50px;
  z-index: 1;
}

@media (max-width: 991px) {
  .masthead.-type-7 .mainSearch {
    bottom: 0;
    width: 480px;
    max-width: calc(100% - 40px);
  }
}

.masthead.-type-8 {
  position: relative;
  padding-top: 200px;
}

@media (max-width: 991px) {
  .masthead.-type-8 {
    padding-top: 160px;
  }
}

@media (max-width: 767px) {
  .masthead.-type-8 {
    padding-top: 120px;
  }
}

.masthead.-type-8 .masthead__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 700px;
  z-index: -1;
}

.masthead.-type-8 .masthead-bottom {
  position: relative;
  max-width: 1500px;
  margin: 0 auto;
}

.masthead.-type-8 .mainSearch {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -50px;
  z-index: 2;
}

@media (max-width: 991px) {
  .masthead.-type-8 .mainSearch {
    position: relative;
    top: unset;
    left: unset;
    transform: none;
    max-width: 600px;
  }
}

.masthead.-type-8 .masthead-slider {
  position: relative;
  border-radius: 16px;
  margin: 0 24px;
}

@media (max-width: 767px) {
  .masthead.-type-8 .masthead-slider {
    border-radius: 0;
    margin: 0;
  }
}

.masthead.-type-8 .masthead-slider__nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}

.masthead.-type-8 .masthead-slider__nav.-prev {
  left: 40px;
}

@media (max-width: 767px) {
  .masthead.-type-8 .masthead-slider__nav.-prev {
    left: 12px;
  }
}

.masthead.-type-8 .masthead-slider__nav.-next {
  right: 40px;
}

@media (max-width: 767px) {
  .masthead.-type-8 .masthead-slider__nav.-next {
    right: 12px;
  }
}

.masthead.-type-9 {
  position: relative;
  z-index: 0;
}

.masthead.-type-9 .masthead__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.masthead.-type-9 .masthead__bg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.masthead.-type-9 .masthead__bg::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(2, 6, 20, 0.3);
}

.masthead.-type-9 .masthead__scroll {
  position: absolute;
  bottom: 30px;
  right: 50px;
}

@media (max-width: 1199px) {
  .masthead.-type-9 .masthead__scroll {
    display: none;
  }
}

.masthead.-type-9 .masthead__scroll .-icon {
  position: relative;
}

.masthead.-type-9 .masthead__scroll .-icon > *:nth-child(1) {
  width: 28px;
  height: 40px;
  border: 2px solid white;
  border-radius: 200px;
}

.masthead.-type-9 .masthead__scroll .-icon > *:nth-child(2) {
  position: absolute;
  top: 8px;
  left: 10px;
  width: 8px;
  height: 8px;
  border: 2px solid white;
  border-radius: 100%;
  transition: all 0.5s ease;
}

.masthead.-type-9 .masthead__scroll:hover .-icon > *:nth-child(2) {
  transform: translateY(16px);
}

.masthead.-type-9 .masthead-slider {
  position: relative;
  z-index: -1;
}

.masthead.-type-9 .masthead-slider .swiper-slide {
  padding-top: 385px;
  padding-bottom: 285px;
}

@media (max-width: 991px) {
  .masthead.-type-9 .masthead-slider .swiper-slide {
    padding-top: 200px;
    padding-bottom: 60px;
  }
}

@media (max-width: 767px) {
  .masthead.-type-9 .masthead-slider .swiper-slide {
    padding-top: 160px;
    padding-bottom: 40px;
  }
}

.masthead.-type-9 .masthead-slider__nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}

.masthead.-type-9 .masthead-slider__nav.-prev {
  left: 40px;
}

@media (max-width: 767px) {
  .masthead.-type-9 .masthead-slider__nav.-prev {
    left: 12px;
  }
}

.masthead.-type-9 .masthead-slider__nav.-next {
  right: 40px;
}

@media (max-width: 767px) {
  .masthead.-type-9 .masthead-slider__nav.-next {
    right: 12px;
  }
}

.masthead.-type-9 .mainSearch-wrap {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 2;
  display: flex;
  justify-content: flex-end;
  width: 55vw;
}

@media (max-width: 1300px) {
  .masthead.-type-9 .mainSearch-wrap {
    left: 50%;
    transform: translateX(-50%);
    justify-content: center;
    width: 100%;
    background-color: transparent !important;
  }
}

@media (max-width: 991px) {
  .masthead.-type-9 .mainSearch-wrap {
    left: unset;
    transform: none;
    position: relative;
    margin-top: 50px;
  }
}

.masthead.-type-10 {
  position: relative;
  padding-top: 360px;
  padding-bottom: 205px;
  background: linear-gradient(180deg, rgba(53, 84, 209, 0.1) 0%, rgba(196, 196, 196, 0) 100%);
}

@media (max-width: 991px) {
  .masthead.-type-10 {
    padding-top: 140px;
    padding-bottom: 60px;
  }
}

@media (max-width: 767px) {
  .masthead.-type-10 {
    padding-top: 120px;
    padding-bottom: 40px;
  }
}

.masthead.-type-10 .masthead__content {
  position: relative;
  z-index: 1;
}

.masthead.-type-10 .masthead__image {
  position: absolute;
  z-index: 0;
  display: flex;
  top: 160px;
  right: 0;
}

@media (max-width: 991px) {
  .masthead.-type-10 .masthead__image {
    top: unset;
    position: relative;
    margin-top: 30px;
  }
}

.masthead.-type-10 .container-1500 {
  margin: 0 auto;
  max-width: 1530px;
  padding: 0 15px;
}

@media (max-width: 1600px) {
  .masthead.-type-10 .container-1500 {
    max-width: 1320px;
  }
}

@media (max-width: 1399px) {
  .masthead.-type-10 .container-1500 {
    max-width: 1140px;
  }
}

@media (max-width: 1199px) {
  .masthead.-type-10 .container-1500 {
    max-width: 960px;
  }
}

@media (max-width: 991px) {
  .masthead.-type-10 .container-1500 {
    max-width: 720px;
  }
}

@media (max-width: 767px) {
  .masthead.-type-10 .container-1500 {
    max-width: 540px;
  }
}

@media (max-width: 575px) {
  .masthead.-type-10 .container-1500 {
    max-width: 100%;
  }
}

.masthead.-type-10 .mainSearch .button-grid {
  grid-template-columns: 1fr 1fr auto auto auto auto;
}

@media (max-width: 991px) {
  .masthead.-type-10 .mainSearch .button-grid {
    grid-template-columns: 1fr;
  }
}

.citiesCard.-type-1 {
  position: relative;
  z-index: 0;
}

.citiesCard.-type-1 .citiesCard__image {
  border-radius: inherit;
}

.citiesCard.-type-1 .citiesCard__image img {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
}

.citiesCard.-type-1 .citiesCard__content {
  overflow: hidden;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
}

.citiesCard.-type-1 .citiesCard__bg {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(5, 16, 54, 0.4);
  border-radius: inherit;
  transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  opacity: 0;
}

.citiesCard.-type-1 .citiesCard__top {
  transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  opacity: 0;
}

.citiesCard.-type-1 .citiesCard__bottom {
  transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  transform: translateY(80px);
}

.citiesCard.-type-1:hover {
  cursor: pointer;
}

.citiesCard.-type-1:hover .citiesCard__top {
  opacity: 1;
}

.citiesCard.-type-1:hover .citiesCard__bottom {
  transform: translateY(0);
}

.citiesCard.-type-1:hover .citiesCard__bg {
  opacity: 1;
}

.citiesCard.-type-2 {
  position: relative;
  z-index: 0;
}

.citiesCard.-type-2 .citiesCard__image {
  overflow: hidden;
}

.citiesCard.-type-2 .citiesCard__image img {
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.citiesCard.-type-2:hover {
  cursor: pointer;
}

.citiesCard.-type-2:hover .citiesCard__image img {
  transform: scale(1.15);
}

.citiesCard.-type-3 {
  position: relative;
  overflow: hidden;
  z-index: 0;
}

@media (max-width: 575px) {
  .citiesCard.-type-3 {
    width: 100%;
    padding-bottom: 100%;
  }
  .citiesCard.-type-3 > * {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.citiesCard.-type-3 .citiesCard__image {
  overflow: hidden;
  border-radius: inherit;
  width: 100%;
  height: 100%;
}

.citiesCard.-type-3 .citiesCard__image img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: inherit;
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.citiesCard.-type-3 .citiesCard__content {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
}

.citiesCard.-type-3 .citiesCard__content::before {
  content: "";
  position: absolute;
  border-radius: inherit;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(5, 16, 54, 0.6) 0%, rgba(5, 16, 54, 0) 63.5%);
}

.citiesCard.-type-3:hover .citiesCard__image img {
  transform: scale(1.1);
}

.citiesCard.-type-4 .citiesCard__image {
  overflow: hidden;
}

.citiesCard.-type-4 .citiesCard__image img {
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.citiesCard.-type-4:hover .citiesCard__image img {
  transform: scale(1.1);
}

.citiesCard.-type-5 {
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.citiesCard.-type-5:hover {
  background-color: var(--color-blue-1) !important;
}

.citiesCard.-type-5:hover * {
  color: white !important;
}

.ctaCard.-type-1 {
  position: relative;
  z-index: 0;
}

.ctaCard.-type-1 .ctaCard__image {
  border-radius: inherit;
}

.ctaCard.-type-1 .ctaCard__image img {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
}

.ctaCard.-type-1 .ctaCard__content {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
}

.ctaCard.-type-1 .ctaCard__content::before {
  content: "";
  border-radius: inherit;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(5, 16, 54, 0.7) 0%, rgba(5, 16, 54, 0) 100%);
}

.ctaCard.-type-1.-no-overlay .ctaCard__content::before {
  display: none;
}

.tabs__content {
  position: relative;
}

.tabs__pane {
  position: absolute;
  top: 0;
  visibility: hidden;
  transition: none;
  opacity: 0;
}

.tabs__pane.is-tab-el-active {
  transition: opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0.1s;
  position: relative;
  opacity: 1;
  visibility: visible;
}

.tabs.-pills .tabs__controls .tabs__button {
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.tabs.-pills .tabs__controls .tabs__button:hover {
  color: var(--color-blue-1) !important;
}

.tabs.-pills .tabs__controls .tabs__button.is-tab-el-active {
  color: var(--color-blue-1) !important;
  background-color: rgba(53, 84, 209, 0.05);
}

.tabs.-pills-2 .tabs__controls .tabs__button {
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.tabs.-pills-2 .tabs__controls .tabs__button:hover {
  color: var(--color-blue-1) !important;
}

.tabs.-pills-2 .tabs__controls .tabs__button.is-tab-el-active {
  color: var(--color-white) !important;
  background-color: var(--color-blue-1) !important;
}

.tabs.-pills-3 .tabs__controls .tabs__button {
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.tabs.-pills-3 .tabs__controls .tabs__button:hover {
  color: var(--color-dark-3) !important;
}

.tabs.-pills-3 .tabs__controls .tabs__button.is-tab-el-active {
  color: var(--color-white) !important;
  background-color: var(--color-dark-3) !important;
}

.tabs__controls {
  position: relative;
  overflow: auto;
  scroll-snap-type: x mandatory;
  white-space: nowrap;
}

.tabs__controls > div {
  scroll-snap-align: center;
}

.tabs.-bookmark .tabs__controls {
  position: relative;
  overflow: auto;
  scroll-snap-type: x mandatory;
}

.tabs.-bookmark .tabs__controls > div {
  scroll-snap-align: center;
}

.tabs.-bookmark .tabs__button {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.3;
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.tabs.-bookmark .tabs__button:hover {
  color: var(--color-blue-1) !important;
}

.tabs.-bookmark .tabs__button.is-tab-el-active {
  color: var(--color-dark-1) !important;
  background-color: white;
}

.tabs.-bookmark-2 .tabs__controls {
  position: relative;
  overflow: auto;
  scroll-snap-type: x mandatory;
}

.tabs.-bookmark-2 .tabs__controls > div {
  scroll-snap-align: center;
}

.tabs.-bookmark-2 .tabs__button {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.3;
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.tabs.-bookmark-2 .tabs__button:hover {
  color: var(--color-blue-1) !important;
}

.tabs.-bookmark-2 .tabs__button.is-tab-el-active {
  color: white !important;
  background-color: rgba(255, 255, 255, 0.1);
}

.tabs.-underline .tabs__controls .tabs__button {
  position: relative;
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.tabs.-underline .tabs__controls .tabs__button::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: white;
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  opacity: 0;
}

.tabs.-underline .tabs__controls .tabs__button:hover {
  color: var(--color-blue-1) !important;
}

.tabs.-underline .tabs__controls .tabs__button.is-tab-el-active::after {
  opacity: 1;
}

.tabs.-underline-2 .tabs__controls .tabs__button {
  position: relative;
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.tabs.-underline-2 .tabs__controls .tabs__button::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: var(--color-blue-1);
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  opacity: 0;
}

.tabs.-underline-2 .tabs__controls .tabs__button:hover {
  color: var(--color-blue-1) !important;
}

.tabs.-underline-2 .tabs__controls .tabs__button.is-tab-el-active {
  color: var(--color-blue-1) !important;
}

.tabs.-underline-2 .tabs__controls .tabs__button.is-tab-el-active::after {
  opacity: 1;
}

.accordion__icon {
  position: relative;
}

.accordion__icon .icon {
  transition: all 0.2s ease-out;
}

.accordion__icon .icon:nth-child(1) {
  position: absolute;
  top: 0;
  left: 0;
}

.accordion__icon .icon:nth-child(2) {
  position: relative;
  transform: rotate(-90deg);
  opacity: 0;
}

.accordion__item.is-active .accordion__icon > *:nth-child(1) {
  transform: rotate(90deg);
  opacity: 0;
}

.accordion__item.is-active .accordion__icon > *:nth-child(2) {
  transform: none;
  opacity: 1;
}

.accordion__content {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.2s ease-out;
}

.accordion__button {
  cursor: pointer;
}

.accordion.-simple .accordion__button {
  cursor: pointer;
  transition: 0.4s;
}

.accordion.-simple .accordion__button button {
  color: var(--text-dark-1);
}

.accordion.-simple .accordion__icon {
  transition: all 0.2s ease-out;
}

.accordion.-simple .accordion__icon > *:nth-child(1) {
  position: absolute;
  transition: all 0.2s ease-out;
}

.accordion.-simple .accordion__icon > *:nth-child(2) {
  position: relative;
  transform: rotate(-90deg);
  opacity: 0;
  transition: all 0.2s ease-out;
}

.accordion.-simple .accordion__item.is-active .accordion__icon {
  background-color: var(--color-dark-1) !important;
  color: white !important;
}

.accordion.-simple .accordion__item.is-active .accordion__icon > *:nth-child(1) {
  transform: rotate(90deg);
  opacity: 0;
}

.accordion.-simple .accordion__item.is-active .accordion__icon > *:nth-child(2) {
  transform: none;
  opacity: 1;
}

.accordion.-map .accordion__icon {
  transition: all 0.2s ease-out;
}

.accordion.-map .accordion__item.is-active .accordion__icon {
  background-color: var(--color-blue-1) !important;
  color: white !important;
}

.accordion.-map .accordion__item.is-active .accordion__icon > * {
  transform: none !important;
  opacity: 1 !important;
}

.accordion.-db-sidebar .accordion__button {
  cursor: pointer;
  transition: 0.4s;
}

.accordion.-db-sidebar .accordion__button button {
  color: var(--text-dark-1);
}

.accordion.-db-sidebar .accordion__icon {
  transition: all 0.2s ease-out;
}

.accordion.-db-sidebar .accordion__icon > *:nth-child(1) {
  position: absolute;
  transition: all 0.2s ease-out;
}

.accordion.-db-sidebar .accordion__icon > *:nth-child(2) {
  position: relative;
  transform: rotate(-90deg);
  opacity: 0;
  transition: all 0.2s ease-out;
}

.accordion.-db-sidebar .accordion__item.is-active .sidebar__button {
  background-color: rgba(53, 84, 209, 0.05) !important;
  color: var(--color-blue-1) !important;
}

.accordion.-db-sidebar .accordion__item.is-active .accordion__icon {
  background-color: var(--color-dark-1) !important;
  color: white !important;
}

.accordion.-db-sidebar .accordion__item.is-active .accordion__icon > *:nth-child(1) {
  transform: rotate(90deg);
  opacity: 0;
}

.accordion.-db-sidebar .accordion__item.is-active .accordion__icon > *:nth-child(2) {
  transform: none;
  opacity: 1;
}

.header-margin {
  margin-top: 90px;
}

@media (max-width: 767px) {
  .header-margin {
    margin-top: 80px;
  }
}

.header {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 90px;
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
}

@media (max-width: 767px) {
  .header {
    height: 80px;
  }
}

.header.-fixed {
  position: absolute !important;
}

.header .is-menu-opened-hide {
  transition: opacity 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.header.is-menu-opened {
  background-color: white !important;
  border-bottom: 1px solid var(--color-border);
}

.header.is-menu-opened .is-menu-opened-hide {
  opacity: 0;
  pointer-events: none;
}

.header.is-sticky {
  margin: 0 !important;
}

.header .row {
  flex-wrap: nowrap;
}

.header__container {
  width: 100%;
}

.header__container-1500 {
  max-width: 1560px;
}

.header .header-logo {
  position: relative;
}

.header .header-logo img {
  width: 140px;
}

@media (max-width: 767px) {
  .header .header-logo img {
    max-width: 120px;
  }
}

.header .header-logo img:nth-child(2) {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.header .header-logo.is-logo-dark img:nth-child(1) {
  opacity: 0;
}

.header .header-logo.is-logo-dark img:nth-child(2) {
  opacity: 1;
}

.header.-type-2 {
  margin-top: 20px;
  transition: background-color 0.4s cubic-bezier(0.165, 0.84, 0.44, 1), margin 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
}

@media (max-width: 1199px) {
  .header.-type-2 {
    margin-top: 0;
  }
}

.header.-type-2.is-sticky {
  margin: 0;
}

@media (max-width: 1199px) {
  .header.-type-2 .col-auto-menu {
    position: absolute;
  }
}

@media (max-width: 767px) {
  .header.-type-5 .header__buttons > *:nth-child(1) {
    color: white !important;
    background-color: var(--color-dark-1) !important;
    border-color: var(--color-dark-1) !important;
  }
  .header.-type-5 .header__buttons > *:nth-child(1):hover {
    background-color: var(--color-blue-1) !important;
    color: white !important;
    border-color: var(--color-blue-1) !important;
  }
  .header.-type-5 .header__buttons > *:nth-child(2) {
    color: var(--color-dark-1) !important;
    border-color: var(--color-dark-1) !important;
  }
  .header.-type-5 .header__buttons > *:nth-child(2):hover {
    background-color: var(--color-blue-1) !important;
    color: white !important;
    border-color: var(--color-blue-1) !important;
  }
}

.header.-type-5.-header-5-sticky {
  background-color: white !important;
  box-shadow: 0px 10px 30px 0px #05103608;
}

.header.-type-5.-header-5-sticky .header__buttons > *:nth-child(1) {
  color: white !important;
  background-color: var(--color-dark-1) !important;
  border-color: var(--color-dark-1) !important;
}

.header.-type-5.-header-5-sticky .header__buttons > *:nth-child(1):hover {
  background-color: var(--color-blue-1) !important;
  color: white !important;
  border-color: var(--color-blue-1) !important;
}

.header.-type-5.-header-5-sticky .header__buttons > *:nth-child(2) {
  color: var(--color-dark-1) !important;
  border-color: var(--color-dark-1) !important;
}

.header.-type-5.-header-5-sticky .header__buttons > *:nth-child(2):hover {
  background-color: var(--color-blue-1) !important;
  color: white !important;
  border-color: var(--color-blue-1) !important;
}

@media (max-width: 1199px) {
  .header.-type-9 .header-menu {
    top: 120px;
    height: calc(100vh - 120px);
  }
}

.header.-dashboard .header__container {
  display: flex;
  align-items: center;
}

.header.-dashboard .header__container > .row {
  width: 100%;
}

.header.-dashboard .-left-side {
  flex-shrink: 0;
  width: calc(var(--dashboard-width) - 30px);
}

@media (max-width: 991px) {
  .header.-dashboard .-left-side {
    width: auto;
  }
}

@media (min-width: 1200px) {
  .header .header-menu .menu li {
    position: relative;
  }
  .header .header-menu .menu li a {
    cursor: pointer;
  }
  .header .header-menu .menu li:hover > .subnav {
    opacity: 1;
    pointer-events: auto;
  }
  .header .header-menu .menu li.-has-mega-menu:hover > .mega {
    opacity: 1 !important;
    pointer-events: auto !important;
  }
  .header .header-menu .menu a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 15px;
    transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  .header .header-menu .menu__nav {
    display: flex;
  }
  .header .header-menu .menu__nav > li {
    padding: 25px 0;
  }
  .header .header-menu .menu__nav > li.menu-item-has-children > a .icon {
    font-size: 7px;
  }
  .header .header-menu .menu__nav > li > .subnav::before {
    content: '';
    position: absolute;
    top: -5px;
    left: 20px;
    width: 10px;
    height: 10px;
    background-color: white;
    transform: rotate(45deg);
  }
  .header .header-menu .menu__nav a {
    padding: 0 10px;
  }
  .header .header-menu .menu .subnav {
    position: absolute;
    top: 100%;
    background-color: white;
    border-radius: 4px;
    color: var(--color-dark-1) !important;
    min-width: 240px;
    padding: 20px;
    box-shadow: 0px 10px 60px 0px #0510360D;
    opacity: 0;
    pointer-events: none;
    transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  .header .header-menu .menu .subnav__backBtn {
    display: none;
  }
  .header .header-menu .menu .subnav .subnav {
    top: 0;
    left: 100%;
  }
  .header .header-menu .menu .subnav > li > a {
    padding: 5px 15px;
    border-radius: 4px;
  }
  .header .header-menu .menu .subnav > li > a:hover {
    color: var(--color-blue-1);
    background-color: rgba(53, 84, 209, 0.05);
  }
  .header .header-menu .menu .mega-mobile {
    display: none;
  }
  .header .header-menu .menu .mega {
    position: absolute;
    top: 100%;
    background-color: white;
    padding: 30px;
    border-radius: 4px;
    color: var(--color-dark-1);
    width: 800px;
    box-shadow: 0px 10px 30px 0px #05103608;
    transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
    opacity: 0;
    pointer-events: none;
  }
  .header .header-menu .menu .mega__content {
    display: flex;
    justify-content: space-between;
  }
  .header .header-menu .menu .mega__grid {
    display: grid;
    gap: 60px;
    grid-template-columns: auto auto auto;
  }
  .header .header-menu .menu .mega__image {
    width: 270px;
  }
  .header .header-menu .menu .mega__image img {
    width: 100%;
    min-height: 300px;
    object-fit: cover;
  }
  .header .header-menu .menu .mega a {
    padding-left: 0px;
    padding-right: 0px;
  }
  .header .header-menu .menu__backButton {
    display: none;
  }
  .header .header-menu .mobile-overlay {
    display: none;
  }
  .header .header-menu .mobile-bg {
    display: none;
  }
  .header .header-menu .mobile-back-button {
    display: none;
  }
  .header .header-menu .mobile-footer {
    display: none;
  }
}

@media (max-width: 1199px) {
  .header .header-menu {
    position: fixed;
    top: 80px;
    left: 0;
    z-index: 10;
    width: 100vw;
    height: calc(100vh - 80px);
    transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    opacity: 0;
    pointer-events: none;
  }
  .header .header-menu.is-menu-active {
    opacity: 1;
    pointer-events: auto;
  }
  .header .header-menu.is-menu-active .-is-active {
    pointer-events: auto !important;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .header .header-menu .mega {
    display: none;
  }
  .header .header-menu ul {
    overflow-y: hidden;
    overflow-x: hidden;
  }
  .header .header-menu__content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .header .header-menu .mobile-overlay {
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(5, 16, 54, 0.7);
    z-index: -2;
  }
  .header .header-menu .mobile-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: white;
  }
  .header .header-menu .menu {
    overflow-y: hidden;
    overflow-x: hidden;
    position: relative;
    height: 100%;
    padding: 20px;
    padding-top: 40px;
  }
  .header .header-menu .menu ul {
    pointer-events: none;
  }
  .header .header-menu .menu li {
    overflow: hidden;
  }
  .header .header-menu .menu a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    padding: 0 20px;
    border-radius: 4px;
    font-weight: 500;
    color: var(--color-dark-1);
    transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  .header .header-menu .menu a .icon {
    font-size: 8px;
    transform: rotate(-90deg);
  }
  .header .header-menu .menu a:hover {
    color: var(--color-blue-1);
  }
  .header .header-menu .menu__nav {
    display: flex;
    flex-direction: column;
  }
  .header .header-menu .menu__nav > li > a {
    font-size: 20px;
  }
  .header .header-menu .menu__nav > li > a:hover {
    background-color: rgba(53, 84, 209, 0.05) !important;
  }
  .header .header-menu .menu .subnav {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-top: 40px;
  }
  .header .header-menu .menu .subnav li {
    padding: 0 20px;
  }
  .header .header-menu .menu .subnav a {
    height: 40px;
    font-size: 18px;
    padding: 0 20px;
    transform: translateY(100%);
  }
  .header .header-menu .menu .subnav__backBtn {
    margin-bottom: 20px;
  }
  .header .header-menu .menu .subnav__backBtn li {
    padding: 0;
  }
  .header .header-menu .menu .subnav__backBtn a {
    justify-content: flex-start;
    height: 50px;
    font-size: 20px;
    color: var(--color-blue-1);
    background-color: rgba(53, 84, 209, 0.05) !important;
  }
  .header .header-menu .menu .subnav__backBtn a .icon {
    margin-right: 12px;
    transform: rotate(90deg);
  }
}

.cardImage {
  position: relative;
  z-index: 0;
  border-radius: inherit;
}

.cardImage:hover .cardImage-slider__nav {
  opacity: 1 !important;
}

.cardImage:hover .pagination__item {
  opacity: 1 !important;
}

.cardImage__content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  border-radius: inherit;
}

.cardImage__content > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: inherit;
}

.cardImage .cardImage-slider .swiper-wrapper {
  position: relative;
  z-index: 0;
}

.cardImage .cardImage-slider__pagination {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  color: rgba(255, 255, 255, 0.7);
}

.cardImage .cardImage-slider__pagination.-sm {
  bottom: 15px;
}

.cardImage .cardImage-slider__pagination > * + * {
  margin-left: 7px;
}

.cardImage .cardImage-slider__pagination .pagination__item {
  height: 8px;
  width: 8px;
  border-radius: 100%;
  background-color: currentColor;
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  transform: scale(0.75);
  opacity: 0;
}

.cardImage .cardImage-slider__pagination .pagination__item.is-active {
  transform: scale(1);
  background-color: var(--color-white);
  opacity: 1;
}

.cardImage .cardImage-slider__nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: opacity 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  opacity: 0;
}

.cardImage .cardImage-slider__nav.-prev {
  left: 20px;
}

.cardImage .cardImage-slider__nav.-next {
  right: 20px;
}

.cardImage__wishlist {
  position: absolute;
  top: 20px;
  right: 20px;
}

.cardImage__wishlist.-sm {
  top: 10px;
  right: 10px;
}

.cardImage__leftBadge {
  position: absolute;
  top: 20px;
  left: 0;
}

.footer.-dashboard {
  border-top: 1px solid var(--color-border);
}

.footer.-dashboard .footer__row {
  padding: 15px 0;
}

.mainSearch {
  position: relative;
  z-index: 20;
}

@media (max-width: 991px) {
  .mainSearch {
    width: 100%;
    border-radius: 4px !important;
  }
}

.mainSearch .button-grid {
  display: grid;
  grid-template-columns: 1fr 250px 290px auto;
}

.mainSearch .button-grid > * + * {
  border-left: 1px solid var(--color-border);
}

@media (max-width: 991px) {
  .mainSearch .button-grid {
    grid-template-columns: 1fr;
  }
  .mainSearch .button-grid > * + * {
    border-left: 0;
    border-top: 1px solid var(--color-border);
  }
}

.mainSearch .button-item {
  border: none !important;
}

@media (max-width: 991px) {
  .mainSearch__submit {
    border-radius: 4px !important;
  }
}

.mainSearch.-col-2 .button-grid {
  grid-template-columns: 1fr 1fr min-content;
}

@media (max-width: 991px) {
  .mainSearch.-col-2 .button-grid {
    grid-template-columns: 1fr;
  }
}

.mainSearch.-col-4 .button-grid {
  grid-template-columns: auto auto 200px 200px min-content;
}

@media (max-width: 991px) {
  .mainSearch.-col-4 .button-grid {
    grid-template-columns: 1fr;
  }
}

.mainSearch.-col-3-big .button-grid {
  grid-template-columns: 1fr 0.6fr 0.6fr auto;
}

@media (max-width: 991px) {
  .mainSearch.-col-3-big .button-grid {
    grid-template-columns: 1fr;
  }
}

.mainSearch.-col-5 .button-grid {
  grid-template-columns: auto auto auto auto auto min-content;
}

@media (max-width: 991px) {
  .mainSearch.-col-5 .button-grid {
    grid-template-columns: 1fr;
  }
  .mainSearch.-col-5 .button-grid > * + * {
    border-left: 0;
    border-top: 1px solid var(--color-border);
  }
}

.mainSearch.-w-900 {
  width: 900px;
}

@media (max-width: 991px) {
  .mainSearch.-w-900 {
    width: auto;
  }
}

.mainSearch.-w-1070 {
  width: 1070px;
  max-width: 100%;
}

@media (max-width: 991px) {
  .mainSearch.-w-1070 {
    width: auto;
  }
}

.searchMenu-loc {
  position: relative;
}

.searchMenu-loc__field {
  position: absolute;
  margin-top: 30px;
  top: 100%;
  left: 0;
  z-index: 20;
  width: 500px;
  max-width: 100vw;
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  pointer-events: none;
  opacity: 0;
}

@media (max-width: 991px) {
  .searchMenu-loc__field {
    width: auto;
    left: -20px;
    right: -20px;
  }
}

@media (max-width: 575px) {
  .searchMenu-loc__field {
    left: -35px;
    right: -35px;
  }
}

.searchMenu-loc__field.-is-active {
  pointer-events: auto;
  opacity: 1;
}

.searchMenu-loc .-link:hover {
  background-color: rgba(53, 84, 209, 0.05) !important;
}

.searchMenu-date {
  position: relative;
}

.searchMenu-date.-left .searchMenu-date__field {
  transform: none !important;
}

.searchMenu-date.-right .searchMenu-date__field {
  left: unset;
  right: 0;
  transform: none !important;
}

.searchMenu-date__field {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  width: 900px;
  max-width: 100vw;
  margin-top: 30px;
  box-shadow: 0px 10px 60px 0px #0510360D;
  transform: translateX(-31%);
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  pointer-events: none;
  opacity: 0;
}

.searchMenu-date__field.-is-active {
  pointer-events: auto;
  opacity: 1;
}

@media (max-width: 991px) {
  .searchMenu-date__field {
    transform: none;
    width: auto;
    left: -20px;
    right: -20px;
  }
}

@media (max-width: 575px) {
  .searchMenu-date__field {
    left: -35px;
    right: -35px;
  }
}

.searchMenu-guests {
  position: relative;
}

.searchMenu-guests__field {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  width: 400px;
  max-width: 100vw;
  margin-top: 30px;
  box-shadow: 0px 10px 60px 0px #0510360D;
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  pointer-events: none;
  opacity: 0;
}

@media (max-width: 991px) {
  .searchMenu-guests__field {
    width: auto;
    left: -20px;
    right: -20px;
  }
}

@media (max-width: 575px) {
  .searchMenu-guests__field {
    left: -35px;
    right: -35px;
  }
}

.searchMenu-guests__field.-is-active {
  pointer-events: auto;
  opacity: 1;
}

.featureIcon.-type-1.-hover-shadow {
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.featureIcon.-type-1.-hover-shadow:hover {
  box-shadow: 0px 20px 40px 0px #05103612;
}

.testimonials-slider-2 .swiper-slide {
  opacity: 0.8;
}

.testimonials-slider-2 .swiper-slide.swiper-slide-active {
  opacity: 1;
}

.testimonials.-type-2 .testimonials__image {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
}

.testimonials.-type-2 .testimonials__image > img:nth-child(2) {
  position: absolute;
  z-index: 1;
  width: 96px;
  height: 96px;
  object-fit: cover;
}

.pagination.-dots {
  display: flex;
}

.pagination.-dots > * {
  margin: 0 5px;
}

.pagination.-dots .pagination__item {
  background-color: currentColor;
  height: 8px;
  width: 8px;
  border-radius: 100%;
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  transform: scale(0.75);
}

.pagination.-dots .pagination__item.is-active {
  background-color: var(--color-blue-1);
  transform: scale(1);
}

.arrow-left-hover {
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.arrow-left-hover:hover {
  color: var(--color-purple-1);
  transform: translateX(-5px) scale(1.25);
}

.arrow-right-hover {
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.arrow-right-hover:hover {
  color: var(--color-purple-1);
  transform: translateX(5px) scale(1.25);
}

.pagination.-buttons {
  display: flex;
  align-items: center;
}

.pagination.-buttons > * + * {
  margin-left: 40px;
}

.pagination.-buttons .pagination__button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 45px;
  border-radius: 100%;
  background-color: var(--color-light-7);
  color: var(--color-purple-1);
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.pagination.-buttons .pagination__button .icon {
  font-size: 12px;
}

.pagination.-buttons .pagination__button:hover {
  background-color: var(--color-purple-1);
  color: white;
}

.pagination.-buttons .pagination__count > * {
  position: relative;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: var(--color-dark-1);
}

.pagination.-buttons .pagination__count > * + * {
  margin-left: 30px;
}

.pagination.-buttons .pagination__count a::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 50%;
  width: 16px;
  height: 2px;
  transform: translateX(-50%);
  background-color: var(--color-purple-1);
  transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  opacity: 0;
}

.pagination.-buttons .pagination__count a.-count-is-active {
  color: var(--color-purple-1);
}

.pagination.-buttons .pagination__count a.-count-is-active::after {
  opacity: 1;
}

.pagination.-avatars {
  display: flex;
  align-items: center;
}

.pagination.-avatars .pagination__item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination.-avatars .pagination__item::before {
  content: "";
  position: absolute;
  border-radius: 100%;
  width: 92px;
  height: 92px;
  border: 2px solid var(--color-dark-1);
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  opacity: 0;
}

.pagination.-avatars .pagination__item.is-active::before {
  opacity: 1;
}

:root {
  --dashboard-width: 300px;
}

@media (max-width: 1199px) {
  :root {
    --dashboard-width: 250px;
  }
}

.dashboard {
  display: flex;
}

@media (max-width: 991px) {
  .dashboard {
    flex-direction: column;
  }
}

.dashboard__main {
  overflow: hidden;
  width: 100%;
  padding-left: var(--dashboard-width);
  will-change: padding-left;
  transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.dashboard__main::after {
  content: "";
  display: none;
  position: fixed;
  z-index: 50;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  pointer-events: none;
  opacity: 0;
}

@media (max-width: 991px) {
  .dashboard__main {
    padding-left: 0;
  }
  .dashboard__main::after {
    display: block;
  }
}

.dashboard__content {
  width: 100%;
  padding: 60px;
  padding-bottom: 0;
}

@media (max-width: 767px) {
  .dashboard__content {
    padding: 30px;
    padding-bottom: 0;
  }
}

@media (max-width: 575px) {
  .dashboard__content {
    padding: 20px;
    padding-bottom: 0;
  }
}

.dashboard__sidebar {
  overflow-y: scroll;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
  width: var(--dashboard-width);
  height: calc(100% - 90px);
  padding: 30px;
  padding-top: 60px;
  padding-bottom: 40px;
  will-change: transform;
  transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
}

@media (max-width: 991px) {
  .dashboard__sidebar {
    transform: translateX(-100%);
  }
}

@media (max-width: 767px) {
  .dashboard__sidebar {
    width: 80%;
    height: calc(100% - 80px);
  }
}

@media (max-width: 991px) {
  .dashboard.-is-sidebar-open .dashboard__sidebar {
    transform: none;
  }
  .dashboard.-is-sidebar-open .dashboard__main::after {
    pointer-events: auto;
    opacity: 1;
  }
}

@media (min-width: 992px) {
  .dashboard.-is-sidebar-open .dashboard__sidebar {
    transform: translateX(-100%);
  }
  .dashboard.-is-sidebar-open .dashboard__main {
    padding-left: 0;
  }
  .dashboard.-is-sidebar-open .dashboard__main::after {
    pointer-events: auto;
    opacity: 1;
  }
}

.sidebar.-dashboard .sidebar__item {
  border: none;
}

.sidebar.-dashboard .sidebar__button {
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding: 0 15px;
  height: 55px;
}

@media (max-width: 575px) {
  .sidebar.-dashboard .sidebar__button {
    height: 45px;
  }
}

.sidebar.-dashboard .sidebar__button.-is-active {
  background-color: rgba(53, 84, 209, 0.05);
  color: var(--color-blue-1) !important;
}

.scroll-bar-1::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.scroll-bar-1::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 12px;
}

.dropdown {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 8px;
}

.dropdown__button {
  height: 40px;
}

.dropdown .icon {
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.dropdown.-is-dd-active .border-light {
  border: 2px solid var(--color-dark-1) !important;
}

.dropdown.-is-dd-active .icon {
  transform: rotate(180deg);
}

.dropdown__item {
  position: absolute;
  z-index: 500;
  pointer-events: none;
  top: 100%;
  left: 50%;
  padding: 26px;
  background-color: white;
  box-shadow: 0px 25px 70px rgba(1, 33, 58, 0.07);
  min-width: 180px;
  border-radius: 8px;
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  transform: translateX(-50%);
  opacity: 0;
}

.dropdown:hover .dropdown__item {
  z-index: 20;
  transform: translateX(-50%);
  opacity: 1;
  pointer-events: auto;
}

.toggle-element {
  transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  opacity: 0;
  pointer-events: none;
}

.toggle-element.-down {
  transform: translateY(20px);
}

.toggle-element.-is-el-visible {
  z-index: 25;
  transform: none;
  opacity: 1;
  pointer-events: auto;
}

.toggle-element.-dropdown {
  position: absolute;
  top: calc(100% + 10px);
  left: 50%;
  padding: 14px 20px;
  background-color: white;
  box-shadow: 0px 25px 70px rgba(1, 33, 58, 0.07);
  min-width: 160px;
  border-radius: 4px;
  border: 1px solid var(--color-border);
  font-weight: 500;
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  transform: translateX(-50%);
}

.toggle-element.-dropdown-2 {
  position: absolute;
  top: 100%;
  left: 50%;
  padding: 10px;
  background-color: white;
  box-shadow: 0px 25px 70px rgba(1, 33, 58, 0.07);
  min-width: 120px;
  border-radius: 4px;
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  transform: translateX(-50%);
}

.toggle-element.-dropdown-2 a {
  padding: 10px;
}

.toggle-element.-dropdown-2 a:hover {
  background-color: var(--color-light-2);
  color: var(--color-dark-1);
}

.blogCard.-type-1 .blogCard__image {
  overflow: hidden;
}

.blogCard.-type-1 .blogCard__image img {
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.blogCard.-type-1:hover .blogCard__image img {
  transform: scale(1.1);
}

.blogCard.-type-2 .blogCard__image {
  overflow: hidden;
}

.blogCard.-type-2 .blogCard__image img {
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.blogCard.-type-2:hover .blogCard__image img {
  transform: scale(1.15);
}

.blogCard.-type-3 {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}

.blogCard.-type-3 .blogCard__image {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
}

.blogCard.-type-3 .blogCard__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.blogCard.-type-3 .blogCard__content {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(5, 16, 54, 0) 0%, rgba(5, 16, 54, 0.7) 100%);
  padding-left: 50px;
  padding-bottom: 30px;
  padding: 20px 20px;
}

.blogCard.-type-3:hover .blogCard__image img {
  transform: scale(1.1);
}

[data-slider] > div > [data-sign] {
  background-color: unset;
  width: unset;
  height: unset;
  border-radius: unset;
  display: inline-block;
  text-align: unset;
  margin-top: 22px;
  color: black;
}

[data-slider] > div > [data-sign]:last-child {
  float: right;
}

[data-slider] > div > [data-sign] span::before {
  content: 'Min Price: $';
  display: inline-block;
}

[data-slider] > div > [data-sign]:last-child span::before {
  content: 'Max Price: $';
  display: inline-block;
}

[data-slider] > div > [data-sign] > span {
  font-size: 14px;
  line-height: 16px;
}

[data-slider] {
  position: relative;
  border-radius: 10px;
  text-align: left;
}

[data-slider] > div {
  width: 100%;
}

[data-slider] > div > [data-inverse-left] {
  position: absolute;
  left: 0;
  height: 5px;
  border-radius: 10px;
  background-color: var(--color-blue-2);
}

[data-slider] > div > [data-inverse-right] {
  position: absolute;
  right: 0;
  height: 5px;
  border-radius: 10px;
  background-color: var(--color-blue-2);
}

[data-slider] > div > [data-range] {
  position: absolute;
  left: 0;
  height: 5px;
  border-radius: 14px;
  background-color: var(--color-blue-1);
}

[data-slider] > div > [data-thumb] {
  position: absolute;
  top: -7px;
  z-index: 2;
  height: 17px;
  width: 17px;
  text-align: left;
  margin-left: -11px;
  cursor: pointer;
  background-color: #FFF;
  border-radius: 50%;
  outline: none;
  border: 2px solid var(--color-blue-1);
}

div[data-slider] > input[type=range]::-ms-thumb {
  pointer-events: all;
  width: 17px;
  height: 17px;
  border-radius: 0px;
  border: 0 none;
  background: red;
}

div[data-slider] > input[type=range]::-moz-range-thumb {
  pointer-events: all;
  width: 17px;
  height: 17px;
  border-radius: 0px;
  border: 0 none;
  background: red;
}

div[data-slider] > input[type=range]::-webkit-slider-thumb {
  pointer-events: all;
  width: 17px;
  height: 17px;
  border-radius: 0px;
  border: 0 none;
  background: red;
  -webkit-appearance: none;
}

div[data-slider] > input[type=range]::-ms-fill-lower {
  background: transparent;
  border: 0 none;
}

div[data-slider] > input[type=range]::-ms-fill-upper {
  background: transparent;
  border: 0 none;
}

[data-slider] > input[type=range] {
  position: absolute;
  pointer-events: none;
  -webkit-appearance: none;
  z-index: 3;
  height: 5px;
  top: -2px;
  width: 100%;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
}

div[data-slider] > input[type=range]::-ms-track {
  -webkit-appearance: none;
  background: transparent;
  color: transparent;
}

div[data-slider] > input[type=range]::-moz-range-track {
  -moz-appearance: none;
  background: transparent;
  color: transparent;
}

div[data-slider] > input[type=range]:focus::-webkit-slider-runnable-track {
  background: transparent;
  border: transparent;
}

div[data-slider] > input[type=range]:focus {
  outline: none;
}

div[data-slider] > input[type=range]::-ms-tooltip {
  display: none;
}

[data-slider] > div > [data-sign] {
  display: inline-block;
  text-align: center;
  padding: 4px 16px;
  border-radius: 8px;
  margin-top: 22px;
}

[data-slider] > div > [data-sign] > span {
  font-size: 12px;
  line-height: 28px;
}

[data-slider]:hover > div > [data-sign] {
  opacity: 1;
}

.select {
  position: relative;
}

.select__button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 15px;
  height: 70px;
  border-radius: 4px;
  border: 1px solid #DDDDDD;
  background-color: white;
  text-align: left;
}

.select__icon {
  width: 20px;
  height: 20px;
}

.select__dropdown {
  position: absolute;
  top: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
  z-index: 30;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid var(--color-light-8);
  background-color: white;
  padding: 20px;
  width: 100%;
  min-height: 150px;
  max-height: 300px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid #DDDDDD;
  transition: all 0.2s ease;
  pointer-events: none;
  opacity: 0;
}

.select__dropdown.-is-visible {
  opacity: 1;
  pointer-events: auto;
}

.select__options {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: scroll;
}

.select__options::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.select__options::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 12px;
}

.select__options__button {
  display: flex;
  align-items: center;
  font-size: 15px;
  padding: 6px 0;
  text-align: left;
  transition: all 0.15s ease;
}

.select__options__button:hover {
  cursor: pointer;
  color: var(--color-purple-1) !important;
}

.select__options__button:focus {
  color: var(--color-purple-1) !important;
}

.select__search {
  flex-shrink: 0;
  height: 50px;
  border-radius: 4px;
  background-color: #F5F5F5 !important;
  color: black;
  padding: 0 20px;
  margin-bottom: 12px;
}

.mapFilter {
  overflow: hidden;
  position: fixed;
  top: 30px;
  bottom: 70px;
  left: 30px;
  right: 30px;
  z-index: 1000;
  border-radius: 4px;
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  pointer-events: none;
  opacity: 0;
}

@media (max-width: 1400px) {
  .mapFilter {
    top: 20px;
    bottom: 20px;
    left: 20px;
    right: 20px;
  }
}

@media (max-width: 1199px) {
  .mapFilter {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 0;
  }
}

.mapFilter.-is-active {
  pointer-events: auto;
  opacity: 1;
}

.mapFilter__overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: rgba(5, 16, 54, 0.4);
}

.mapFilter__close {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 5;
}

@media (max-width: 575px) {
  .mapFilter__close {
    right: 50%;
    transform: translateX(50%);
  }
}

.mapFilter__grid {
  display: grid;
  gap: 0;
  grid-template-columns: 515px 1fr;
  width: 100%;
  height: 100%;
  background-color: white;
  padding-left: 340px;
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
}

@media (max-width: 991px) {
  .mapFilter__grid {
    grid-template-columns: 420px 1fr;
  }
}

@media (max-width: 767px) {
  .mapFilter__grid {
    grid-template-columns: 250px 1fr;
    padding-left: 330px;
  }
  .mapFilter__grid .ratio {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 575px) {
  .mapFilter__grid {
    grid-template-columns: 1fr;
  }
}

.mapFilter__grid.-filters-hidden {
  padding-left: 80px;
}

.mapFilter__grid.-filters-hidden .mapFilter-filter {
  transform: translateX(-255px);
}

@media (max-width: 767px) {
  .mapFilter__grid.-filters-hidden {
    padding-left: 70px;
  }
}

.mapFilter-filter {
  overflow-y: scroll;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  box-shadow: 0px 10px 35px 0px #0510361A;
  transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.mapFilter-filter__item {
  border-top: 1px solid var(--color-border);
  padding-top: 20px;
  margin-top: 20px;
}

.mapFilter-results {
  overflow-y: scroll;
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

@media (max-width: 575px) {
  .mapFilter-results {
    display: none;
  }
}

.mapFilter-results__item {
  border-top: 1px solid var(--color-border);
  padding-top: 20px;
  margin-top: 20px;
}

.mapFilter-map {
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.mapFilter-map img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.scroll-bar-1::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.scroll-bar-1::-webkit-scrollbar-thumb {
  background-color: #e7e7e7;
  border-radius: 4px;
}

.vignette.-type-1 .hotelsCard__image {
  overflow: hidden;
  border-radius: 4px;
}

.vignette.-type-1 .hotelsCard__image .cardImage__content .swiper-slide,
.vignette.-type-1 .hotelsCard__image .cardImage__content > img {
  transition: all 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.vignette.-type-1 .hotelsCard__image .swiper-slide-active {
  position: relative;
  z-index: 2;
}

.vignette.-type-1 .hotelsCard__title span {
  background-image: linear-gradient(transparent 24px, black 20px);
  background-size: 0;
  background-repeat: no-repeat;
  transition: background-size 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
}

.vignette.-type-1:hover .vignette__image .cardImage__content > img,
.vignette.-type-1:hover .vignette__image .swiper-slide {
  transform: scale(1.15);
}

.vignette.-type-1:hover .vignette__title span {
  background-size: 100% !important;
}

.vignette.-type-1 .vignette__image {
  overflow: hidden;
  border-radius: 4px;
}

.vignette.-type-1 .vignette__image .cardImage__content .swiper-slide,
.vignette.-type-1 .vignette__image .cardImage__content > img {
  transition: all 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.vignette.-type-1 .vignette__image .swiper-slide-active {
  position: relative;
  z-index: 2;
}

.vignette.-type-1 .vignette__title span {
  background-image: linear-gradient(transparent 24px, black 20px);
  background-size: 0;
  background-repeat: no-repeat;
  transition: background-size 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
}

.vignette.-type-1:hover .vignette__image .cardImage__content > img,
.vignette.-type-1:hover .vignette__image .swiper-slide {
  transform: scale(1.15);
}

.vignette.-type-1:hover .vignette__title span {
  background-size: 100% !important;
}

.tourCard.-type-1 .tourCard__image {
  overflow: hidden;
  border-radius: 4px;
}

.tourCard.-type-1 .tourCard__image .cardImage__content .swiper-slide,
.tourCard.-type-1 .tourCard__image .cardImage__content > img {
  transition: all 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.tourCard.-type-1 .tourCard__image .swiper-slide-active {
  position: relative;
  z-index: 2;
}

.tourCard.-type-1 .tourCard__title span {
  background-image: linear-gradient(transparent 24px, black 20px);
  background-size: 0;
  background-repeat: no-repeat;
  transition: background-size 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
}

.tourCard.-type-1:hover .tourCard__image .cardImage__content > img,
.tourCard.-type-1:hover .tourCard__image .swiper-slide {
  transform: scale(1.15);
}

.tourCard.-type-1:hover .tourCard__title span {
  background-size: 100% !important;
}

.activityCard.-type-1 .activityCard__image {
  overflow: hidden;
  border-radius: 4px;
}

.activityCard.-type-1 .activityCard__image .cardImage__content .swiper-slide,
.activityCard.-type-1 .activityCard__image .cardImage__content > img {
  transition: all 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.activityCard.-type-1 .activityCard__image .swiper-slide-active {
  position: relative;
  z-index: 2;
}

.activityCard.-type-1 .activityCard__title span {
  background-image: linear-gradient(transparent 24px, black 20px);
  background-size: 0;
  background-repeat: no-repeat;
  transition: background-size 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
}

.activityCard.-type-1:hover .activityCard__image .cardImage__content > img,
.activityCard.-type-1:hover .activityCard__image .swiper-slide {
  transform: scale(1.15);
}

.activityCard.-type-1:hover .activityCard__title span {
  background-size: 100% !important;
}

.rentalCard.-type-1 .rentalCard__image {
  overflow: hidden;
  border-radius: 4px;
}

.rentalCard.-type-1 .rentalCard__image .cardImage__content .swiper-slide,
.rentalCard.-type-1 .rentalCard__image .cardImage__content > img {
  transition: all 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.rentalCard.-type-1 .rentalCard__image .swiper-slide-active {
  position: relative;
  z-index: 2;
}

.rentalCard.-type-1 .rentalCard__title span {
  background-image: linear-gradient(transparent 24px, black 20px);
  background-size: 0;
  background-repeat: no-repeat;
  transition: background-size 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
}

.rentalCard.-type-1:hover .rentalCard__image .cardImage__content > img,
.rentalCard.-type-1:hover .rentalCard__image .swiper-slide {
  transform: scale(1.15);
}

.rentalCard.-type-1:hover .rentalCard__title span {
  background-size: 100% !important;
}

.rentalCard.-type-2 .rentalCard__image {
  overflow: hidden;
  border-radius: 4px;
}

.rentalCard.-type-2 .rentalCard__image .cardImage__content .swiper-slide,
.rentalCard.-type-2 .rentalCard__image .cardImage__content > img {
  transition: all 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.rentalCard.-type-2 .rentalCard__image .swiper-slide-active {
  position: relative;
  z-index: 2;
}

.rentalCard.-type-2 .rentalCard__title span {
  background-image: linear-gradient(transparent 24px, black 20px);
  background-size: 0;
  background-repeat: no-repeat;
  transition: background-size 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
}

.rentalCard.-type-2:hover .rentalCard__image .cardImage__content > img,
.rentalCard.-type-2:hover .rentalCard__image .swiper-slide {
  transform: scale(1.15);
}

.rentalCard.-type-2:hover .rentalCard__title span {
  background-size: 100% !important;
}

.carCard.-type-1 .carCard__image {
  overflow: hidden;
  border-radius: 4px;
}

.carCard.-type-1 .carCard__image .cardImage__content .swiper-slide,
.carCard.-type-1 .carCard__image .cardImage__content > img {
  transition: all 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.carCard.-type-1 .carCard__image .swiper-slide-active {
  position: relative;
  z-index: 2;
}

.carCard.-type-1:hover .carCard__image .cardImage__content > img,
.carCard.-type-1:hover .carCard__image .swiper-slide {
  transform: scale(1.05);
}

.cruiseCard.-type-1 .cruiseCard__image {
  overflow: hidden;
  border-radius: 4px;
}

.cruiseCard.-type-1 .cruiseCard__image .cardImage__content .swiper-slide,
.cruiseCard.-type-1 .cruiseCard__image .cardImage__content > img {
  transition: all 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.cruiseCard.-type-1 .cruiseCard__image .swiper-slide-active {
  position: relative;
  z-index: 2;
}

.cruiseCard.-type-1 .cruiseCard__title span {
  background-image: linear-gradient(transparent 24px, black 20px);
  background-size: 0;
  background-repeat: no-repeat;
  transition: background-size 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
}

.cruiseCard.-type-1:hover .cruiseCard__image .cardImage__content > img,
.cruiseCard.-type-1:hover .cruiseCard__image .swiper-slide {
  transform: scale(1.15);
}

.cruiseCard.-type-1:hover .cruiseCard__title span {
  background-size: 100% !important;
}

.tourTypeCard.-type-1 {
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.tourTypeCard.-type-1:hover {
  background-color: var(--color-blue-1) !important;
  transform: translateY(-10px);
}

.tourTypeCard.-type-1:hover .tourTypeCard__content > * {
  color: white !important;
}

.destCard.-type-1 {
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.destCard.-type-1 .destCard__image {
  overflow: hidden;
}

.destCard.-type-1 .destCard__image img {
  transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.destCard.-type-1:hover .destCard__image img {
  transform: scale(1.15);
}

.preloader {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white;
  transition: opacity 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.preloader__wrap {
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 72px;
  height: 72px;
  border-radius: 30px;
  background-color: white;
  box-shadow: 0px 2px 24px 0px #00000014;
  animation-name: preloaderWraper;
  animation-iteration-count: infinite;
  animation-duration: 1.8s;
  animation-timing-function: ease;
}

.preloader__icon {
  position: absolute;
  animation-name: preloader;
  animation-iteration-count: infinite;
  animation-duration: 1.8s;
  animation-timing-function: ease;
}

.preloader__title {
  font-size: 47px;
  font-weight: 600;
  line-height: 68px;
  letter-spacing: 0em;
  margin-top: 16px;
}

.preloader.-is-hidden {
  opacity: 0;
  pointer-events: none;
}

@keyframes preloaderWraper {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: none;
  }
}

@keyframes preloader {
  0% {
    transform: translateY(150%) scale(1);
  }
  50% {
    transform: translateY(0%) scale(1.2) rotate(20deg);
  }
  100% {
    transform: translateY(-150%) rotate(-20deg);
  }
}

.py-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.px-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.pt-0 {
  padding-top: 0px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.pr-0 {
  padding-right: 0px !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.py-4 {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.px-4 {
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.pt-4 {
  padding-top: 4px !important;
}

.pb-4 {
  padding-bottom: 4px !important;
}

.pl-4 {
  padding-left: 4px !important;
}

.pr-4 {
  padding-right: 4px !important;
}

.mt-4 {
  margin-top: 4px !important;
}

.mb-4 {
  margin-bottom: 4px !important;
}

.ml-4 {
  margin-left: 4px !important;
}

.mr-4 {
  margin-right: 4px !important;
}

.py-5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.px-5 {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.pt-5 {
  padding-top: 5px !important;
}

.pb-5 {
  padding-bottom: 5px !important;
}

.pl-5 {
  padding-left: 5px !important;
}

.pr-5 {
  padding-right: 5px !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.py-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.px-10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pl-10 {
  padding-left: 10px !important;
}

.pr-10 {
  padding-right: 10px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.py-15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.px-15 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.pt-15 {
  padding-top: 15px !important;
}

.pb-15 {
  padding-bottom: 15px !important;
}

.pl-15 {
  padding-left: 15px !important;
}

.pr-15 {
  padding-right: 15px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.ml-15 {
  margin-left: 15px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.py-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.px-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pl-20 {
  padding-left: 20px !important;
}

.pr-20 {
  padding-right: 20px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.py-24 {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

.px-24 {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.pt-24 {
  padding-top: 24px !important;
}

.pb-24 {
  padding-bottom: 24px !important;
}

.pl-24 {
  padding-left: 24px !important;
}

.pr-24 {
  padding-right: 24px !important;
}

.mt-24 {
  margin-top: 24px !important;
}

.mb-24 {
  margin-bottom: 24px !important;
}

.ml-24 {
  margin-left: 24px !important;
}

.mr-24 {
  margin-right: 24px !important;
}

.py-28 {
  padding-top: 28px !important;
  padding-bottom: 28px !important;
}

.px-28 {
  padding-left: 28px !important;
  padding-right: 28px !important;
}

.pt-28 {
  padding-top: 28px !important;
}

.pb-28 {
  padding-bottom: 28px !important;
}

.pl-28 {
  padding-left: 28px !important;
}

.pr-28 {
  padding-right: 28px !important;
}

.mt-28 {
  margin-top: 28px !important;
}

.mb-28 {
  margin-bottom: 28px !important;
}

.ml-28 {
  margin-left: 28px !important;
}

.mr-28 {
  margin-right: 28px !important;
}

.py-30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.px-30 {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.pl-30 {
  padding-left: 30px !important;
}

.pr-30 {
  padding-right: 30px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.ml-30 {
  margin-left: 30px !important;
}

.mr-30 {
  margin-right: 30px !important;
}

.py-35 {
  padding-top: 35px !important;
  padding-bottom: 35px !important;
}

.px-35 {
  padding-left: 35px !important;
  padding-right: 35px !important;
}

.pt-35 {
  padding-top: 35px !important;
}

.pb-35 {
  padding-bottom: 35px !important;
}

.pl-35 {
  padding-left: 35px !important;
}

.pr-35 {
  padding-right: 35px !important;
}

.mt-35 {
  margin-top: 35px !important;
}

.mb-35 {
  margin-bottom: 35px !important;
}

.ml-35 {
  margin-left: 35px !important;
}

.mr-35 {
  margin-right: 35px !important;
}

.py-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.px-40 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

.pl-40 {
  padding-left: 40px !important;
}

.pr-40 {
  padding-right: 40px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.ml-40 {
  margin-left: 40px !important;
}

.mr-40 {
  margin-right: 40px !important;
}

.py-45 {
  padding-top: 45px !important;
  padding-bottom: 45px !important;
}

.px-45 {
  padding-left: 45px !important;
  padding-right: 45px !important;
}

.pt-45 {
  padding-top: 45px !important;
}

.pb-45 {
  padding-bottom: 45px !important;
}

.pl-45 {
  padding-left: 45px !important;
}

.pr-45 {
  padding-right: 45px !important;
}

.mt-45 {
  margin-top: 45px !important;
}

.mb-45 {
  margin-bottom: 45px !important;
}

.ml-45 {
  margin-left: 45px !important;
}

.mr-45 {
  margin-right: 45px !important;
}

.py-50 {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

.px-50 {
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

.pl-50 {
  padding-left: 50px !important;
}

.pr-50 {
  padding-right: 50px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.ml-50 {
  margin-left: 50px !important;
}

.mr-50 {
  margin-right: 50px !important;
}

.py-60 {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}

.px-60 {
  padding-left: 60px !important;
  padding-right: 60px !important;
}

.pt-60 {
  padding-top: 60px !important;
}

.pb-60 {
  padding-bottom: 60px !important;
}

.pl-60 {
  padding-left: 60px !important;
}

.pr-60 {
  padding-right: 60px !important;
}

.mt-60 {
  margin-top: 60px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.ml-60 {
  margin-left: 60px !important;
}

.mr-60 {
  margin-right: 60px !important;
}

.py-70 {
  padding-top: 70px !important;
  padding-bottom: 70px !important;
}

.px-70 {
  padding-left: 70px !important;
  padding-right: 70px !important;
}

.pt-70 {
  padding-top: 70px !important;
}

.pb-70 {
  padding-bottom: 70px !important;
}

.pl-70 {
  padding-left: 70px !important;
}

.pr-70 {
  padding-right: 70px !important;
}

.mt-70 {
  margin-top: 70px !important;
}

.mb-70 {
  margin-bottom: 70px !important;
}

.ml-70 {
  margin-left: 70px !important;
}

.mr-70 {
  margin-right: 70px !important;
}

.py-80 {
  padding-top: 80px !important;
  padding-bottom: 80px !important;
}

.px-80 {
  padding-left: 80px !important;
  padding-right: 80px !important;
}

.pt-80 {
  padding-top: 80px !important;
}

.pb-80 {
  padding-bottom: 80px !important;
}

.pl-80 {
  padding-left: 80px !important;
}

.pr-80 {
  padding-right: 80px !important;
}

.mt-80 {
  margin-top: 80px !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.ml-80 {
  margin-left: 80px !important;
}

.mr-80 {
  margin-right: 80px !important;
}

.py-90 {
  padding-top: 90px !important;
  padding-bottom: 90px !important;
}

.px-90 {
  padding-left: 90px !important;
  padding-right: 90px !important;
}

.pt-90 {
  padding-top: 90px !important;
}

.pb-90 {
  padding-bottom: 90px !important;
}

.pl-90 {
  padding-left: 90px !important;
}

.pr-90 {
  padding-right: 90px !important;
}

.mt-90 {
  margin-top: 90px !important;
}

.mb-90 {
  margin-bottom: 90px !important;
}

.ml-90 {
  margin-left: 90px !important;
}

.mr-90 {
  margin-right: 90px !important;
}

.py-120 {
  padding-top: 120px !important;
  padding-bottom: 120px !important;
}

.px-120 {
  padding-left: 120px !important;
  padding-right: 120px !important;
}

.pt-120 {
  padding-top: 120px !important;
}

.pb-120 {
  padding-bottom: 120px !important;
}

.pl-120 {
  padding-left: 120px !important;
}

.pr-120 {
  padding-right: 120px !important;
}

.mt-120 {
  margin-top: 120px !important;
}

.mb-120 {
  margin-bottom: 120px !important;
}

.ml-120 {
  margin-left: 120px !important;
}

.mr-120 {
  margin-right: 120px !important;
}

@media (max-width: 1199px) {
  .xl\:py-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .xl\:px-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .xl\:pt-0 {
    padding-top: 0px !important;
  }
  .xl\:pb-0 {
    padding-bottom: 0px !important;
  }
  .xl\:pl-0 {
    padding-left: 0px !important;
  }
  .xl\:pr-0 {
    padding-right: 0px !important;
  }
  .xl\:mt-0 {
    margin-top: 0px !important;
  }
  .xl\:mb-0 {
    margin-bottom: 0px !important;
  }
  .xl\:ml-0 {
    margin-left: 0px !important;
  }
  .xl\:mr-0 {
    margin-right: 0px !important;
  }
  .xl\:py-4 {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }
  .xl\:px-4 {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }
  .xl\:pt-4 {
    padding-top: 4px !important;
  }
  .xl\:pb-4 {
    padding-bottom: 4px !important;
  }
  .xl\:pl-4 {
    padding-left: 4px !important;
  }
  .xl\:pr-4 {
    padding-right: 4px !important;
  }
  .xl\:mt-4 {
    margin-top: 4px !important;
  }
  .xl\:mb-4 {
    margin-bottom: 4px !important;
  }
  .xl\:ml-4 {
    margin-left: 4px !important;
  }
  .xl\:mr-4 {
    margin-right: 4px !important;
  }
  .xl\:py-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .xl\:px-5 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .xl\:pt-5 {
    padding-top: 5px !important;
  }
  .xl\:pb-5 {
    padding-bottom: 5px !important;
  }
  .xl\:pl-5 {
    padding-left: 5px !important;
  }
  .xl\:pr-5 {
    padding-right: 5px !important;
  }
  .xl\:mt-5 {
    margin-top: 5px !important;
  }
  .xl\:mb-5 {
    margin-bottom: 5px !important;
  }
  .xl\:ml-5 {
    margin-left: 5px !important;
  }
  .xl\:mr-5 {
    margin-right: 5px !important;
  }
  .xl\:py-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .xl\:px-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .xl\:pt-10 {
    padding-top: 10px !important;
  }
  .xl\:pb-10 {
    padding-bottom: 10px !important;
  }
  .xl\:pl-10 {
    padding-left: 10px !important;
  }
  .xl\:pr-10 {
    padding-right: 10px !important;
  }
  .xl\:mt-10 {
    margin-top: 10px !important;
  }
  .xl\:mb-10 {
    margin-bottom: 10px !important;
  }
  .xl\:ml-10 {
    margin-left: 10px !important;
  }
  .xl\:mr-10 {
    margin-right: 10px !important;
  }
  .xl\:py-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .xl\:px-15 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .xl\:pt-15 {
    padding-top: 15px !important;
  }
  .xl\:pb-15 {
    padding-bottom: 15px !important;
  }
  .xl\:pl-15 {
    padding-left: 15px !important;
  }
  .xl\:pr-15 {
    padding-right: 15px !important;
  }
  .xl\:mt-15 {
    margin-top: 15px !important;
  }
  .xl\:mb-15 {
    margin-bottom: 15px !important;
  }
  .xl\:ml-15 {
    margin-left: 15px !important;
  }
  .xl\:mr-15 {
    margin-right: 15px !important;
  }
  .xl\:py-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .xl\:px-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .xl\:pt-20 {
    padding-top: 20px !important;
  }
  .xl\:pb-20 {
    padding-bottom: 20px !important;
  }
  .xl\:pl-20 {
    padding-left: 20px !important;
  }
  .xl\:pr-20 {
    padding-right: 20px !important;
  }
  .xl\:mt-20 {
    margin-top: 20px !important;
  }
  .xl\:mb-20 {
    margin-bottom: 20px !important;
  }
  .xl\:ml-20 {
    margin-left: 20px !important;
  }
  .xl\:mr-20 {
    margin-right: 20px !important;
  }
  .xl\:py-24 {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }
  .xl\:px-24 {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
  .xl\:pt-24 {
    padding-top: 24px !important;
  }
  .xl\:pb-24 {
    padding-bottom: 24px !important;
  }
  .xl\:pl-24 {
    padding-left: 24px !important;
  }
  .xl\:pr-24 {
    padding-right: 24px !important;
  }
  .xl\:mt-24 {
    margin-top: 24px !important;
  }
  .xl\:mb-24 {
    margin-bottom: 24px !important;
  }
  .xl\:ml-24 {
    margin-left: 24px !important;
  }
  .xl\:mr-24 {
    margin-right: 24px !important;
  }
  .xl\:py-28 {
    padding-top: 28px !important;
    padding-bottom: 28px !important;
  }
  .xl\:px-28 {
    padding-left: 28px !important;
    padding-right: 28px !important;
  }
  .xl\:pt-28 {
    padding-top: 28px !important;
  }
  .xl\:pb-28 {
    padding-bottom: 28px !important;
  }
  .xl\:pl-28 {
    padding-left: 28px !important;
  }
  .xl\:pr-28 {
    padding-right: 28px !important;
  }
  .xl\:mt-28 {
    margin-top: 28px !important;
  }
  .xl\:mb-28 {
    margin-bottom: 28px !important;
  }
  .xl\:ml-28 {
    margin-left: 28px !important;
  }
  .xl\:mr-28 {
    margin-right: 28px !important;
  }
  .xl\:py-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .xl\:px-30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .xl\:pt-30 {
    padding-top: 30px !important;
  }
  .xl\:pb-30 {
    padding-bottom: 30px !important;
  }
  .xl\:pl-30 {
    padding-left: 30px !important;
  }
  .xl\:pr-30 {
    padding-right: 30px !important;
  }
  .xl\:mt-30 {
    margin-top: 30px !important;
  }
  .xl\:mb-30 {
    margin-bottom: 30px !important;
  }
  .xl\:ml-30 {
    margin-left: 30px !important;
  }
  .xl\:mr-30 {
    margin-right: 30px !important;
  }
  .xl\:py-35 {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  .xl\:px-35 {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
  .xl\:pt-35 {
    padding-top: 35px !important;
  }
  .xl\:pb-35 {
    padding-bottom: 35px !important;
  }
  .xl\:pl-35 {
    padding-left: 35px !important;
  }
  .xl\:pr-35 {
    padding-right: 35px !important;
  }
  .xl\:mt-35 {
    margin-top: 35px !important;
  }
  .xl\:mb-35 {
    margin-bottom: 35px !important;
  }
  .xl\:ml-35 {
    margin-left: 35px !important;
  }
  .xl\:mr-35 {
    margin-right: 35px !important;
  }
  .xl\:py-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .xl\:px-40 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .xl\:pt-40 {
    padding-top: 40px !important;
  }
  .xl\:pb-40 {
    padding-bottom: 40px !important;
  }
  .xl\:pl-40 {
    padding-left: 40px !important;
  }
  .xl\:pr-40 {
    padding-right: 40px !important;
  }
  .xl\:mt-40 {
    margin-top: 40px !important;
  }
  .xl\:mb-40 {
    margin-bottom: 40px !important;
  }
  .xl\:ml-40 {
    margin-left: 40px !important;
  }
  .xl\:mr-40 {
    margin-right: 40px !important;
  }
  .xl\:py-45 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }
  .xl\:px-45 {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }
  .xl\:pt-45 {
    padding-top: 45px !important;
  }
  .xl\:pb-45 {
    padding-bottom: 45px !important;
  }
  .xl\:pl-45 {
    padding-left: 45px !important;
  }
  .xl\:pr-45 {
    padding-right: 45px !important;
  }
  .xl\:mt-45 {
    margin-top: 45px !important;
  }
  .xl\:mb-45 {
    margin-bottom: 45px !important;
  }
  .xl\:ml-45 {
    margin-left: 45px !important;
  }
  .xl\:mr-45 {
    margin-right: 45px !important;
  }
  .xl\:py-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  .xl\:px-50 {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
  .xl\:pt-50 {
    padding-top: 50px !important;
  }
  .xl\:pb-50 {
    padding-bottom: 50px !important;
  }
  .xl\:pl-50 {
    padding-left: 50px !important;
  }
  .xl\:pr-50 {
    padding-right: 50px !important;
  }
  .xl\:mt-50 {
    margin-top: 50px !important;
  }
  .xl\:mb-50 {
    margin-bottom: 50px !important;
  }
  .xl\:ml-50 {
    margin-left: 50px !important;
  }
  .xl\:mr-50 {
    margin-right: 50px !important;
  }
  .xl\:py-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .xl\:px-60 {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
  .xl\:pt-60 {
    padding-top: 60px !important;
  }
  .xl\:pb-60 {
    padding-bottom: 60px !important;
  }
  .xl\:pl-60 {
    padding-left: 60px !important;
  }
  .xl\:pr-60 {
    padding-right: 60px !important;
  }
  .xl\:mt-60 {
    margin-top: 60px !important;
  }
  .xl\:mb-60 {
    margin-bottom: 60px !important;
  }
  .xl\:ml-60 {
    margin-left: 60px !important;
  }
  .xl\:mr-60 {
    margin-right: 60px !important;
  }
  .xl\:py-70 {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }
  .xl\:px-70 {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }
  .xl\:pt-70 {
    padding-top: 70px !important;
  }
  .xl\:pb-70 {
    padding-bottom: 70px !important;
  }
  .xl\:pl-70 {
    padding-left: 70px !important;
  }
  .xl\:pr-70 {
    padding-right: 70px !important;
  }
  .xl\:mt-70 {
    margin-top: 70px !important;
  }
  .xl\:mb-70 {
    margin-bottom: 70px !important;
  }
  .xl\:ml-70 {
    margin-left: 70px !important;
  }
  .xl\:mr-70 {
    margin-right: 70px !important;
  }
  .xl\:py-80 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  .xl\:px-80 {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
  .xl\:pt-80 {
    padding-top: 80px !important;
  }
  .xl\:pb-80 {
    padding-bottom: 80px !important;
  }
  .xl\:pl-80 {
    padding-left: 80px !important;
  }
  .xl\:pr-80 {
    padding-right: 80px !important;
  }
  .xl\:mt-80 {
    margin-top: 80px !important;
  }
  .xl\:mb-80 {
    margin-bottom: 80px !important;
  }
  .xl\:ml-80 {
    margin-left: 80px !important;
  }
  .xl\:mr-80 {
    margin-right: 80px !important;
  }
  .xl\:py-90 {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }
  .xl\:px-90 {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }
  .xl\:pt-90 {
    padding-top: 90px !important;
  }
  .xl\:pb-90 {
    padding-bottom: 90px !important;
  }
  .xl\:pl-90 {
    padding-left: 90px !important;
  }
  .xl\:pr-90 {
    padding-right: 90px !important;
  }
  .xl\:mt-90 {
    margin-top: 90px !important;
  }
  .xl\:mb-90 {
    margin-bottom: 90px !important;
  }
  .xl\:ml-90 {
    margin-left: 90px !important;
  }
  .xl\:mr-90 {
    margin-right: 90px !important;
  }
  .xl\:py-120 {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
  }
  .xl\:px-120 {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }
  .xl\:pt-120 {
    padding-top: 120px !important;
  }
  .xl\:pb-120 {
    padding-bottom: 120px !important;
  }
  .xl\:pl-120 {
    padding-left: 120px !important;
  }
  .xl\:pr-120 {
    padding-right: 120px !important;
  }
  .xl\:mt-120 {
    margin-top: 120px !important;
  }
  .xl\:mb-120 {
    margin-bottom: 120px !important;
  }
  .xl\:ml-120 {
    margin-left: 120px !important;
  }
  .xl\:mr-120 {
    margin-right: 120px !important;
  }
}

@media (max-width: 991px) {
  .lg\:py-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .lg\:px-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .lg\:pt-0 {
    padding-top: 0px !important;
  }
  .lg\:pb-0 {
    padding-bottom: 0px !important;
  }
  .lg\:pl-0 {
    padding-left: 0px !important;
  }
  .lg\:pr-0 {
    padding-right: 0px !important;
  }
  .lg\:mt-0 {
    margin-top: 0px !important;
  }
  .lg\:mb-0 {
    margin-bottom: 0px !important;
  }
  .lg\:ml-0 {
    margin-left: 0px !important;
  }
  .lg\:mr-0 {
    margin-right: 0px !important;
  }
  .lg\:py-4 {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }
  .lg\:px-4 {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }
  .lg\:pt-4 {
    padding-top: 4px !important;
  }
  .lg\:pb-4 {
    padding-bottom: 4px !important;
  }
  .lg\:pl-4 {
    padding-left: 4px !important;
  }
  .lg\:pr-4 {
    padding-right: 4px !important;
  }
  .lg\:mt-4 {
    margin-top: 4px !important;
  }
  .lg\:mb-4 {
    margin-bottom: 4px !important;
  }
  .lg\:ml-4 {
    margin-left: 4px !important;
  }
  .lg\:mr-4 {
    margin-right: 4px !important;
  }
  .lg\:py-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .lg\:px-5 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .lg\:pt-5 {
    padding-top: 5px !important;
  }
  .lg\:pb-5 {
    padding-bottom: 5px !important;
  }
  .lg\:pl-5 {
    padding-left: 5px !important;
  }
  .lg\:pr-5 {
    padding-right: 5px !important;
  }
  .lg\:mt-5 {
    margin-top: 5px !important;
  }
  .lg\:mb-5 {
    margin-bottom: 5px !important;
  }
  .lg\:ml-5 {
    margin-left: 5px !important;
  }
  .lg\:mr-5 {
    margin-right: 5px !important;
  }
  .lg\:py-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .lg\:px-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .lg\:pt-10 {
    padding-top: 10px !important;
  }
  .lg\:pb-10 {
    padding-bottom: 10px !important;
  }
  .lg\:pl-10 {
    padding-left: 10px !important;
  }
  .lg\:pr-10 {
    padding-right: 10px !important;
  }
  .lg\:mt-10 {
    margin-top: 10px !important;
  }
  .lg\:mb-10 {
    margin-bottom: 10px !important;
  }
  .lg\:ml-10 {
    margin-left: 10px !important;
  }
  .lg\:mr-10 {
    margin-right: 10px !important;
  }
  .lg\:py-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .lg\:px-15 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .lg\:pt-15 {
    padding-top: 15px !important;
  }
  .lg\:pb-15 {
    padding-bottom: 15px !important;
  }
  .lg\:pl-15 {
    padding-left: 15px !important;
  }
  .lg\:pr-15 {
    padding-right: 15px !important;
  }
  .lg\:mt-15 {
    margin-top: 15px !important;
  }
  .lg\:mb-15 {
    margin-bottom: 15px !important;
  }
  .lg\:ml-15 {
    margin-left: 15px !important;
  }
  .lg\:mr-15 {
    margin-right: 15px !important;
  }
  .lg\:py-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .lg\:px-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .lg\:pt-20 {
    padding-top: 20px !important;
  }
  .lg\:pb-20 {
    padding-bottom: 20px !important;
  }
  .lg\:pl-20 {
    padding-left: 20px !important;
  }
  .lg\:pr-20 {
    padding-right: 20px !important;
  }
  .lg\:mt-20 {
    margin-top: 20px !important;
  }
  .lg\:mb-20 {
    margin-bottom: 20px !important;
  }
  .lg\:ml-20 {
    margin-left: 20px !important;
  }
  .lg\:mr-20 {
    margin-right: 20px !important;
  }
  .lg\:py-24 {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }
  .lg\:px-24 {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
  .lg\:pt-24 {
    padding-top: 24px !important;
  }
  .lg\:pb-24 {
    padding-bottom: 24px !important;
  }
  .lg\:pl-24 {
    padding-left: 24px !important;
  }
  .lg\:pr-24 {
    padding-right: 24px !important;
  }
  .lg\:mt-24 {
    margin-top: 24px !important;
  }
  .lg\:mb-24 {
    margin-bottom: 24px !important;
  }
  .lg\:ml-24 {
    margin-left: 24px !important;
  }
  .lg\:mr-24 {
    margin-right: 24px !important;
  }
  .lg\:py-28 {
    padding-top: 28px !important;
    padding-bottom: 28px !important;
  }
  .lg\:px-28 {
    padding-left: 28px !important;
    padding-right: 28px !important;
  }
  .lg\:pt-28 {
    padding-top: 28px !important;
  }
  .lg\:pb-28 {
    padding-bottom: 28px !important;
  }
  .lg\:pl-28 {
    padding-left: 28px !important;
  }
  .lg\:pr-28 {
    padding-right: 28px !important;
  }
  .lg\:mt-28 {
    margin-top: 28px !important;
  }
  .lg\:mb-28 {
    margin-bottom: 28px !important;
  }
  .lg\:ml-28 {
    margin-left: 28px !important;
  }
  .lg\:mr-28 {
    margin-right: 28px !important;
  }
  .lg\:py-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .lg\:px-30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .lg\:pt-30 {
    padding-top: 30px !important;
  }
  .lg\:pb-30 {
    padding-bottom: 30px !important;
  }
  .lg\:pl-30 {
    padding-left: 30px !important;
  }
  .lg\:pr-30 {
    padding-right: 30px !important;
  }
  .lg\:mt-30 {
    margin-top: 30px !important;
  }
  .lg\:mb-30 {
    margin-bottom: 30px !important;
  }
  .lg\:ml-30 {
    margin-left: 30px !important;
  }
  .lg\:mr-30 {
    margin-right: 30px !important;
  }
  .lg\:py-35 {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  .lg\:px-35 {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
  .lg\:pt-35 {
    padding-top: 35px !important;
  }
  .lg\:pb-35 {
    padding-bottom: 35px !important;
  }
  .lg\:pl-35 {
    padding-left: 35px !important;
  }
  .lg\:pr-35 {
    padding-right: 35px !important;
  }
  .lg\:mt-35 {
    margin-top: 35px !important;
  }
  .lg\:mb-35 {
    margin-bottom: 35px !important;
  }
  .lg\:ml-35 {
    margin-left: 35px !important;
  }
  .lg\:mr-35 {
    margin-right: 35px !important;
  }
  .lg\:py-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .lg\:px-40 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .lg\:pt-40 {
    padding-top: 40px !important;
  }
  .lg\:pb-40 {
    padding-bottom: 40px !important;
  }
  .lg\:pl-40 {
    padding-left: 40px !important;
  }
  .lg\:pr-40 {
    padding-right: 40px !important;
  }
  .lg\:mt-40 {
    margin-top: 40px !important;
  }
  .lg\:mb-40 {
    margin-bottom: 40px !important;
  }
  .lg\:ml-40 {
    margin-left: 40px !important;
  }
  .lg\:mr-40 {
    margin-right: 40px !important;
  }
  .lg\:py-45 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }
  .lg\:px-45 {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }
  .lg\:pt-45 {
    padding-top: 45px !important;
  }
  .lg\:pb-45 {
    padding-bottom: 45px !important;
  }
  .lg\:pl-45 {
    padding-left: 45px !important;
  }
  .lg\:pr-45 {
    padding-right: 45px !important;
  }
  .lg\:mt-45 {
    margin-top: 45px !important;
  }
  .lg\:mb-45 {
    margin-bottom: 45px !important;
  }
  .lg\:ml-45 {
    margin-left: 45px !important;
  }
  .lg\:mr-45 {
    margin-right: 45px !important;
  }
  .lg\:py-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  .lg\:px-50 {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
  .lg\:pt-50 {
    padding-top: 50px !important;
  }
  .lg\:pb-50 {
    padding-bottom: 50px !important;
  }
  .lg\:pl-50 {
    padding-left: 50px !important;
  }
  .lg\:pr-50 {
    padding-right: 50px !important;
  }
  .lg\:mt-50 {
    margin-top: 50px !important;
  }
  .lg\:mb-50 {
    margin-bottom: 50px !important;
  }
  .lg\:ml-50 {
    margin-left: 50px !important;
  }
  .lg\:mr-50 {
    margin-right: 50px !important;
  }
  .lg\:py-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .lg\:px-60 {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
  .lg\:pt-60 {
    padding-top: 60px !important;
  }
  .lg\:pb-60 {
    padding-bottom: 60px !important;
  }
  .lg\:pl-60 {
    padding-left: 60px !important;
  }
  .lg\:pr-60 {
    padding-right: 60px !important;
  }
  .lg\:mt-60 {
    margin-top: 60px !important;
  }
  .lg\:mb-60 {
    margin-bottom: 60px !important;
  }
  .lg\:ml-60 {
    margin-left: 60px !important;
  }
  .lg\:mr-60 {
    margin-right: 60px !important;
  }
  .lg\:py-70 {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }
  .lg\:px-70 {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }
  .lg\:pt-70 {
    padding-top: 70px !important;
  }
  .lg\:pb-70 {
    padding-bottom: 70px !important;
  }
  .lg\:pl-70 {
    padding-left: 70px !important;
  }
  .lg\:pr-70 {
    padding-right: 70px !important;
  }
  .lg\:mt-70 {
    margin-top: 70px !important;
  }
  .lg\:mb-70 {
    margin-bottom: 70px !important;
  }
  .lg\:ml-70 {
    margin-left: 70px !important;
  }
  .lg\:mr-70 {
    margin-right: 70px !important;
  }
  .lg\:py-80 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  .lg\:px-80 {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
  .lg\:pt-80 {
    padding-top: 80px !important;
  }
  .lg\:pb-80 {
    padding-bottom: 80px !important;
  }
  .lg\:pl-80 {
    padding-left: 80px !important;
  }
  .lg\:pr-80 {
    padding-right: 80px !important;
  }
  .lg\:mt-80 {
    margin-top: 80px !important;
  }
  .lg\:mb-80 {
    margin-bottom: 80px !important;
  }
  .lg\:ml-80 {
    margin-left: 80px !important;
  }
  .lg\:mr-80 {
    margin-right: 80px !important;
  }
  .lg\:py-90 {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }
  .lg\:px-90 {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }
  .lg\:pt-90 {
    padding-top: 90px !important;
  }
  .lg\:pb-90 {
    padding-bottom: 90px !important;
  }
  .lg\:pl-90 {
    padding-left: 90px !important;
  }
  .lg\:pr-90 {
    padding-right: 90px !important;
  }
  .lg\:mt-90 {
    margin-top: 90px !important;
  }
  .lg\:mb-90 {
    margin-bottom: 90px !important;
  }
  .lg\:ml-90 {
    margin-left: 90px !important;
  }
  .lg\:mr-90 {
    margin-right: 90px !important;
  }
  .lg\:py-120 {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
  }
  .lg\:px-120 {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }
  .lg\:pt-120 {
    padding-top: 120px !important;
  }
  .lg\:pb-120 {
    padding-bottom: 120px !important;
  }
  .lg\:pl-120 {
    padding-left: 120px !important;
  }
  .lg\:pr-120 {
    padding-right: 120px !important;
  }
  .lg\:mt-120 {
    margin-top: 120px !important;
  }
  .lg\:mb-120 {
    margin-bottom: 120px !important;
  }
  .lg\:ml-120 {
    margin-left: 120px !important;
  }
  .lg\:mr-120 {
    margin-right: 120px !important;
  }
}

@media (max-width: 767px) {
  .md\:py-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .md\:px-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .md\:pt-0 {
    padding-top: 0px !important;
  }
  .md\:pb-0 {
    padding-bottom: 0px !important;
  }
  .md\:pl-0 {
    padding-left: 0px !important;
  }
  .md\:pr-0 {
    padding-right: 0px !important;
  }
  .md\:mt-0 {
    margin-top: 0px !important;
  }
  .md\:mb-0 {
    margin-bottom: 0px !important;
  }
  .md\:ml-0 {
    margin-left: 0px !important;
  }
  .md\:mr-0 {
    margin-right: 0px !important;
  }
  .md\:py-4 {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }
  .md\:px-4 {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }
  .md\:pt-4 {
    padding-top: 4px !important;
  }
  .md\:pb-4 {
    padding-bottom: 4px !important;
  }
  .md\:pl-4 {
    padding-left: 4px !important;
  }
  .md\:pr-4 {
    padding-right: 4px !important;
  }
  .md\:mt-4 {
    margin-top: 4px !important;
  }
  .md\:mb-4 {
    margin-bottom: 4px !important;
  }
  .md\:ml-4 {
    margin-left: 4px !important;
  }
  .md\:mr-4 {
    margin-right: 4px !important;
  }
  .md\:py-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .md\:px-5 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .md\:pt-5 {
    padding-top: 5px !important;
  }
  .md\:pb-5 {
    padding-bottom: 5px !important;
  }
  .md\:pl-5 {
    padding-left: 5px !important;
  }
  .md\:pr-5 {
    padding-right: 5px !important;
  }
  .md\:mt-5 {
    margin-top: 5px !important;
  }
  .md\:mb-5 {
    margin-bottom: 5px !important;
  }
  .md\:ml-5 {
    margin-left: 5px !important;
  }
  .md\:mr-5 {
    margin-right: 5px !important;
  }
  .md\:py-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .md\:px-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .md\:pt-10 {
    padding-top: 10px !important;
  }
  .md\:pb-10 {
    padding-bottom: 10px !important;
  }
  .md\:pl-10 {
    padding-left: 10px !important;
  }
  .md\:pr-10 {
    padding-right: 10px !important;
  }
  .md\:mt-10 {
    margin-top: 10px !important;
  }
  .md\:mb-10 {
    margin-bottom: 10px !important;
  }
  .md\:ml-10 {
    margin-left: 10px !important;
  }
  .md\:mr-10 {
    margin-right: 10px !important;
  }
  .md\:py-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .md\:px-15 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .md\:pt-15 {
    padding-top: 15px !important;
  }
  .md\:pb-15 {
    padding-bottom: 15px !important;
  }
  .md\:pl-15 {
    padding-left: 15px !important;
  }
  .md\:pr-15 {
    padding-right: 15px !important;
  }
  .md\:mt-15 {
    margin-top: 15px !important;
  }
  .md\:mb-15 {
    margin-bottom: 15px !important;
  }
  .md\:ml-15 {
    margin-left: 15px !important;
  }
  .md\:mr-15 {
    margin-right: 15px !important;
  }
  .md\:py-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .md\:px-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .md\:pt-20 {
    padding-top: 20px !important;
  }
  .md\:pb-20 {
    padding-bottom: 20px !important;
  }
  .md\:pl-20 {
    padding-left: 20px !important;
  }
  .md\:pr-20 {
    padding-right: 20px !important;
  }
  .md\:mt-20 {
    margin-top: 20px !important;
  }
  .md\:mb-20 {
    margin-bottom: 20px !important;
  }
  .md\:ml-20 {
    margin-left: 20px !important;
  }
  .md\:mr-20 {
    margin-right: 20px !important;
  }
  .md\:py-24 {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }
  .md\:px-24 {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
  .md\:pt-24 {
    padding-top: 24px !important;
  }
  .md\:pb-24 {
    padding-bottom: 24px !important;
  }
  .md\:pl-24 {
    padding-left: 24px !important;
  }
  .md\:pr-24 {
    padding-right: 24px !important;
  }
  .md\:mt-24 {
    margin-top: 24px !important;
  }
  .md\:mb-24 {
    margin-bottom: 24px !important;
  }
  .md\:ml-24 {
    margin-left: 24px !important;
  }
  .md\:mr-24 {
    margin-right: 24px !important;
  }
  .md\:py-28 {
    padding-top: 28px !important;
    padding-bottom: 28px !important;
  }
  .md\:px-28 {
    padding-left: 28px !important;
    padding-right: 28px !important;
  }
  .md\:pt-28 {
    padding-top: 28px !important;
  }
  .md\:pb-28 {
    padding-bottom: 28px !important;
  }
  .md\:pl-28 {
    padding-left: 28px !important;
  }
  .md\:pr-28 {
    padding-right: 28px !important;
  }
  .md\:mt-28 {
    margin-top: 28px !important;
  }
  .md\:mb-28 {
    margin-bottom: 28px !important;
  }
  .md\:ml-28 {
    margin-left: 28px !important;
  }
  .md\:mr-28 {
    margin-right: 28px !important;
  }
  .md\:py-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .md\:px-30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .md\:pt-30 {
    padding-top: 30px !important;
  }
  .md\:pb-30 {
    padding-bottom: 30px !important;
  }
  .md\:pl-30 {
    padding-left: 30px !important;
  }
  .md\:pr-30 {
    padding-right: 30px !important;
  }
  .md\:mt-30 {
    margin-top: 30px !important;
  }
  .md\:mb-30 {
    margin-bottom: 30px !important;
  }
  .md\:ml-30 {
    margin-left: 30px !important;
  }
  .md\:mr-30 {
    margin-right: 30px !important;
  }
  .md\:py-35 {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  .md\:px-35 {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
  .md\:pt-35 {
    padding-top: 35px !important;
  }
  .md\:pb-35 {
    padding-bottom: 35px !important;
  }
  .md\:pl-35 {
    padding-left: 35px !important;
  }
  .md\:pr-35 {
    padding-right: 35px !important;
  }
  .md\:mt-35 {
    margin-top: 35px !important;
  }
  .md\:mb-35 {
    margin-bottom: 35px !important;
  }
  .md\:ml-35 {
    margin-left: 35px !important;
  }
  .md\:mr-35 {
    margin-right: 35px !important;
  }
  .md\:py-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .md\:px-40 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .md\:pt-40 {
    padding-top: 40px !important;
  }
  .md\:pb-40 {
    padding-bottom: 40px !important;
  }
  .md\:pl-40 {
    padding-left: 40px !important;
  }
  .md\:pr-40 {
    padding-right: 40px !important;
  }
  .md\:mt-40 {
    margin-top: 40px !important;
  }
  .md\:mb-40 {
    margin-bottom: 40px !important;
  }
  .md\:ml-40 {
    margin-left: 40px !important;
  }
  .md\:mr-40 {
    margin-right: 40px !important;
  }
  .md\:py-45 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }
  .md\:px-45 {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }
  .md\:pt-45 {
    padding-top: 45px !important;
  }
  .md\:pb-45 {
    padding-bottom: 45px !important;
  }
  .md\:pl-45 {
    padding-left: 45px !important;
  }
  .md\:pr-45 {
    padding-right: 45px !important;
  }
  .md\:mt-45 {
    margin-top: 45px !important;
  }
  .md\:mb-45 {
    margin-bottom: 45px !important;
  }
  .md\:ml-45 {
    margin-left: 45px !important;
  }
  .md\:mr-45 {
    margin-right: 45px !important;
  }
  .md\:py-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  .md\:px-50 {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
  .md\:pt-50 {
    padding-top: 50px !important;
  }
  .md\:pb-50 {
    padding-bottom: 50px !important;
  }
  .md\:pl-50 {
    padding-left: 50px !important;
  }
  .md\:pr-50 {
    padding-right: 50px !important;
  }
  .md\:mt-50 {
    margin-top: 50px !important;
  }
  .md\:mb-50 {
    margin-bottom: 50px !important;
  }
  .md\:ml-50 {
    margin-left: 50px !important;
  }
  .md\:mr-50 {
    margin-right: 50px !important;
  }
  .md\:py-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .md\:px-60 {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
  .md\:pt-60 {
    padding-top: 60px !important;
  }
  .md\:pb-60 {
    padding-bottom: 60px !important;
  }
  .md\:pl-60 {
    padding-left: 60px !important;
  }
  .md\:pr-60 {
    padding-right: 60px !important;
  }
  .md\:mt-60 {
    margin-top: 60px !important;
  }
  .md\:mb-60 {
    margin-bottom: 60px !important;
  }
  .md\:ml-60 {
    margin-left: 60px !important;
  }
  .md\:mr-60 {
    margin-right: 60px !important;
  }
  .md\:py-70 {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }
  .md\:px-70 {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }
  .md\:pt-70 {
    padding-top: 70px !important;
  }
  .md\:pb-70 {
    padding-bottom: 70px !important;
  }
  .md\:pl-70 {
    padding-left: 70px !important;
  }
  .md\:pr-70 {
    padding-right: 70px !important;
  }
  .md\:mt-70 {
    margin-top: 70px !important;
  }
  .md\:mb-70 {
    margin-bottom: 70px !important;
  }
  .md\:ml-70 {
    margin-left: 70px !important;
  }
  .md\:mr-70 {
    margin-right: 70px !important;
  }
  .md\:py-80 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  .md\:px-80 {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
  .md\:pt-80 {
    padding-top: 80px !important;
  }
  .md\:pb-80 {
    padding-bottom: 80px !important;
  }
  .md\:pl-80 {
    padding-left: 80px !important;
  }
  .md\:pr-80 {
    padding-right: 80px !important;
  }
  .md\:mt-80 {
    margin-top: 80px !important;
  }
  .md\:mb-80 {
    margin-bottom: 80px !important;
  }
  .md\:ml-80 {
    margin-left: 80px !important;
  }
  .md\:mr-80 {
    margin-right: 80px !important;
  }
  .md\:py-90 {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }
  .md\:px-90 {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }
  .md\:pt-90 {
    padding-top: 90px !important;
  }
  .md\:pb-90 {
    padding-bottom: 90px !important;
  }
  .md\:pl-90 {
    padding-left: 90px !important;
  }
  .md\:pr-90 {
    padding-right: 90px !important;
  }
  .md\:mt-90 {
    margin-top: 90px !important;
  }
  .md\:mb-90 {
    margin-bottom: 90px !important;
  }
  .md\:ml-90 {
    margin-left: 90px !important;
  }
  .md\:mr-90 {
    margin-right: 90px !important;
  }
  .md\:py-120 {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
  }
  .md\:px-120 {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }
  .md\:pt-120 {
    padding-top: 120px !important;
  }
  .md\:pb-120 {
    padding-bottom: 120px !important;
  }
  .md\:pl-120 {
    padding-left: 120px !important;
  }
  .md\:pr-120 {
    padding-right: 120px !important;
  }
  .md\:mt-120 {
    margin-top: 120px !important;
  }
  .md\:mb-120 {
    margin-bottom: 120px !important;
  }
  .md\:ml-120 {
    margin-left: 120px !important;
  }
  .md\:mr-120 {
    margin-right: 120px !important;
  }
}

@media (max-width: 575px) {
  .sm\:py-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .sm\:px-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .sm\:pt-0 {
    padding-top: 0px !important;
  }
  .sm\:pb-0 {
    padding-bottom: 0px !important;
  }
  .sm\:pl-0 {
    padding-left: 0px !important;
  }
  .sm\:pr-0 {
    padding-right: 0px !important;
  }
  .sm\:mt-0 {
    margin-top: 0px !important;
  }
  .sm\:mb-0 {
    margin-bottom: 0px !important;
  }
  .sm\:ml-0 {
    margin-left: 0px !important;
  }
  .sm\:mr-0 {
    margin-right: 0px !important;
  }
  .sm\:py-4 {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }
  .sm\:px-4 {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }
  .sm\:pt-4 {
    padding-top: 4px !important;
  }
  .sm\:pb-4 {
    padding-bottom: 4px !important;
  }
  .sm\:pl-4 {
    padding-left: 4px !important;
  }
  .sm\:pr-4 {
    padding-right: 4px !important;
  }
  .sm\:mt-4 {
    margin-top: 4px !important;
  }
  .sm\:mb-4 {
    margin-bottom: 4px !important;
  }
  .sm\:ml-4 {
    margin-left: 4px !important;
  }
  .sm\:mr-4 {
    margin-right: 4px !important;
  }
  .sm\:py-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .sm\:px-5 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .sm\:pt-5 {
    padding-top: 5px !important;
  }
  .sm\:pb-5 {
    padding-bottom: 5px !important;
  }
  .sm\:pl-5 {
    padding-left: 5px !important;
  }
  .sm\:pr-5 {
    padding-right: 5px !important;
  }
  .sm\:mt-5 {
    margin-top: 5px !important;
  }
  .sm\:mb-5 {
    margin-bottom: 5px !important;
  }
  .sm\:ml-5 {
    margin-left: 5px !important;
  }
  .sm\:mr-5 {
    margin-right: 5px !important;
  }
  .sm\:py-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .sm\:px-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .sm\:pt-10 {
    padding-top: 10px !important;
  }
  .sm\:pb-10 {
    padding-bottom: 10px !important;
  }
  .sm\:pl-10 {
    padding-left: 10px !important;
  }
  .sm\:pr-10 {
    padding-right: 10px !important;
  }
  .sm\:mt-10 {
    margin-top: 10px !important;
  }
  .sm\:mb-10 {
    margin-bottom: 10px !important;
  }
  .sm\:ml-10 {
    margin-left: 10px !important;
  }
  .sm\:mr-10 {
    margin-right: 10px !important;
  }
  .sm\:py-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .sm\:px-15 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .sm\:pt-15 {
    padding-top: 15px !important;
  }
  .sm\:pb-15 {
    padding-bottom: 15px !important;
  }
  .sm\:pl-15 {
    padding-left: 15px !important;
  }
  .sm\:pr-15 {
    padding-right: 15px !important;
  }
  .sm\:mt-15 {
    margin-top: 15px !important;
  }
  .sm\:mb-15 {
    margin-bottom: 15px !important;
  }
  .sm\:ml-15 {
    margin-left: 15px !important;
  }
  .sm\:mr-15 {
    margin-right: 15px !important;
  }
  .sm\:py-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .sm\:px-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .sm\:pt-20 {
    padding-top: 20px !important;
  }
  .sm\:pb-20 {
    padding-bottom: 20px !important;
  }
  .sm\:pl-20 {
    padding-left: 20px !important;
  }
  .sm\:pr-20 {
    padding-right: 20px !important;
  }
  .sm\:mt-20 {
    margin-top: 20px !important;
  }
  .sm\:mb-20 {
    margin-bottom: 20px !important;
  }
  .sm\:ml-20 {
    margin-left: 20px !important;
  }
  .sm\:mr-20 {
    margin-right: 20px !important;
  }
  .sm\:py-24 {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }
  .sm\:px-24 {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
  .sm\:pt-24 {
    padding-top: 24px !important;
  }
  .sm\:pb-24 {
    padding-bottom: 24px !important;
  }
  .sm\:pl-24 {
    padding-left: 24px !important;
  }
  .sm\:pr-24 {
    padding-right: 24px !important;
  }
  .sm\:mt-24 {
    margin-top: 24px !important;
  }
  .sm\:mb-24 {
    margin-bottom: 24px !important;
  }
  .sm\:ml-24 {
    margin-left: 24px !important;
  }
  .sm\:mr-24 {
    margin-right: 24px !important;
  }
  .sm\:py-28 {
    padding-top: 28px !important;
    padding-bottom: 28px !important;
  }
  .sm\:px-28 {
    padding-left: 28px !important;
    padding-right: 28px !important;
  }
  .sm\:pt-28 {
    padding-top: 28px !important;
  }
  .sm\:pb-28 {
    padding-bottom: 28px !important;
  }
  .sm\:pl-28 {
    padding-left: 28px !important;
  }
  .sm\:pr-28 {
    padding-right: 28px !important;
  }
  .sm\:mt-28 {
    margin-top: 28px !important;
  }
  .sm\:mb-28 {
    margin-bottom: 28px !important;
  }
  .sm\:ml-28 {
    margin-left: 28px !important;
  }
  .sm\:mr-28 {
    margin-right: 28px !important;
  }
  .sm\:py-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .sm\:px-30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .sm\:pt-30 {
    padding-top: 30px !important;
  }
  .sm\:pb-30 {
    padding-bottom: 30px !important;
  }
  .sm\:pl-30 {
    padding-left: 30px !important;
  }
  .sm\:pr-30 {
    padding-right: 30px !important;
  }
  .sm\:mt-30 {
    margin-top: 30px !important;
  }
  .sm\:mb-30 {
    margin-bottom: 30px !important;
  }
  .sm\:ml-30 {
    margin-left: 30px !important;
  }
  .sm\:mr-30 {
    margin-right: 30px !important;
  }
  .sm\:py-35 {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  .sm\:px-35 {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
  .sm\:pt-35 {
    padding-top: 35px !important;
  }
  .sm\:pb-35 {
    padding-bottom: 35px !important;
  }
  .sm\:pl-35 {
    padding-left: 35px !important;
  }
  .sm\:pr-35 {
    padding-right: 35px !important;
  }
  .sm\:mt-35 {
    margin-top: 35px !important;
  }
  .sm\:mb-35 {
    margin-bottom: 35px !important;
  }
  .sm\:ml-35 {
    margin-left: 35px !important;
  }
  .sm\:mr-35 {
    margin-right: 35px !important;
  }
  .sm\:py-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .sm\:px-40 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .sm\:pt-40 {
    padding-top: 40px !important;
  }
  .sm\:pb-40 {
    padding-bottom: 40px !important;
  }
  .sm\:pl-40 {
    padding-left: 40px !important;
  }
  .sm\:pr-40 {
    padding-right: 40px !important;
  }
  .sm\:mt-40 {
    margin-top: 40px !important;
  }
  .sm\:mb-40 {
    margin-bottom: 40px !important;
  }
  .sm\:ml-40 {
    margin-left: 40px !important;
  }
  .sm\:mr-40 {
    margin-right: 40px !important;
  }
  .sm\:py-45 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }
  .sm\:px-45 {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }
  .sm\:pt-45 {
    padding-top: 45px !important;
  }
  .sm\:pb-45 {
    padding-bottom: 45px !important;
  }
  .sm\:pl-45 {
    padding-left: 45px !important;
  }
  .sm\:pr-45 {
    padding-right: 45px !important;
  }
  .sm\:mt-45 {
    margin-top: 45px !important;
  }
  .sm\:mb-45 {
    margin-bottom: 45px !important;
  }
  .sm\:ml-45 {
    margin-left: 45px !important;
  }
  .sm\:mr-45 {
    margin-right: 45px !important;
  }
  .sm\:py-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  .sm\:px-50 {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
  .sm\:pt-50 {
    padding-top: 50px !important;
  }
  .sm\:pb-50 {
    padding-bottom: 50px !important;
  }
  .sm\:pl-50 {
    padding-left: 50px !important;
  }
  .sm\:pr-50 {
    padding-right: 50px !important;
  }
  .sm\:mt-50 {
    margin-top: 50px !important;
  }
  .sm\:mb-50 {
    margin-bottom: 50px !important;
  }
  .sm\:ml-50 {
    margin-left: 50px !important;
  }
  .sm\:mr-50 {
    margin-right: 50px !important;
  }
  .sm\:py-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .sm\:px-60 {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
  .sm\:pt-60 {
    padding-top: 60px !important;
  }
  .sm\:pb-60 {
    padding-bottom: 60px !important;
  }
  .sm\:pl-60 {
    padding-left: 60px !important;
  }
  .sm\:pr-60 {
    padding-right: 60px !important;
  }
  .sm\:mt-60 {
    margin-top: 60px !important;
  }
  .sm\:mb-60 {
    margin-bottom: 60px !important;
  }
  .sm\:ml-60 {
    margin-left: 60px !important;
  }
  .sm\:mr-60 {
    margin-right: 60px !important;
  }
  .sm\:py-70 {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }
  .sm\:px-70 {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }
  .sm\:pt-70 {
    padding-top: 70px !important;
  }
  .sm\:pb-70 {
    padding-bottom: 70px !important;
  }
  .sm\:pl-70 {
    padding-left: 70px !important;
  }
  .sm\:pr-70 {
    padding-right: 70px !important;
  }
  .sm\:mt-70 {
    margin-top: 70px !important;
  }
  .sm\:mb-70 {
    margin-bottom: 70px !important;
  }
  .sm\:ml-70 {
    margin-left: 70px !important;
  }
  .sm\:mr-70 {
    margin-right: 70px !important;
  }
  .sm\:py-80 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  .sm\:px-80 {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
  .sm\:pt-80 {
    padding-top: 80px !important;
  }
  .sm\:pb-80 {
    padding-bottom: 80px !important;
  }
  .sm\:pl-80 {
    padding-left: 80px !important;
  }
  .sm\:pr-80 {
    padding-right: 80px !important;
  }
  .sm\:mt-80 {
    margin-top: 80px !important;
  }
  .sm\:mb-80 {
    margin-bottom: 80px !important;
  }
  .sm\:ml-80 {
    margin-left: 80px !important;
  }
  .sm\:mr-80 {
    margin-right: 80px !important;
  }
  .sm\:py-90 {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }
  .sm\:px-90 {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }
  .sm\:pt-90 {
    padding-top: 90px !important;
  }
  .sm\:pb-90 {
    padding-bottom: 90px !important;
  }
  .sm\:pl-90 {
    padding-left: 90px !important;
  }
  .sm\:pr-90 {
    padding-right: 90px !important;
  }
  .sm\:mt-90 {
    margin-top: 90px !important;
  }
  .sm\:mb-90 {
    margin-bottom: 90px !important;
  }
  .sm\:ml-90 {
    margin-left: 90px !important;
  }
  .sm\:mr-90 {
    margin-right: 90px !important;
  }
  .sm\:py-120 {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
  }
  .sm\:px-120 {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }
  .sm\:pt-120 {
    padding-top: 120px !important;
  }
  .sm\:pb-120 {
    padding-bottom: 120px !important;
  }
  .sm\:pl-120 {
    padding-left: 120px !important;
  }
  .sm\:pr-120 {
    padding-right: 120px !important;
  }
  .sm\:mt-120 {
    margin-top: 120px !important;
  }
  .sm\:mb-120 {
    margin-bottom: 120px !important;
  }
  .sm\:ml-120 {
    margin-left: 120px !important;
  }
  .sm\:mr-120 {
    margin-right: 120px !important;
  }
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.xl\:mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.lg\:mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.md\:mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.sm\:mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.x-gap-0 {
  margin-left: -0px;
  margin-right: -0px;
}

.x-gap-0 > * {
  padding-left: 0px;
  padding-right: 0px;
}

.y-gap-0 {
  margin-top: -0px;
  margin-bottom: -0px;
}

.y-gap-0 > * {
  padding-top: 0px;
  padding-bottom: 0px;
}

.x-gap-5 {
  margin-left: -2.5px;
  margin-right: -2.5px;
}

.x-gap-5 > * {
  padding-left: 2.5px;
  padding-right: 2.5px;
}

.y-gap-5 {
  margin-top: -2.5px;
  margin-bottom: -2.5px;
}

.y-gap-5 > * {
  padding-top: 2.5px;
  padding-bottom: 2.5px;
}

.x-gap-10 {
  margin-left: -5px;
  margin-right: -5px;
}

.x-gap-10 > * {
  padding-left: 5px;
  padding-right: 5px;
}

.y-gap-10 {
  margin-top: -5px;
  margin-bottom: -5px;
}

.y-gap-10 > * {
  padding-top: 5px;
  padding-bottom: 5px;
}

.x-gap-15 {
  margin-left: -7.5px;
  margin-right: -7.5px;
}

.x-gap-15 > * {
  padding-left: 7.5px;
  padding-right: 7.5px;
}

.y-gap-15 {
  margin-top: -7.5px;
  margin-bottom: -7.5px;
}

.y-gap-15 > * {
  padding-top: 7.5px;
  padding-bottom: 7.5px;
}

.x-gap-20 {
  margin-left: -10px;
  margin-right: -10px;
}

.x-gap-20 > * {
  padding-left: 10px;
  padding-right: 10px;
}

.y-gap-20 {
  margin-top: -10px;
  margin-bottom: -10px;
}

.y-gap-20 > * {
  padding-top: 10px;
  padding-bottom: 10px;
}

.x-gap-30 {
  margin-left: -15px;
  margin-right: -15px;
}

.x-gap-30 > * {
  padding-left: 15px;
  padding-right: 15px;
}

.y-gap-30 {
  margin-top: -15px;
  margin-bottom: -15px;
}

.y-gap-30 > * {
  padding-top: 15px;
  padding-bottom: 15px;
}

.x-gap-40 {
  margin-left: -20px;
  margin-right: -20px;
}

.x-gap-40 > * {
  padding-left: 20px;
  padding-right: 20px;
}

.y-gap-40 {
  margin-top: -20px;
  margin-bottom: -20px;
}

.y-gap-40 > * {
  padding-top: 20px;
  padding-bottom: 20px;
}

.x-gap-50 {
  margin-left: -25px;
  margin-right: -25px;
}

.x-gap-50 > * {
  padding-left: 25px;
  padding-right: 25px;
}

.y-gap-50 {
  margin-top: -25px;
  margin-bottom: -25px;
}

.y-gap-50 > * {
  padding-top: 25px;
  padding-bottom: 25px;
}

.x-gap-60 {
  margin-left: -30px;
  margin-right: -30px;
}

.x-gap-60 > * {
  padding-left: 30px;
  padding-right: 30px;
}

.y-gap-60 {
  margin-top: -30px;
  margin-bottom: -30px;
}

.y-gap-60 > * {
  padding-top: 30px;
  padding-bottom: 30px;
}

.x-gap-80 {
  margin-left: -40px;
  margin-right: -40px;
}

.x-gap-80 > * {
  padding-left: 40px;
  padding-right: 40px;
}

.y-gap-80 {
  margin-top: -40px;
  margin-bottom: -40px;
}

.y-gap-80 > * {
  padding-top: 40px;
  padding-bottom: 40px;
}

@media (max-width: 767px) {
  .md\:x-gap-0 {
    margin-left: -0px;
    margin-right: -0px;
  }
  .md\:x-gap-0 > * {
    padding-left: 0px;
    padding-right: 0px;
  }
  .md\:y-gap-0 {
    margin-top: -0px;
    margin-bottom: -0px;
  }
  .md\:y-gap-0 > * {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .md\:x-gap-5 {
    margin-left: -2.5px;
    margin-right: -2.5px;
  }
  .md\:x-gap-5 > * {
    padding-left: 2.5px;
    padding-right: 2.5px;
  }
  .md\:y-gap-5 {
    margin-top: -2.5px;
    margin-bottom: -2.5px;
  }
  .md\:y-gap-5 > * {
    padding-top: 2.5px;
    padding-bottom: 2.5px;
  }
  .md\:x-gap-10 {
    margin-left: -5px;
    margin-right: -5px;
  }
  .md\:x-gap-10 > * {
    padding-left: 5px;
    padding-right: 5px;
  }
  .md\:y-gap-10 {
    margin-top: -5px;
    margin-bottom: -5px;
  }
  .md\:y-gap-10 > * {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .md\:x-gap-15 {
    margin-left: -7.5px;
    margin-right: -7.5px;
  }
  .md\:x-gap-15 > * {
    padding-left: 7.5px;
    padding-right: 7.5px;
  }
  .md\:y-gap-15 {
    margin-top: -7.5px;
    margin-bottom: -7.5px;
  }
  .md\:y-gap-15 > * {
    padding-top: 7.5px;
    padding-bottom: 7.5px;
  }
  .md\:x-gap-20 {
    margin-left: -10px;
    margin-right: -10px;
  }
  .md\:x-gap-20 > * {
    padding-left: 10px;
    padding-right: 10px;
  }
  .md\:y-gap-20 {
    margin-top: -10px;
    margin-bottom: -10px;
  }
  .md\:y-gap-20 > * {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .md\:x-gap-30 {
    margin-left: -15px;
    margin-right: -15px;
  }
  .md\:x-gap-30 > * {
    padding-left: 15px;
    padding-right: 15px;
  }
  .md\:y-gap-30 {
    margin-top: -15px;
    margin-bottom: -15px;
  }
  .md\:y-gap-30 > * {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .md\:x-gap-40 {
    margin-left: -20px;
    margin-right: -20px;
  }
  .md\:x-gap-40 > * {
    padding-left: 20px;
    padding-right: 20px;
  }
  .md\:y-gap-40 {
    margin-top: -20px;
    margin-bottom: -20px;
  }
  .md\:y-gap-40 > * {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .md\:x-gap-50 {
    margin-left: -25px;
    margin-right: -25px;
  }
  .md\:x-gap-50 > * {
    padding-left: 25px;
    padding-right: 25px;
  }
  .md\:y-gap-50 {
    margin-top: -25px;
    margin-bottom: -25px;
  }
  .md\:y-gap-50 > * {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .md\:x-gap-60 {
    margin-left: -30px;
    margin-right: -30px;
  }
  .md\:x-gap-60 > * {
    padding-left: 30px;
    padding-right: 30px;
  }
  .md\:y-gap-60 {
    margin-top: -30px;
    margin-bottom: -30px;
  }
  .md\:y-gap-60 > * {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .md\:x-gap-80 {
    margin-left: -40px;
    margin-right: -40px;
  }
  .md\:x-gap-80 > * {
    padding-left: 40px;
    padding-right: 40px;
  }
  .md\:y-gap-80 {
    margin-top: -40px;
    margin-bottom: -40px;
  }
  .md\:y-gap-80 > * {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

@media (max-width: 767px) {
  .sm\:x-gap-0 {
    margin-left: -0px;
    margin-right: -0px;
  }
  .sm\:x-gap-0 > * {
    padding-left: 0px;
    padding-right: 0px;
  }
  .sm\:y-gap-0 {
    margin-top: -0px;
    margin-bottom: -0px;
  }
  .sm\:y-gap-0 > * {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .sm\:x-gap-5 {
    margin-left: -2.5px;
    margin-right: -2.5px;
  }
  .sm\:x-gap-5 > * {
    padding-left: 2.5px;
    padding-right: 2.5px;
  }
  .sm\:y-gap-5 {
    margin-top: -2.5px;
    margin-bottom: -2.5px;
  }
  .sm\:y-gap-5 > * {
    padding-top: 2.5px;
    padding-bottom: 2.5px;
  }
  .sm\:x-gap-10 {
    margin-left: -5px;
    margin-right: -5px;
  }
  .sm\:x-gap-10 > * {
    padding-left: 5px;
    padding-right: 5px;
  }
  .sm\:y-gap-10 {
    margin-top: -5px;
    margin-bottom: -5px;
  }
  .sm\:y-gap-10 > * {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .sm\:x-gap-15 {
    margin-left: -7.5px;
    margin-right: -7.5px;
  }
  .sm\:x-gap-15 > * {
    padding-left: 7.5px;
    padding-right: 7.5px;
  }
  .sm\:y-gap-15 {
    margin-top: -7.5px;
    margin-bottom: -7.5px;
  }
  .sm\:y-gap-15 > * {
    padding-top: 7.5px;
    padding-bottom: 7.5px;
  }
  .sm\:x-gap-20 {
    margin-left: -10px;
    margin-right: -10px;
  }
  .sm\:x-gap-20 > * {
    padding-left: 10px;
    padding-right: 10px;
  }
  .sm\:y-gap-20 {
    margin-top: -10px;
    margin-bottom: -10px;
  }
  .sm\:y-gap-20 > * {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .sm\:x-gap-30 {
    margin-left: -15px;
    margin-right: -15px;
  }
  .sm\:x-gap-30 > * {
    padding-left: 15px;
    padding-right: 15px;
  }
  .sm\:y-gap-30 {
    margin-top: -15px;
    margin-bottom: -15px;
  }
  .sm\:y-gap-30 > * {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .sm\:x-gap-40 {
    margin-left: -20px;
    margin-right: -20px;
  }
  .sm\:x-gap-40 > * {
    padding-left: 20px;
    padding-right: 20px;
  }
  .sm\:y-gap-40 {
    margin-top: -20px;
    margin-bottom: -20px;
  }
  .sm\:y-gap-40 > * {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .sm\:x-gap-50 {
    margin-left: -25px;
    margin-right: -25px;
  }
  .sm\:x-gap-50 > * {
    padding-left: 25px;
    padding-right: 25px;
  }
  .sm\:y-gap-50 {
    margin-top: -25px;
    margin-bottom: -25px;
  }
  .sm\:y-gap-50 > * {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .sm\:x-gap-60 {
    margin-left: -30px;
    margin-right: -30px;
  }
  .sm\:x-gap-60 > * {
    padding-left: 30px;
    padding-right: 30px;
  }
  .sm\:y-gap-60 {
    margin-top: -30px;
    margin-bottom: -30px;
  }
  .sm\:y-gap-60 > * {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .sm\:x-gap-80 {
    margin-left: -40px;
    margin-right: -40px;
  }
  .sm\:x-gap-80 > * {
    padding-left: 40px;
    padding-right: 40px;
  }
  .sm\:y-gap-80 {
    margin-top: -40px;
    margin-bottom: -40px;
  }
  .sm\:y-gap-80 > * {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.layout-pt-sm {
  padding-top: 30px;
}

.layout-pb-sm {
  padding-bottom: 30px;
}

.layout-pt-md {
  padding-top: 60px;
}

.layout-pb-md {
  padding-bottom: 60px;
}

.layout-pt-lg {
  padding-top: 120px;
}

.layout-pb-lg {
  padding-bottom: 120px;
}

.layout-pt-xl {
  padding-top: 140px;
}

.layout-pb-xl {
  padding-bottom: 140px;
}

@media (max-width: 767px) {
  .layout-pt-sm {
    padding-top: 30px;
  }
  .layout-pb-sm {
    padding-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .layout-pt-md {
    padding-top: 60px;
  }
  .layout-pb-md {
    padding-bottom: 60px;
  }
}

@media (max-width: 767px) {
  .layout-pt-lg {
    padding-top: 100px;
  }
  .layout-pb-lg {
    padding-bottom: 100px;
  }
}

@media (max-width: 767px) {
  .layout-pt-xl {
    padding-top: 120px;
  }
  .layout-pb-xl {
    padding-bottom: 120px;
  }
}

@media (max-width: 575px) {
  .layout-pt-sm {
    padding-top: 30px;
  }
  .layout-pb-sm {
    padding-bottom: 30px;
  }
}

@media (max-width: 575px) {
  .layout-pt-md {
    padding-top: 30px;
  }
  .layout-pb-md {
    padding-bottom: 30px;
  }
}

@media (max-width: 575px) {
  .layout-pt-lg {
    padding-top: 60px;
  }
  .layout-pb-lg {
    padding-bottom: 60px;
  }
}

@media (max-width: 575px) {
  .layout-pt-xl {
    padding-top: 60px;
  }
  .layout-pb-xl {
    padding-bottom: 60px;
  }
}

.w-1\/7 {
  width: 14.285714% !important;
}

.w-1\/5 {
  width: 20% !important;
}

.w-1\/4 {
  width: 25% !important;
}

.w-1\/3 {
  width: 33.333333% !important;
}

.w-1\/2 {
  width: 50% !important;
}

.w-1\/1 {
  width: 100% !important;
}

@media (max-width: 1199px) {
  .xl\:w-1\/5 {
    width: 20% !important;
  }
  .xl\:w-1\/4 {
    width: 25% !important;
  }
  .xl\:w-1\/3 {
    width: 33.333333% !important;
  }
  .xl\:w-1\/2 {
    width: 50% !important;
  }
  .xl\:w-1\/1 {
    width: 100% !important;
  }
}

@media (max-width: 991px) {
  .lg\:w-1\/5 {
    width: 20% !important;
  }
  .lg\:w-1\/4 {
    width: 25% !important;
  }
  .lg\:w-1\/3 {
    width: 33.333333% !important;
  }
  .lg\:w-1\/2 {
    width: 50% !important;
  }
  .lg\:w-1\/1 {
    width: 100% !important;
  }
}

@media (max-width: 767px) {
  .md\:w-1\/5 {
    width: 20% !important;
  }
  .md\:w-1\/4 {
    width: 25% !important;
  }
  .md\:w-1\/3 {
    width: 33.333333% !important;
  }
  .md\:w-1\/2 {
    width: 50% !important;
  }
  .md\:w-1\/1 {
    width: 100% !important;
  }
}

@media (max-width: 575px) {
  .sm\:w-1\/5 {
    width: 20% !important;
  }
  .sm\:w-1\/4 {
    width: 25% !important;
  }
  .sm\:w-1\/3 {
    width: 33.333333% !important;
  }
  .sm\:w-1\/2 {
    width: 50% !important;
  }
  .sm\:w-1\/1 {
    width: 100% !important;
  }
}

.flex-column {
  flex-direction: column;
}

@media (max-width: 1199px) {
  .xl\:flex-column {
    flex-direction: column !important;
  }
}

@media (max-width: 991px) {
  .lg\:flex-column {
    flex-direction: column !important;
  }
}

@media (max-width: 767px) {
  .md\:flex-column {
    flex-direction: column !important;
  }
}

@media (max-width: 575px) {
  .sm\:flex-column {
    flex-direction: column !important;
  }
}

.justify-start {
  justify-content: flex-start !important;
}

.justify-end {
  justify-content: flex-end !important;
}

.justify-center {
  justify-content: center !important;
}

.justify-between {
  justify-content: space-between !important;
}

.items-start {
  align-items: flex-start !important;
}

.items-end {
  align-items: flex-end !important;
}

.items-center {
  align-items: center !important;
}

@media (max-width: 1199px) {
  .xl\:justify-start {
    justify-content: flex-start !important;
  }
  .xl\:justify-end {
    justify-content: flex-end !important;
  }
  .xl\:justify-center {
    justify-content: center !important;
  }
  .xl\:justify-between {
    justify-content: space-between !important;
  }
  .xl\:items-start {
    align-items: flex-start !important;
  }
  .xl\:items-end {
    align-items: flex-end !important;
  }
  .xl\:items-center {
    align-items: center !important;
  }
}

@media (max-width: 991px) {
  .lg\:justify-start {
    justify-content: flex-start !important;
  }
  .lg\:justify-end {
    justify-content: flex-end !important;
  }
  .lg\:justify-center {
    justify-content: center !important;
  }
  .lg\:justify-between {
    justify-content: space-between !important;
  }
  .lg\:items-start {
    align-items: flex-start !important;
  }
  .lg\:items-end {
    align-items: flex-end !important;
  }
  .lg\:items-center {
    align-items: center !important;
  }
}

@media (max-width: 767px) {
  .md\:justify-start {
    justify-content: flex-start !important;
  }
  .md\:justify-end {
    justify-content: flex-end !important;
  }
  .md\:justify-center {
    justify-content: center !important;
  }
  .md\:justify-between {
    justify-content: space-between !important;
  }
  .md\:items-start {
    align-items: flex-start !important;
  }
  .md\:items-end {
    align-items: flex-end !important;
  }
  .md\:items-center {
    align-items: center !important;
  }
}

@media (max-width: 575px) {
  .sm\:justify-start {
    justify-content: flex-start !important;
  }
  .sm\:justify-end {
    justify-content: flex-end !important;
  }
  .sm\:justify-center {
    justify-content: center !important;
  }
  .sm\:justify-between {
    justify-content: space-between !important;
  }
  .sm\:items-start {
    align-items: flex-start !important;
  }
  .sm\:items-end {
    align-items: flex-end !important;
  }
  .sm\:items-center {
    align-items: center !important;
  }
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

@media (max-width: 1199px) {
  .xl\:order-1 {
    order: 1;
  }
  .xl\:order-2 {
    order: 2;
  }
  .xl\:order-3 {
    order: 3;
  }
}

@media (max-width: 991px) {
  .lg\:order-1 {
    order: 1;
  }
  .lg\:order-2 {
    order: 2;
  }
  .lg\:order-3 {
    order: 3;
  }
}

@media (max-width: 767px) {
  .md\:order-1 {
    order: 1;
  }
  .md\:order-2 {
    order: 2;
  }
  .md\:order-3 {
    order: 3;
  }
}

@media (max-width: 575px) {
  .sm\:order-1 {
    order: 1;
  }
  .sm\:order-2 {
    order: 2;
  }
  .sm\:order-3 {
    order: 3;
  }
}

.bg-primary {
  background-color: var(--color-primary) !important;
}

.bg-secondary {
  background-color: var(--color-secondary) !important;
}

.bg-terracotta {
  background-color: var(--color-terracotta) !important;
}

.bg-ocre {
  background-color: var(--color-ocre) !important;
}

.bg-ocre-light {
  background-color: var(--color-ocre-light) !important;
}

.bg-ocre-dark {
  background-color: var(--color-ocre-dark) !important;
}

.bg-white {
  background-color: var(--color-white) !important;
}

.bg-black {
  background-color: var(--color-black) !important;
}

.bg-border {
  background-color: var(--color-border) !important;
}

.bg-bg {
  background-color: var(--color-bg) !important;
}

.bg-bg-light {
  background-color: var(--color-bg-light) !important;
}

.bg-bg-dark {
  background-color: var(--color-bg-dark) !important;
}

.bg-titre-light {
  background-color: var(--color-titre-light) !important;
}

.bg-bloc {
  background-color: var(--color-bloc) !important;
}

.bg-sub {
  background-color: var(--color-sub) !important;
}

.bg-text {
  background-color: var(--color-text) !important;
}

.bg-primary-1 {
  background-color: var(--color-primary-1) !important;
}

.bg-primary-2 {
  background-color: var(--color-primary-2) !important;
}

.bg-primary-3 {
  background-color: var(--color-primary-3) !important;
}

.bg-primary-4 {
  background-color: var(--color-primary-4) !important;
}

.bg-primary-l {
  background-color: var(--color-primary-l) !important;
}

.bg-primary-d {
  background-color: var(--color-primary-d) !important;
}

.bg-dark-1 {
  background-color: var(--color-dark-1) !important;
}

.bg-dark-2 {
  background-color: var(--color-dark-2) !important;
}

.bg-dark-3 {
  background-color: var(--color-dark-3) !important;
}

.bg-dark-4 {
  background-color: var(--color-dark-4) !important;
}

.bg-light-1 {
  background-color: var(--color-light-1) !important;
}

.bg-light-2 {
  background-color: var(--color-light-2) !important;
}

.bg-light-3 {
  background-color: var(--color-light-3) !important;
}

.bg-blue-1 {
  background-color: var(--color-blue-1) !important;
}

.bg-blue-2 {
  background-color: var(--color-blue-2) !important;
}

.bg-green-1 {
  background-color: var(--color-green-1) !important;
}

.bg-green-2 {
  background-color: var(--color-green-2) !important;
}

.bg-yellow-1 {
  background-color: var(--color-yellow-1) !important;
}

.bg-yellow-2 {
  background-color: var(--color-yellow-2) !important;
}

.bg-yellow-3 {
  background-color: var(--color-yellow-3) !important;
}

.bg-yellow-4 {
  background-color: var(--color-yellow-4) !important;
}

.bg-brown-1 {
  background-color: var(--color-brown-1) !important;
}

.bg-purple-1 {
  background-color: var(--color-purple-1) !important;
}

.bg-red-1 {
  background-color: var(--color-red-1) !important;
}

.bg-red-2 {
  background-color: var(--color-red-2) !important;
}

.bg-red-3 {
  background-color: var(--color-red-3) !important;
}

.bg-info-1 {
  background-color: var(--color-info-1) !important;
}

.bg-info-2 {
  background-color: var(--color-info-2) !important;
}

.bg-warning-1 {
  background-color: var(--color-warning-1) !important;
}

.bg-warning-2 {
  background-color: var(--color-warning-2) !important;
}

.bg-danger {
  background-color: var(--color-danger) !important;
}

.bg-error-1 {
  background-color: var(--color-error-1) !important;
}

.bg-error-2 {
  background-color: var(--color-error-2) !important;
}

.bg-success {
  background-color: var(--color-success) !important;
}

.bg-success-1 {
  background-color: var(--color-success-1) !important;
}

.bg-success-2 {
  background-color: var(--color-success-2) !important;
}

.bg-blue-1-05 {
  background-color: rgba(53, 84, 209, 0.05);
}

.bg-white-10 {
  background-color: rgba(255, 255, 255, 0.1);
}

.bg-white-20 {
  background-color: rgba(255, 255, 255, 0.2);
}

.bg-white-50 {
  background-color: rgba(255, 255, 255, 0.5);
}

.bg-black-20 {
  background-color: rgba(0, 0, 0, 0.2);
}

.text-primary {
  color: var(--color-primary);
}

.text-secondary {
  color: var(--color-secondary);
}

.text-terracotta {
  color: var(--color-terracotta);
}

.text-ocre {
  color: var(--color-ocre);
}

.text-ocre-light {
  color: var(--color-ocre-light);
}

.text-ocre-dark {
  color: var(--color-ocre-dark);
}

.text-white {
  color: var(--color-white);
}

.text-black {
  color: var(--color-black);
}

.text-border {
  color: var(--color-border);
}

.text-bg {
  color: var(--color-bg);
}

.text-bg-light {
  color: var(--color-bg-light);
}

.text-bg-dark {
  color: var(--color-bg-dark);
}

.text-titre-light {
  color: var(--color-titre-light);
}

.text-bloc {
  color: var(--color-bloc);
}

.text-sub {
  color: var(--color-sub);
}

.text-text {
  color: var(--color-text);
}

.text-primary-1 {
  color: var(--color-primary-1);
}

.text-primary-2 {
  color: var(--color-primary-2);
}

.text-primary-3 {
  color: var(--color-primary-3);
}

.text-primary-4 {
  color: var(--color-primary-4);
}

.text-primary-l {
  color: var(--color-primary-l);
}

.text-primary-d {
  color: var(--color-primary-d);
}

.text-dark-1 {
  color: var(--color-dark-1);
}

.text-dark-2 {
  color: var(--color-dark-2);
}

.text-dark-3 {
  color: var(--color-dark-3);
}

.text-dark-4 {
  color: var(--color-dark-4);
}

.text-light-1 {
  color: var(--color-light-1);
}

.text-light-2 {
  color: var(--color-light-2);
}

.text-light-3 {
  color: var(--color-light-3);
}

.text-blue-1 {
  color: var(--color-blue-1);
}

.text-blue-2 {
  color: var(--color-blue-2);
}

.text-green-1 {
  color: var(--color-green-1);
}

.text-green-2 {
  color: var(--color-green-2);
}

.text-yellow-1 {
  color: var(--color-yellow-1);
}

.text-yellow-2 {
  color: var(--color-yellow-2);
}

.text-yellow-3 {
  color: var(--color-yellow-3);
}

.text-yellow-4 {
  color: var(--color-yellow-4);
}

.text-brown-1 {
  color: var(--color-brown-1);
}

.text-purple-1 {
  color: var(--color-purple-1);
}

.text-red-1 {
  color: var(--color-red-1);
}

.text-red-2 {
  color: var(--color-red-2);
}

.text-red-3 {
  color: var(--color-red-3);
}

.text-info-1 {
  color: var(--color-info-1);
}

.text-info-2 {
  color: var(--color-info-2);
}

.text-warning-1 {
  color: var(--color-warning-1);
}

.text-warning-2 {
  color: var(--color-warning-2);
}

.text-danger {
  color: var(--color-danger);
}

.text-error-1 {
  color: var(--color-error-1);
}

.text-error-2 {
  color: var(--color-error-2);
}

.text-success {
  color: var(--color-success);
}

.text-success-1 {
  color: var(--color-success-1);
}

.text-success-2 {
  color: var(--color-success-2);
}

.text-inherit {
  color: inherit;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5);
}

.d-none {
  display: none !important;
}

.d-flex {
  display: flex !important;
}

.d-block {
  display: block !important;
}

.d-inline-block {
  display: inline-block !important;
}

@media (max-width: 1199px) {
  .xl\:d-none {
    display: none !important;
  }
  .xl\:d-inline-block {
    display: inline-block !important;
  }
  .xl\:d-block {
    display: block !important;
  }
  .xl\:d-flex {
    display: flex !important;
  }
}

@media (max-width: 991px) {
  .lg\:d-none {
    display: none !important;
  }
  .lg\:d-inline-block {
    display: inline-block !important;
  }
  .lg\:d-block {
    display: block !important;
  }
  .lg\:d-flex {
    display: flex !important;
  }
}

@media (max-width: 767px) {
  .md\:d-none {
    display: none !important;
  }
  .md\:d-inline-block {
    display: inline-block !important;
  }
  .md\:d-block {
    display: block !important;
  }
  .md\:d-flex {
    display: flex !important;
  }
}

@media (max-width: 575px) {
  .sm\:d-none {
    display: none !important;
  }
  .sm\:d-inline-block {
    display: inline-block !important;
  }
  .sm\:d-block {
    display: block !important;
  }
  .sm\:d-flex {
    display: flex !important;
  }
}

@media (max-width: 1300px) {
  .xxl\:d-none {
    display: none !important;
  }
}

.html-overflow-hidden {
  overflow: hidden !important;
}

.html-overflow-hidden body {
  overflow-y: scroll;
}

.origin-top {
  transform-origin: top !important;
}

.origin-left {
  transform-origin: left !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-normal {
  overflow: initial !important;
}

.overflow-visible {
  overflow: visible !important;
}

.pointer-events-auto {
  pointer-events: auto !important;
}

.pointer-events-none {
  pointer-events: none !important;
}

.bg-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  height: 100%;
}

.img-ratio {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.line-through {
  text-decoration: line-through;
}

.underline {
  text-decoration: underline;
}

.italic {
  font-style: italic;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.normal {
  text-transform: none;
}

.rounded-0 {
  border-radius: 0;
}

.rounded-4 {
  border-radius: 4px;
}

.rounded-8 {
  border-radius: 8px;
}

.rounded-16 {
  border-radius: 16px;
}

.rounded-22 {
  border-radius: 22px;
}

.rounded-100 {
  border-radius: 100px;
}

.rounded-full {
  border-radius: 100%;
}

.rounded-right-4 {
  border-radius: 0 4px 4px 0;
}

@media (max-width: 991px) {
  .lg\:border-none {
    border: none !important;
  }
}

.border-type-1 {
  border: 1px dashed var(--color-blue-1);
}

.border-top-light {
  border-top: 1px solid var(--color-border);
}

.border-left-light {
  border-left: 1px solid var(--color-border);
}

.border-bottom-light {
  border-bottom: 1px solid var(--color-border);
}

.border-white {
  border: 1px solid white;
}

.border-dark-1 {
  border: 1px solid var(--color-dark-1);
}

.border-dark-4 {
  border: 1px solid var(--color-dark-4);
}

.border-blue-1 {
  border: 1px solid var(--color-blue-1);
}

.border-white-15 {
  border: 1px solid rgba(255, 255, 255, 0.15);
}

.border-top-white-15 {
  border-top: 1px solid rgba(255, 255, 255, 0.15);
}

.border-light {
  border: 1px solid var(--color-border) !important;
}

.size-3 {
  flex-shrink: 0;
  width: 3px;
  height: 3px;
}

.size-4 {
  flex-shrink: 0;
  width: 4px;
  height: 4px;
}

.size-10 {
  flex-shrink: 0;
  width: 10px;
  height: 10px;
}

.size-30 {
  flex-shrink: 0;
  width: 30px;
  height: 30px;
}

.size-38 {
  flex-shrink: 0;
  width: 38px;
  height: 38px;
}

.size-35 {
  flex-shrink: 0;
  width: 35px;
  height: 35px;
}

.size-40 {
  flex-shrink: 0;
  width: 40px;
  height: 40px;
}

.size-180 {
  flex-shrink: 0;
  width: 180px;
  height: 180px;
}

.size-140 {
  flex-shrink: 0;
  width: 140px;
  height: 140px;
}

.size-80 {
  flex-shrink: 0;
  width: 80px;
  height: 80px;
}

.size-50 {
  flex-shrink: 0;
  width: 50px;
  height: 50px;
}

.size-60 {
  flex-shrink: 0;
  width: 60px;
  height: 60px;
}

.size-65 {
  flex-shrink: 0;
  width: 65px;
  height: 65px;
}

.size-20 {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
}

.size-70 {
  flex-shrink: 0;
  width: 70px;
  height: 70px;
}

.size-100 {
  flex-shrink: 0;
  width: 100px;
  height: 100px;
}

.size-120 {
  flex-shrink: 0;
  width: 120px;
  height: 120px;
}

.size-250 {
  flex-shrink: 0;
  width: 250px;
  height: 250px;
}

.size-160 {
  flex-shrink: 0;
  width: 160px;
  height: 160px;
}

.size-130 {
  flex-shrink: 0;
  width: 130px;
  height: 130px;
}

.shadow-1 {
  box-shadow: 0px 20px 40px 0px #05103612;
}

.shadow-2 {
  box-shadow: 0px 10px 35px 0px #0510361A;
}

.shadow-3 {
  box-shadow: 0px 10px 30px 0px #05103608;
}

.shadow-4 {
  box-shadow: 0px 10px 60px 0px #0510360D;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-wrap {
  flex-wrap: wrap;
}

.h-full {
  height: 100% !important;
}

.h-34 {
  height: 34px !important;
}

.h-60 {
  height: 60px !important;
}

.h-1 {
  height: 1px !important;
}

.h-68 {
  height: 68px !important;
}

.h-50 {
  height: 50px !important;
}

.h-20 {
  height: 20px !important;
}

.h-40 {
  height: 40px !important;
}

.w-48 {
  width: 48px;
}

.w-28 {
  width: 28px;
}

.w-max-300 {
  max-width: 300px;
}

.slider-scrollbar {
  width: 100%;
  border-radius: 4px;
  height: 3px;
}

.slider-scrollbar .swiper-scrollbar-drag {
  cursor: grab;
  background-color: var(--color-dark-1);
}

.slider-scrollbar .swiper-scrollbar-drag:active:hover {
  cursor: grabbing;
}

.slider-scrollbar.-light .swiper-scrollbar-drag {
  background-color: var(--color-white);
}

.pointer {
  cursor: pointer;
}

.section-bg {
  position: relative;
  z-index: 0;
}

.section-bg__item {
  position: absolute;
  top: 0;
  height: 100%;
  z-index: -1;
}

.section-bg__item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.section-bg__item.-w-1500 {
  width: 1500px;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 1199px) {
  .section-bg__item.-w-1500 {
    width: 100%;
  }
}

.section-bg__item.-w-1165 {
  width: 64vw;
}

@media (max-width: 1199px) {
  .section-bg__item.-w-1165 {
    width: 100%;
  }
}

.section-bg__item.-video-left {
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 40vw;
  height: unset;
}

@media (max-width: 1199px) {
  .section-bg__item.-video-left {
    top: unset;
    width: 100%;
    transform: none;
    position: relative;
    margin: 0 auto;
    padding: 0 15px;
    max-width: 960px;
  }
}

@media (max-width: 991px) {
  .section-bg__item.-video-left {
    max-width: 720px;
  }
}

@media (max-width: 767px) {
  .section-bg__item.-video-left {
    max-width: 540px;
  }
}

.section-bg__item.-left {
  left: 0;
}

.section-bg__item.-right {
  right: 0;
}

.section-bg__item.-left-100 {
  left: 100px;
}

@media (max-width: 991px) {
  .section-bg__item.-left-100 {
    left: 0;
  }
}

.section-bg__item.-right-100 {
  right: 100px;
}

@media (max-width: 991px) {
  .section-bg__item.-right-100 {
    right: 0;
  }
}

.section-bg__item.-mx-20 {
  left: 20px;
  right: 20px;
}

@media (max-width: 767px) {
  .section-bg__item.-mx-20 {
    left: 0;
    right: 0;
  }
}

@media (max-width: 767px) {
  .section-bg__item.-image {
    position: relative;
    width: 100%;
  }
}

.object-cover {
  object-fit: cover;
}

.currencyMenu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-top: 120px;
  z-index: 1100;
  pointer-events: auto;
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.currencyMenu.is-hidden {
  pointer-events: none;
  opacity: 0;
}

.currencyMenu__bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(5, 16, 54, 0.4);
}

.currencyMenu__content {
  position: absolute;
  left: 50%;
  z-index: 1;
  width: 1070px;
  height: auto;
  transform: translateX(-50%);
}

.modalGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 30px;
}

@media (max-width: 991px) {
  .modalGrid {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media (max-width: 575px) {
  .modalGrid {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
  }
}

.modalGrid__item {
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.modalGrid__item > * > *:nth-child(1) {
  transition: all 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.modalGrid__item:hover {
  background-color: rgba(53, 84, 209, 0.05) !important;
}

.modalGrid__item:hover > * > *:nth-child(1) {
  color: var(--color-blue-1);
}

.modalGrid__item.-is-active {
  background-color: #F5F5F5;
}

.langMenu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1100;
  width: 100%;
  height: 100%;
  padding-top: 120px;
  pointer-events: auto;
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.langMenu.is-hidden {
  pointer-events: none;
  opacity: 0;
}

.langMenu__bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(5, 16, 54, 0.4);
}

.langMenu__content {
  position: absolute;
  left: 50%;
  z-index: 1;
  width: 1070px;
  max-width: 100%;
  height: auto;
  transform: translateX(-50%);
}

.relative {
  position: relative;
}

.ph-white::placeholder {
  color: var(--color-white);
}

.absolute {
  position: absolute;
}

.bottom-0 {
  bottom: 0;
}

.flightLine {
  position: relative;
  width: 100%;
  height: 1px;
  background-color: var(--color-border);
}

.flightLine > * {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 9px;
  height: 9px;
  border: 1px solid var(--color-border);
  border-radius: 100%;
}

.flightLine > *:nth-child(1) {
  left: 0;
  background-color: var(--color-white);
}

.flightLine > *:nth-child(2) {
  right: 0;
  background-color: var(--color-border);
}

.blog-grid-1 {
  display: grid;
  grid-template-columns: 0.66fr 0.33fr;
  grid-template-rows: 250px 250px;
  gap: 30px;
}

@media (max-width: 991px) {
  .blog-grid-1 {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }
}

.blog-grid-1 > *:nth-child(1) {
  grid-row: span 2;
}

@media (max-width: 991px) {
  .blog-grid-1 > *:nth-child(1) {
    grid-row: unset;
  }
}

.border-center > *:nth-child(2n + 2) {
  border-left: 1px solid var(--color-light-2);
}

.border-center > *:nth-child(1n + 3) {
  border-top: 1px solid var(--color-light-2);
}

@media (max-width: 991px) {
  .border-center > *:nth-child(1n + 2) {
    border-top: 1px solid var(--color-light-2);
  }
  .border-center > * {
    border-left: 0;
  }
}

.header-banner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.section-slider-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 5;
}

.section-slider-nav.-prev {
  left: -20px;
}

.section-slider-nav.-next {
  right: -20px;
}

@media (max-width: 767px) {
  .md\:size-full {
    width: 100%;
    height: auto;
  }
}

.sidebar__item:not(.-no-border) {
  border-top: 1px solid var(--color-border);
}

.sidebar.-mobile-filter {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 40px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 10px;
  padding-top: 70px;
}

.sidebar.-mobile-filter .-icon-close {
  position: absolute;
  top: 20px;
  left: 24px;
}

@media (max-width: 767px) {
  .sidebar.-mobile-filter {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 575px) {
  .sidebar.-mobile-filter {
    grid-template-columns: 1fr;
  }
}

.sidebar.-mobile-filter > * {
  border-top: none !important;
}

.w-250 {
  width: 250px;
  max-width: 100%;
}

.w-1 {
  width: 1px;
  max-width: 100%;
}

.w-200 {
  width: 200px;
  max-width: 100%;
}

.w-180 {
  width: 180px;
  max-width: 100%;
}

.halfMap {
  display: flex;
  width: 100%;
  min-height: calc(100vh - 90px);
  margin-top: 90px;
}

@media (max-width: 991px) {
  .halfMap {
    flex-direction: column;
  }
}

@media (max-width: 767px) {
  .halfMap {
    margin-top: 80px;
  }
}

.halfMap__content {
  width: 865px;
  max-width: 100%;
  flex-shrink: 0;
  padding: 30px;
  min-height: 100%;
}

@media (max-width: 1199px) {
  .halfMap__content {
    width: 660px;
  }
}

@media (max-width: 991px) {
  .halfMap__content {
    width: 100%;
    order: 2;
  }
}

@media (max-width: 767px) {
  .halfMap__content {
    padding: 20px;
  }
}

.halfMap__map {
  width: 100%;
  min-height: 100%;
}

@media (max-width: 991px) {
  .halfMap__map {
    order: 1;
  }
}

.halfMap__map img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.galleryGrid.-type-1 {
  display: grid;
  grid-template-columns: 0.52fr 0.24fr 0.24fr;
  grid-template-rows: 1fr 1fr;
  gap: 10px;
}

@media (max-width: 991px) {
  .galleryGrid.-type-1 {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width: 767px) {
  .galleryGrid.-type-1 {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 575px) {
  .galleryGrid.-type-1 {
    grid-template-columns: 1fr;
  }
}

.galleryGrid.-type-1 > *:nth-child(1) {
  grid-row: 2 span;
}

@media (max-width: 991px) {
  .galleryGrid.-type-1 > *:nth-child(1) {
    grid-row: 1;
  }
}

.galleryGrid.-type-1 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 991px) {
  .galleryGrid.-type-1 img {
    width: 100%;
  }
}

.galleryGrid.-type-2 {
  display: grid;
  grid-template-columns: 0.6fr 0.2fr 0.2fr;
  grid-template-rows: 0.4fr 0.6fr;
  gap: 10px;
}

@media (max-width: 991px) {
  .galleryGrid.-type-2 {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width: 767px) {
  .galleryGrid.-type-2 {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 575px) {
  .galleryGrid.-type-2 {
    grid-template-columns: 1fr;
  }
}

.galleryGrid.-type-2 > *:nth-child(1) {
  grid-row: 2 span;
}

.galleryGrid.-type-2 > *:nth-child(4) {
  grid-column: 2 span;
}

.galleryGrid.-type-2 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 991px) {
  .galleryGrid.-type-2 img {
    width: 100%;
  }
}

.min-h-250 {
  min-height: 250px;
}

.roomGrid {
  width: 100%;
}

.roomGrid__header {
  display: grid;
  grid-template-columns: 180px 1fr 60px 170px 140px 270px;
  gap: 30px;
  background-color: var(--color-dark-3);
  color: white;
  font-weight: 500;
  padding: 15px 0;
}

@media (max-width: 1400px) {
  .roomGrid__header {
    grid-template-columns: 140px 1fr 60px 170px 140px 180px;
  }
}

@media (max-width: 1199px) {
  .roomGrid__header {
    display: none;
  }
}

.roomGrid__header > *:first-child {
  padding-left: 30px;
}

.roomGrid__grid {
  display: grid;
  gap: 30px;
  grid-template-columns: 180px auto 270px;
}

@media (max-width: 1400px) {
  .roomGrid__grid {
    grid-template-columns: 140px auto 180px;
  }
}

@media (max-width: 1199px) {
  .roomGrid__grid {
    grid-template-columns: 140px auto;
  }
  .roomGrid__grid > *:nth-child(3) {
    grid-column: 2 span;
  }
}

@media (max-width: 991px) {
  .roomGrid__grid {
    grid-template-columns: 1fr;
  }
  .roomGrid__grid > *:nth-child(1) {
    display: flex;
    align-items: center;
  }
  .roomGrid__grid > *:nth-child(1) > *:first-child {
    min-width: 200px;
    margin-right: 20px;
  }
  .roomGrid__grid > *:nth-child(2) {
    grid-column: 2 span;
  }
}

@media (max-width: 767px) {
  .roomGrid__grid > *:nth-child(1) {
    display: block;
    align-items: unset;
  }
  .roomGrid__grid > *:nth-child(1) > *:first-child {
    min-width: auto;
    width: 100%;
    margin-right: 0;
  }
}

.roomGrid__grid > * {
  padding-top: 20px;
}

.roomGrid__grid > *:nth-child(3) {
  background-color: rgba(53, 84, 209, 0.05);
  padding: 20px 30px;
}

.roomGrid__content {
  display: grid;
  grid-template-columns: 1fr 60px 170px 140px;
  gap: 30px;
}

@media (max-width: 767px) {
  .roomGrid__content {
    grid-template-columns: 1fr;
    gap: 20px;
  }
}

.roomGrid__content:nth-child(1n + 2) {
  border-top: 1px solid var(--color-border);
}

.progressBar {
  position: relative;
}

.progressBar__bg {
  width: 100%;
  height: 4px;
  border-radius: 4px;
}

.progressBar__bar {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  height: 100%;
  border-radius: 4px;
}

.progressBar__bar span {
  position: absolute;
  right: 0;
  bottom: 100%;
}

.tooltip {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #615E5E;
}

.tooltip__content {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 11px;
  font-size: 14px;
  line-height: 1.2;
  background-color: var(--color-dark-1);
  color: white;
  border-radius: 8px;
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  opacity: 0;
}

.tooltip__content::after {
  content: '';
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: black;
  transform: rotate(45deg);
}

.tooltip.-top .tooltip__content {
  bottom: calc(100% + 6px);
  transform: translateY(8px);
}

.tooltip.-top .tooltip__content::after {
  bottom: -4px;
}

.tooltip.-bottom .tooltip__content {
  top: calc(100% + 6px);
  transform: translateY(-8px);
}

.tooltip.-bottom .tooltip__content::after {
  top: -4px;
}

.tooltip.-left .tooltip__content {
  right: calc(100% + 6px);
  transform: translateX(8px);
}

.tooltip.-left .tooltip__content::after {
  right: -4px;
}

.tooltip.-right .tooltip__content {
  left: calc(100% + 6px);
  transform: translateX(-8px);
}

.tooltip.-right .tooltip__content::after {
  left: -4px;
}

.tooltip:hover .tooltip__content {
  transform: none;
  opacity: 1;
}

.gallery__item {
  position: relative;
  display: block;
}

.gallery__item:hover .gallery__button {
  opacity: 1;
}

.gallery__button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  background-color: black;
  border-radius: 100%;
  width: rem(52px);
  height: rem(52px);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  opacity: 0;
}

.gallery__button .icon {
  color: white;
  stroke-width: 1;
  width: rem(28px);
  height: rem(28px);
}

.gallery__button.-bottom-right {
  top: unset;
  left: unset;
  transform: unset;
  bottom: rem(30px);
  right: rem(30px);
}

.form-input {
  position: relative;
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  display: flex;
}

.form-input label {
  position: absolute;
  top: 0;
  top: 26px;
  padding: 0 15px;
  pointer-events: none;
  font-size: 14px;
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.form-input textarea,
.form-input input {
  border: 1px solid var(--color-border);
  border-radius: 4px;
  padding: 0 15px;
  padding-top: 25px;
  min-height: 70px;
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.form-input textarea:focus,
.form-input input:focus {
  border: 2px solid #051036 !important;
}

.form-input textarea:focus ~ label, .form-input textarea:valid ~ label,
.form-input input:focus ~ label, .form-input input:valid ~ label {
  transform: translateY(-10px);
}

.sidebar.-blog > * + * {
  padding-top: 30px;
  margin-top: 30px;
}

.list-disc li {
  list-style-type: disc;
  list-style-position: inside;
}

.w-360 {
  width: 360px;
}

@media (max-width: 991px) {
  .lg\:w-full {
    width: 100% !important;
  }
}

@media (max-width: 575px) {
  .sm\:w-full {
    width: 100% !important;
  }
}

.w-140 {
  width: 140px;
  max-width: 100%;
}

.w-210 {
  width: 210px;
  max-width: 100%;
}

.w-230 {
  width: 230px;
  max-width: 100%;
}

.no-page__title {
  font-size: 200px;
  font-weight: 600;
  line-height: 1.2;
}

table.table th {
  padding: 25px 40px;
  line-height: 1;
  font-weight: 500;
}

table.table th:first-child {
  border-radius: 8px 0 0 8px;
}

table.table th:last-child {
  border-radius: 0 8px 8px 0;
}

table.table tbody > tr + tr {
  border-top: 1px solid var(--color-border);
}

table.table td {
  padding: 20px 40px;
  font-size: 15px;
  border-bottom: 1px solid var(--color-light-5);
}

table.table-2 th {
  font-weight: 500;
  padding: 15px 10px;
  line-height: 1.2;
}

table.table-2 th:first-child {
  border-radius: 8px 0 0 8px;
}

table.table-2 th:last-child {
  border-radius: 0 8px 8px 0;
}

table.table-2 th:nth-child(1) {
  padding-left: 0;
}

table.table-2 th:last-child {
  text-align: right;
  padding-right: 0;
}

table.table-2 tbody > tr {
  border-top: 1px dashed var(--color-border);
  vertical-align: top;
}

table.table-2 td {
  padding: 15px 10px;
  font-size: 15px;
  line-height: 1.2;
}

table.table-2 td:nth-child(1) {
  padding-left: 0;
}

table.table-2 td:last-child {
  text-align: right;
  padding-right: 0;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

table.table-3 th {
  font-weight: 500;
  padding: 20px 30px;
  line-height: 1.2;
  min-width: 130px;
}

@media (max-width: 1199px) {
  table.table-3 th {
    padding: 15px 15px;
    min-width: 100px;
  }
}

table.table-3 th:first-child {
  border-radius: 8px 0 0 8px;
}

table.table-3 th:last-child {
  border-radius: 0 8px 8px 0;
}

table.table-3 tbody tr {
  vertical-align: top;
}

table.table-3 tbody > tr + tr {
  border-top: 1px dashed var(--color-border);
}

table.table-3 td {
  padding: 15px 30px;
  font-size: 15px;
  line-height: 1.2;
  min-width: 130px;
}

@media (max-width: 1199px) {
  table.table-3 td {
    padding: 15px 15px;
  }
}

table.table-3.-border-bottom tbody > tr {
  border-top: none;
  border-bottom: 1px dashed var(--color-border);
}

table.table-4 th {
  font-weight: 500;
  padding: 20px 30px;
  line-height: 1.2;
  min-width: 130px;
}

@media (max-width: 1199px) {
  table.table-4 th {
    padding: 15px 15px;
    min-width: 100px;
  }
}

table.table-4 th:first-child {
  border-radius: 8px 0 0 8px;
}

table.table-4 th:last-child {
  border-radius: 0 8px 8px 0;
}

table.table-4 tbody tr {
  vertical-align: top;
}

table.table-4 tbody > tr + tr {
  border-top: 1px dashed var(--color-border);
}

table.table-4 td {
  padding: 20px 30px;
  font-size: 15px;
  line-height: 1.2;
  min-width: 130px;
}

@media (max-width: 1199px) {
  table.table-4 td {
    padding: 15px 15px;
  }
}

table.table-4.-border-bottom tbody > tr {
  border-top: none;
  border-bottom: 1px dashed var(--color-border);
}

table.table-5 {
  width: 100%;
}

table.table-5 th {
  font-weight: 500;
  padding: 20px 15px;
  line-height: 1.2;
  min-width: 130px;
}

@media (max-width: 1199px) {
  table.table-5 th {
    padding: 15px 15px;
    min-width: 100px;
  }
}

table.table-5 th:first-child {
  border-radius: 8px 0 0 8px;
}

table.table-5 th:last-child {
  border-radius: 0 8px 8px 0;
}

table.table-5 tbody tr {
  vertical-align: top;
}

table.table-5 tbody > tr {
  border-bottom: 1px solid var(--color-border);
}

table.table-5 td {
  padding: 20px 15px;
  font-size: 15px;
  line-height: 1.2;
  min-width: 130px;
}

@media (max-width: 1199px) {
  table.table-5 td {
    padding: 15px 15px;
  }
}

.z--1 {
  z-index: -1;
}

.z-0 {
  z-index: 0;
}

.z-1 {
  z-index: 1;
}

.z-2 {
  z-index: 2;
}

.z-3 {
  z-index: 3;
}

.z-4 {
  z-index: 4;
}

.z-5 {
  z-index: 5;
}

.side-badge {
  position: absolute;
  top: -7px;
  left: -3px;
}

.side-badge > * {
  border: 3px solid white;
}

.map-500 {
  min-height: 500px;
  height: 500px;
}

@media (max-width: 575px) {
  .map-500 {
    min-height: 400px;
    height: 400px;
  }
}

.map-form {
  position: relative;
  margin-top: -680px;
}

@media (max-width: 991px) {
  .map-form {
    margin-top: 40px;
  }
}

.h-400 {
  height: 400px;
  min-height: 400px;
}

.table-calendar {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.table-calendar__header {
  font-size: 14px;
  font-weight: 600;
}

.table-calendar__grid {
  font-size: 15px;
}

.table-calendar__cell {
  cursor: pointer;
  position: relative;
  z-index: 0;
  transition: all 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.table-calendar__cell:hover {
  background-color: var(--color-dark-3);
  color: white;
}

.table-calendar__cell.-is-in-path {
  position: relative;
  z-index: 0;
  color: var(--color-dark-1) !important;
}

.table-calendar__cell.-is-in-path::before {
  content: "";
  background-color: var(--color-light-2);
  position: absolute;
  top: 0;
  left: -16px;
  right: -16px;
  z-index: -2;
  height: 100%;
}

.table-calendar__cell.-is-active {
  position: relative;
  z-index: 2;
  background-color: var(--color-dark-3);
  color: white;
}

.table-calendar__header, .table-calendar__grid {
  display: grid;
  justify-content: center;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 8px;
}

.table-calendar__header > *, .table-calendar__grid > * {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 48px;
  height: 48px;
  border-radius: 100%;
}

@media (max-width: 767px) {
  .table-calendar__header > *, .table-calendar__grid > * {
    width: 32px;
    height: 32px;
  }
}

.desktopMenu {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 450px;
  max-width: 100%;
  height: 100%;
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  opacity: 0;
  pointer-events: none;
}

.desktopMenu .mobile-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background-color: white;
}

.desktopMenu__content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.desktopMenu-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -2;
  background-color: rgba(5, 16, 54, 0.5);
}

.desktopMenu.is-menu-active {
  opacity: 1;
  pointer-events: auto;
}

.desktopMenu.is-menu-active .-is-active {
  pointer-events: auto !important;
  overflow-x: hidden;
}

.desktopMenu .menu {
  overflow-y: hidden;
  overflow-x: hidden;
  position: relative;
  height: 100%;
}

.desktopMenu .menu ul {
  pointer-events: none;
}

.desktopMenu .menu li {
  overflow: hidden;
}

.desktopMenu .menu a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 0 20px;
  border-radius: 4px;
  font-weight: 500;
  color: var(--color-dark-1);
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.desktopMenu .menu a .icon {
  font-size: 8px;
  transform: rotate(-90deg);
}

.desktopMenu .menu a:hover {
  color: var(--color-blue-1);
}

.desktopMenu .menu__nav {
  display: flex;
  flex-direction: column;
}

.desktopMenu .menu__nav > li > a {
  font-size: 20px;
}

.desktopMenu .menu__nav > li > a:hover {
  background-color: rgba(53, 84, 209, 0.05) !important;
}

.desktopMenu .menu .subnav {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.desktopMenu .menu .subnav a {
  height: 40px;
  font-size: 18px;
  padding: 0 20px;
  transform: translateY(100%);
}

.desktopMenu .menu .subnav__backBtn {
  margin-bottom: 10px;
}

.desktopMenu .menu .subnav__backBtn li {
  padding: 0;
}

.desktopMenu .menu .subnav__backBtn a {
  justify-content: flex-start;
  height: 50px;
  font-size: 20px;
  color: var(--color-blue-1);
  background-color: rgba(53, 84, 209, 0.05) !important;
}

.desktopMenu .menu .subnav__backBtn a .icon {
  margin-right: 12px;
  transform: rotate(90deg);
}

.searchMenu {
  position: relative;
}

.searchMenu__field {
  position: absolute;
  padding-top: 30px;
  top: 100%;
  left: 0;
  z-index: 5;
  width: 500px;
  max-width: 100vw;
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  pointer-events: none;
  opacity: 0;
}

.searchMenu__field.is-visible {
  pointer-events: auto;
  opacity: 1;
}

.searchMenu .-link:hover {
  background-color: rgba(53, 84, 209, 0.05) !important;
}

.h-34 {
  height: 34px;
}

.dropRating {
  position: absolute;
  top: calc(100% + 10px);
  z-index: 10;
  width: 340px;
  max-width: 100vw;
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  pointer-events: none;
  opacity: 0;
}

.dropRating.-is-active {
  pointer-events: auto;
  opacity: 1;
}

.quote {
  position: relative;
  padding: 20px 0;
  padding-left: 75px;
  width: 740px;
  max-width: 100%;
}

.quote__line {
  width: 5px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.quote__icon {
  position: absolute;
  top: 12px;
  left: 22px;
  z-index: -1;
}

.hotelSingleGrid {
  display: grid;
  gap: 30px;
  grid-template-columns: 1fr 360px;
}

@media (max-width: 767px) {
  .hotelSingleGrid {
    grid-template-columns: 1fr;
  }
}

.border-test {
  position: absolute;
  top: 0;
  bottom: 40px;
  left: 19px;
  z-index: -1;
  border-left: 2px dashed #E5F0FD;
}

.border-line-2 {
  position: absolute;
  top: 12px;
  bottom: 12px;
  left: 13px;
  z-index: -1;
  border-left: 1px solid var(--color-border);
}

@media (max-width: 575px) {
  .border-line-2 {
    display: none;
  }
}

.map {
  width: 100%;
  height: 100%;
  background-color: #a3a3a3;
}

.carsSlider {
  display: grid;
  gap: 10px;
  grid-template-columns: 120px calc(100% - 130px);
}

@media (max-width: 767px) {
  .carsSlider {
    grid-template-columns: 1fr;
  }
}

.carsSlider .carsSlider-slides {
  display: flex;
  flex-direction: column;
}

.carsSlider .carsSlider-slides > * + * {
  margin-top: 10px;
}

@media (max-width: 767px) {
  .carsSlider .carsSlider-slides {
    flex-direction: row;
    order: 2;
  }
  .carsSlider .carsSlider-slides > * + * {
    margin-top: 0;
    margin-left: 10px;
  }
}

.carsSlider .carsSlider-slides__item {
  border: 1px solid #DDDDDD;
}

.carsSlider .carsSlider-slides__item.-is-active {
  border: 1px solid #051036;
}

.carsSlider .carsSlider-slider {
  overflow: hidden;
  width: 100%;
}

@media (max-width: 767px) {
  .carsSlider .carsSlider-slider {
    order: 1;
  }
}

.carsSlider .carsSlider-slider img {
  border: 1px solid #DDDDDD;
  width: 100%;
  max-height: 510px;
}

.cruiseSlider .cruiseSlider-slides__item {
  border: 1px solid #DDDDDD;
}

.cruiseSlider .cruiseSlider-slides__item.-is-active {
  border: 1px solid #051036;
}

.cruiseSlider .cruiseSlider-slider {
  overflow: hidden;
  width: 100%;
}

@media (max-width: 767px) {
  .cruiseSlider .cruiseSlider-slider {
    order: 1;
  }
}

.cruiseSlider .cruiseSlider-slider img {
  border: 1px solid #DDDDDD;
  width: 100%;
  max-height: 510px;
}

.cruiseSlider__nav {
  position: absolute;
  top: 50%;
  z-index: 10;
  transform: translateY(-50%);
}

.cruiseSlider__nav.-prev {
  left: 20px;
}

.cruiseSlider__nav.-next {
  right: 20px;
}

@media (max-width: 767px) {
  .cruiseSlider__nav {
    top: unset;
    bottom: 30px;
    transform: none;
  }
  .cruiseSlider__nav.-prev {
    left: unset;
    right: calc(50% + 10px);
  }
  .cruiseSlider__nav.-next {
    right: unset;
    left: calc(50% + 10px);
  }
}

.w-max-120 {
  max-width: 120px;
}

.singleMenu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 20;
  top: 0;
  background-color: white;
  border-bottom: 1px solid #DDDDDD;
  display: flex;
  align-items: center;
  transition: all 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
  opacity: 0;
  pointer-events: none;
}

.singleMenu.-is-active {
  opacity: 1;
  pointer-events: auto;
}

.singleMenu__content {
  width: 100%;
  min-height: 80px;
  padding: 15px 0;
}

.singleMenu__links a {
  font-size: 15px;
  font-weight: 500;
}

.overflow-x-hidden {
  overflow-x: clip;
}

.-is-dd-wrap-active .-dd-button {
  border-color: var(--color-dark-1) !important;
  outline: 1px solid var(--color-dark-1) !important;
}

.-is-dd-wrap-active .-dd-button .icon {
  transform: rotate(180deg);
}

.noUi-target {
  height: 4px;
  border: none;
  outline: 1px solid rgba(0, 0, 0, 0.08);
}

.noUi-connect {
  background-color: var(--color-blue-1) !important;
  box-shadow: 0 !important;
}

.noUi-handle {
  border-radius: 100%;
  width: 20px !important;
  height: 20px !important;
  top: 50% !important;
  transform: translateY(-50%);
  border: 2px solid var(--color-blue-1);
  box-shadow: 0 !important;
}

.noUi-handle::before {
  display: none;
}

.noUi-handle::after {
  display: none;
}

.filterPopup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1100;
  overflow-y: scroll;
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  opacity: 0;
  pointer-events: none;
}

.filterPopup.-is-active {
  opacity: 1;
  pointer-events: auto;
}

.base-tr {
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.date-input {
  display: flex;
  flex-flow: row-reverse;
  width: 100%;
}

.date-input::-webkit-calendar-picker-indicator {
  cursor: pointer;
  background: none;
  z-index: 2;
  margin-right: 10px;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.calendar-icon {
  position: absolute;
  top: 28px;
}

.calendar-icon.-left {
  left: 40px;
}

.calendar-icon.-right {
  right: 40px;
}

.gm-style-iw-t {
  right: unset !important;
  left: 25px;
  bottom: 20px !important;
}

.mapMarker {
  position: relative;
  z-index: 100;
}

.mapMarker:hover {
  background-color: var(--color-dark-1) !important;
  border-color: var(--color-dark-1) !important;
  color: white !important;
}

.gm-style .gm-style-iw-c {
  border-radius: 4px;
}

.mapItem__img {
  width: 100px !important;
  height: 100px !important;
  object-fit: cover;
}

.mapItem__content {
  height: 100px;
}

.map-ratio {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 700px;
}

.show-more__content {
  overflow: hidden;
  max-height: 0;
  transition: .2s;
}

.show-more.-h-60 .show-more__content {
  max-height: 80px;
}

.elCalendar__slider {
  overflow: hidden;
}

.elCalendar__sliderNav {
  position: absolute;
  top: 40px;
  z-index: 10;
}

.elCalendar__sliderNav.-prev {
  left: 60px;
}

.elCalendar__sliderNav.-next {
  right: 60px;
}

@media (max-width: 767px) {
  .elCalendar__sliderNav.-prev {
    left: 54px;
  }
  .elCalendar__sliderNav.-next {
    right: 54px;
  }
}

.elCalendar__month {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.elCalendar__header {
  display: grid;
  grid-template-columns: repeat(7, min-content);
  gap: 8px;
}

.elCalendar__header__sell {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  font-size: 14px;
  font-weight: 600;
}

@media (max-width: 767px) {
  .elCalendar__header__sell {
    width: 32px;
    height: 32px;
  }
}

.elCalendar__body {
  display: grid;
  grid-template-columns: repeat(7, min-content);
  gap: 8px;
}

.elCalendar__sell {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 100%;
  transition: 0.2s;
}

@media (max-width: 767px) {
  .elCalendar__sell {
    width: 32px;
    height: 32px;
  }
}

.elCalendar__sell.-dark {
  opacity: 0.4;
}

.elCalendar__sell:hover {
  background-color: rgba(0, 0, 0, 0.041);
}

.elCalendar__sell.-is-active {
  position: relative;
  color: white;
  background-color: var(--color-dark-3);
  z-index: 1;
}

.elCalendar__sell.-is-in-path {
  position: relative;
  color: var(--color-dark-1) !important;
  z-index: 0;
}

.elCalendar__sell.-is-in-path::before {
  content: "";
  position: absolute;
  top: 0;
  left: -12px;
  right: -12px;
  height: 100%;
  background-color: var(--color-light-2);
  z-index: -2;
}

/******* LAYOUT ********/
html {
  scroll-behavior: smooth;
}

.font-primary {
  font-family: "Barlow Condensed" !important;
  font-style: normal;
  font-weight: 600;
}

.login.full {
  background: var(--color-primary-d);
}

.no-page__title {
  font-size: 120px;
  font-weight: 600;
  line-height: 1.2;
}

main.login.full > section:last-of-type {
  padding-bottom: 0 !important;
}

main.login.full .header-logo img {
  max-width: 200px;
}

main.login.full .header-logo.federation .baseline {
  padding: 12px 7px;
  font-size: 22px;
  color: #c7dfdb;
  background: #3d858196;
  max-width: 200px;
  margin-top: 8px;
  text-align: center;
}

main.login.full a.link {
  color: var(--color-primary-3);
}

main.login.full .form-input textarea:focus,
main.login.full .form-input input:focus {
  background: #fff !important;
  outline: none;
  border: 2px solid white !important;
}

main.login.full a.button-primary:hover {
  background: var(--color-primary-3);
}

.bloc-login {
  height: initial;
  min-height: initial;
}

.enseignant .bloc-login {
  height: calc(100vh - 200px);
  min-height: 1000px;
  padding-bottom: 100px !important;
}

main.login.full .bloc-login {
  align-items: start !important;
  padding-top: 40px !important;
  padding-bottom: 40px !important;
  min-height: initial;
  height: initial;
}

.switchversion {
  position: fixed;
  top: 180px;
  background: var(--color-terracotta);
  z-index: 99999;
  color: white;
  padding: 5px 15px;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
  transform: rotate(90deg) translate(0, -100%);
  transform-origin: top left;
}

main.has-sticky {
  overflow: initial;
}

.heading,
.accueil .heading {
  background: var(--color-primary);
}

.header,
.breadcrumb {
  background: var(--color-primary);
}

.z-index-min {
  z-index: 0;
}

.z-index-med {
  z-index: 999;
}

.z-index-max {
  z-index: 999999;
}

a:hover {
  color: var(--color-primary-3);
}

.opacity-0 {
  opacity: 0 !important;
}

.preloader__wrap {
  border: none;
  box-shadow: none;
  background: none;
}

.preloader__icon {
  position: initial;
  animation-name: initial;
  animation-iteration-count: initial;
  animation-duration: initial;
  animation-timing-function: initial;
}

.back-to-top {
  bottom: 3em;
  opacity: 0.9;
  position: fixed;
  right: 4em;
  transition: opacity 200ms ease;
  z-index: 999999;
  color: var(--color-terracotta);
}

.back-to-top i[class*=" fi-ss-"]:before {
  font-size: 50px;
  color: var(--color-terracotta);
}

.back-to-top:hover {
  opacity: 1;
}

.at-top .back-to-top {
  opacity: 0;
  pointer-events: none;
}

.parallax {
  background-attachment: fixed !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.55);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.accueil .carousel .overlay {
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.7) 30%, rgba(0, 0, 0, 0) 100%);
}

.wrap {
  display: flex;
  align-items: center;
  justify-content: right;
  margin: 20px 20px 20px 40px;
  position: relative;
}

.blob {
  background: var(--color-primary-4);
  width: 500px;
  height: 500px;
  border-radius: 40% 60% 70% 30% / 40% 50% 60% 50%;
  overflow: hidden;
  animation: morph 15s linear infinite;
  z-index: 999;
  top: 40px;
}

.blob-back {
  background: var(--color-primary);
  min-width: 500px;
  min-height: 500px;
  border-radius: 40% 60% 70% 30% / 40% 50% 60% 50%;
  overflow: hidden;
  position: absolute;
  z-index: 9;
  margin-left: 30px;
  top: 20px;
}

.searchbar.container {
  max-width: 960px;
}

.searchbar.container.slogan {
  padding-top: 120px;
  padding-bottom: 40px;
}

.searchbar .blob {
  height: 450px;
  width: 450px;
  top: 20px;
}

.searchbar .blob-back {
  min-width: 450px;
  min-height: 450px;
  top: 0;
}

.accueil .searchbar h1 {
  line-height: 60px;
  margin-top: 40px;
  margin-top: 0px !important;
  padding-right: 30px;
}

.accueil .text-strong {
  color: var(--color-ocre);
}

.blob-back-enseignant {
  background: #c17f06;
  min-width: 360px;
  min-height: 340px;
  border-radius: 40% 60% 70% 30% / 40% 50% 60% 50%;
  overflow: hidden;
  position: absolute;
  z-index: 9;
  left: 20px;
  top: 10px;
}

.blob img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@keyframes morph {
  0%,
  100% {
    border-radius: 40% 60% 70% 30% / 40% 40% 60% 50%;
  }
  34% {
    border-radius: 70% 30% 50% 50% / 30% 30% 70% 70%;
  }
  67% {
    border-radius: 100% 60% 60% 100% / 100% 100% 60% 60%;
  }
}

.accueil h1 {
  color: var(--color-titre-light);
  font-family: "Barlow Condensed", sans-serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 600;
  line-height: 50px;
  text-transform: none;
  margin-top: 40px;
}

.accueil .heading.parallax {
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("../img/hero/hero-region.png");
  background-position: left !important;
}

.accueil section.secteur1.bafa {
  position: relative;
  min-height: 800px;
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.7) 30%, rgba(0, 0, 0, 0.1) 100%), url("../img/gallery/bafa-6.jpg");
  background-attachment: fixed !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.accueil section.secteur1.educform {
  background-image: linear-gradient(rgba(0, 0, 0, 0.9) 30%, rgba(0, 0, 0, 0.1) 100%), url("../img/gallery/educform-1.jpg");
  min-height: 800px;
  padding-top: 160px !important;
  background-attachment: fixed !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.accueil section.secteur2.sejour {
  background-image: none;
  margin-top: 0px;
  position: relative;
  background-color: var(--color-primary-4) !important;
  padding: 80px 20px;
}

.accueil section.secteur2.enseignant {
  position: relative;
  background-color: var(--color-ocre) !important;
  margin-top: 0px;
  padding-top: 100px;
  padding-bottom: 100px;
  color: #fbf4e7 !important;
}

.accueil section.secteur3.bafa {
  position: relative;
  min-height: 500px;
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.7) 30%, rgba(0, 0, 0, 0.1) 100%), url("../img/gallery/bafa-6.jpg");
  background-attachment: fixed !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  padding-top: 200px;
  padding-bottom: 200px;
}

.accueil section.secteur3.educform {
  background-image: linear-gradient(rgba(0, 0, 0, 0.9) 30%, rgba(0, 0, 0, 0.1) 100%), url("../img/gallery/educform-1.jpg");
  min-height: 800px;
  padding-top: 160px;
  padding-bottom: 160px;
  background-attachment: fixed !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.accueil section.secteur4.sejour {
  background-image: none;
  padding-top: 0px;
  margin-top: 0px;
  position: relative;
  background-color: var(--color-primary-4) !important;
  padding: 80px 20px;
}

.accueil section.secteur4.enseignant {
  position: relative;
  background-color: var(--color-ocre) !important;
  padding-top: 100px;
  padding-bottom: 100px;
  color: #fbf4e7 !important;
}

.accueil .curve-after.curve-suggestions {
  bottom: 0;
}

.accueil section.sejour::before {
  border-radius: 50%;
  height: 100px;
  top: 50px;
  transform: translate(-5%, -100%);
  -ms-transform: translate(-5%, -100%);
  -webkit-transform: translate(-5%, -100%);
  content: "";
  position: absolute;
  width: 110%;
  z-index: 9;
  background: var(--color-primary-4) !important;
}

.accueil section.sejour::after {
  border-radius: 50%;
  height: 100px;
  bottom: -150px;
  transform: translate(-5%, -100%);
  -ms-transform: translate(-5%, -100%);
  -webkit-transform: translate(-5%, -100%);
  content: "";
  position: absolute;
  width: 110%;
  z-index: 9;
  background: var(--color-primary-4) !important;
}

.accueil section.enseignant::before {
  border-radius: 50%;
  height: 150px;
  top: 80px;
  transform: translate(-5%, -100%);
  -ms-transform: translate(-5%, -100%);
  -webkit-transform: translate(-5%, -100%);
  content: "";
  position: absolute;
  width: 110%;
  z-index: 9;
  background: var(--color-ocre) !important;
}

.accueil section.enseignant::after {
  border-radius: 50%;
  height: 150px;
  bottom: -200px;
  transform: translate(-5%, -100%);
  -ms-transform: translate(-5%, -100%);
  -webkit-transform: translate(-5%, -100%);
  content: "";
  position: absolute;
  width: 110%;
  z-index: 9;
  background: var(--color-ocre) !important;
}

.accueil section.bafa {
  position: relative;
  background: #fff;
  background-image: url("../img/gallery/bafa-6.jpg");
  min-height: 800px;
}

.accueil section.bafa.parallax {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.7) 30%, rgba(0, 0, 0, 0.1) 100%), url("../img/gallery/bafa-6.jpg");
}

.accueil .testimonials {
  font-size: 22px;
  white-space: pre-wrap;
}

.accueil .testimonials.slogan {
  font-size: 36px;
  line-height: 60px;
  padding-top: 20px;
}

.blur {
  backdrop-filter: blur(3px);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 0;
  height: 100%;
}

.accueil section.educform.parallax {
  background-image: linear-gradient(rgba(0, 0, 0, 0.9) 30%, rgba(0, 0, 0, 0.1) 100%), url("../img/gallery/educform-1.jpg");
  min-height: 800px;
  padding-top: 160px !important;
}

.accueil section.temoignages .presta {
  color: var(--color-primary-2);
}

.accueil section.temoignages .orga {
  color: var(--color-primary-3);
}

.accueil section.temoignages .swiper-slide {
  background-color: inherit;
  width: inherit !important;
}

.accueil .pagination.-avatars .pagination__item {
  cursor: pointer;
}

.accueil .pagination.-avatars .pagination__item.is-active::before {
  opacity: 1;
}

.accueil .pagination.-avatars .pagination__item::before {
  content: "";
  position: absolute;
  border-radius: 100%;
  width: 42px;
  height: 42px;
  border: 4px solid var(--color-primary-2);
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  opacity: 0;
}

.accueil .pagination i:before {
  font-size: 22px;
  color: var(--color-primary-3);
}

section.aroevens.equipe {
  background: #f0d9ae;
  padding: 200px 20px 80px 20px;
  margin-top: -120px;
}

section.aroevens.equipe h5 {
  background: var(--color-primary) !important;
  text-transform: uppercase;
  font-family: "Barlow condensed", sans-serif;
  font-weight: 700;
  font-size: 18px;
  color: white;
  display: inline-block;
  padding: 5px 15px;
  margin: 10px 0 5px 0;
  text-align: left;
}

section.aroevens.equipe h5.selection {
  background: #5f9c99 !important;
  text-transform: uppercase;
  font-family: "Barlow condensed", sans-serif;
  font-weight: 700;
  font-size: 18px;
  color: white;
  display: inline-block;
  padding: 5px 15px;
  margin: 10px 0 5px 0;
  text-align: left;
  max-width: 400px;
}

section.aroevens.equipe h5.selection .accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 5px;
  font-size: initial;
  color: white !important;
  text-transform: uppercase;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  background: transparent;
  justify-content: space-between;
}

section.aroevens.equipe h5.selection .accordion-button::after {
  width: auto;
  height: auto;
  background-image: none;
  font-family: "uicons-bold-straight" !important;
  content: "\f150";
  font-size: 16px;
  margin-left: 15px;
}

section.aroevens.equipe span.aroeven-contact {
  text-transform: none;
  font-family: "Barlow condensed", sans-serif;
  font-weight: 700;
  font-size: 18px;
  display: block;
  padding: 0;
  margin: 5px 0;
  text-align: left;
  line-height: 26px;
}

section.aroevens.equipe .liste-aroevens {
  color: var(--color-primary-4);
}

section.aroevens.equipe .liste-aroevens h3 {
  font-size: 30px;
  line-height: 32px;
  padding-bottom: 10px;
  margin-top: 25px;
}

.informatif section.aroevens.equipe .liste-aroevens h3 {
  font-size: 30px;
  line-height: 32px;
  padding-bottom: 10px;
  margin-top: 25px;
  color: var(--color-primary-4) !important;
  margin-bottom: 0;
}

section.aroevens.equipe .liste-aroevens span.aroeven-contact {
  text-transform: none;
  font-family: "Barlow condensed", sans-serif;
  font-weight: 700;
  font-size: 17px;
  display: block;
  padding: 0;
  margin: 3px 0;
  text-align: left;
  line-height: 20px;
}

section.aroevens.equipe a.button-primary.web {
  color: var(--color-white) !important;
  background-color: #5f9c99;
  margin: 10px 0px;
  width: fit-content;
  padding: 16px 24px;
  line-height: 20px;
}

section.aroevens.equipe .liste-aroevens a.button-primary.web {
  color: var(--color-white) !important;
  background-color: #5f9c99;
  margin: 10px 0px;
  padding: 10px 15px;
  width: fit-content;
  line-height: 20px;
}

section.aroevens.equipe.fede {
  background: #e3eae7;
}

.informatif section.aroevens.equipe {
  background: none !important;
  padding: 0 !important;
  margin-top: 120px !important;
}

.informatif section.interlocuteurs.aroevens.equipe.team {
  padding-bottom: 60px !important;
}

.informatif section.interlocuteurs.aroevens.equipe.team .bubble-back.actif {
  background: var(--color-primary-4);
}

section.aroevens.equipe .liste-aroevens h5 {
  width: 100%;
}

section.aroevens.equipe.fede .libelle-region a.surbrillance {
  background: var(--color-primary-4);
  color: var(--color-white);
  padding: 2px 5px;
}

section.aroevens.equipe.fede .box {
  width: 40px;
  height: 40px;
}

section.aroevens.equipe.fede .box.surbrillance {
  background-image: url(../img/map/marker-hover.svg);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  cursor: pointer;
  -webkit-transition: background-image 0.2s ease-in-out;
  transition: background-image 0.2s ease-in-out;
  transform: scale(1.2);
}

section.aroevens.equipe.fede .box:hover {
  background-image: url(../img/map/marker-hover.svg);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  cursor: pointer;
  -webkit-transition: background-image 0.2s ease;
  transition: background-image 0.2s ease;
  transform: scale(1.2);
}

section.aroevens.equipe .titre h3 {
  color: var(--color-ocre-dark);
}

section.aroevens.equipe .titre h4 {
  color: var(--color-ocre) !important;
}

.equipe .parent {
  display: flex;
  gap: 40px;
}

.equipe .child.libelle-region {
  margin-left: 80px;
}

.equipe .child.libelle-region ul li {
  font-size: 20px;
  color: var(--color-primary-4);
  font-family: "Barlow semi condensed";
  font-weight: 700;
  text-align: center;
  margin-top: 3px;
  text-align: left;
}

section.equipe .swiper-slide {
  background-color: transparent;
  width: 150px !important;
}

.cartefrance {
  position: relative;
  display: inline-block;
  min-width: 600px;
}

.cartefrance img {
  max-width: 100%;
  display: block;
}

.box {
  width: 40px;
  height: 40px;
  background-image: url(../img/map/marker.svg);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  cursor: pointer;
}

#pin1 {
  top: 29%;
  left: 36%;
}

#pin2 {
  top: 45%;
  left: 48%;
}

#pin3 {
  top: 45%;
  left: 48%;
}

#pin4 {
  top: 45%;
  left: 48%;
}

#pin5 {
  top: 35%;
  left: 28%;
}

#pin6 {
  top: 65%;
  left: 78%;
}

#pin7 {
  top: 45%;
  left: 68%;
}

#pin8 {
  top: 55%;
  left: 68%;
}

.box:hover > .pin-text {
  display: block;
}

.pin-text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 75%;
  white-space: nowrap;
  display: none;
}

.pin-text h3 {
  font-size: 20px;
  text-transform: uppercase;
  color: var(--color-primary-3);
  font-family: "Barlow semi condensed";
  font-weight: 700;
  text-align: center;
  margin-top: 10px;
}

section.equipe .bubble.team {
  height: 150px;
  width: 160px;
  clip-path: polygon(55.11% 0.032%, 55.11% 0.032%, 61.042% 0.627%, 66.711% 1.991%, 72.077% 4.072%, 77.1% 6.816%, 81.739% 10.169%, 85.956% 14.079%, 89.711% 18.491%, 92.962% 23.353%, 95.672% 28.611%, 97.799% 34.211%, 97.799% 34.211%, 99.211% 39.84%, 99.911% 45.493%, 99.918% 51.116%, 99.247% 56.651%, 97.919% 62.044%, 95.948% 67.239%, 93.354% 72.18%, 90.154% 76.812%, 86.366% 81.08%, 82.006% 84.926%, 82.006% 84.926%, 76.571% 88.814%, 70.825% 92.227%, 64.838% 95.09%, 58.682% 97.324%, 52.43% 98.85%, 46.152% 99.592%, 39.92% 99.47%, 33.807% 98.407%, 27.883% 96.326%, 22.22% 93.147%, 22.22% 93.147%, 16.667% 88.747%, 11.883% 83.695%, 7.883% 78.085%, 4.683% 72.013%, 2.3% 65.574%, 0.748% 58.864%, 0.044% 51.977%, 0.203% 45.011%, 1.241% 38.059%, 3.174% 31.217%, 3.174% 31.217%, 5.829% 25.22%, 9.281% 19.84%, 13.443% 15.084%, 18.229% 10.962%, 23.549% 7.483%, 29.317% 4.654%, 35.445% 2.487%, 41.845% 0.988%, 48.429% 0.167%, 55.11% 0.032%);
  margin: 0;
  background: #e8c891;
}

.informatif section.equipe .bubble.team {
  height: 150px;
  width: 160px;
  clip-path: polygon(55.11% 0.032%, 55.11% 0.032%, 61.042% 0.627%, 66.711% 1.991%, 72.077% 4.072%, 77.1% 6.816%, 81.739% 10.169%, 85.956% 14.079%, 89.711% 18.491%, 92.962% 23.353%, 95.672% 28.611%, 97.799% 34.211%, 97.799% 34.211%, 99.211% 39.84%, 99.911% 45.493%, 99.918% 51.116%, 99.247% 56.651%, 97.919% 62.044%, 95.948% 67.239%, 93.354% 72.18%, 90.154% 76.812%, 86.366% 81.08%, 82.006% 84.926%, 82.006% 84.926%, 76.571% 88.814%, 70.825% 92.227%, 64.838% 95.09%, 58.682% 97.324%, 52.43% 98.85%, 46.152% 99.592%, 39.92% 99.47%, 33.807% 98.407%, 27.883% 96.326%, 22.22% 93.147%, 22.22% 93.147%, 16.667% 88.747%, 11.883% 83.695%, 7.883% 78.085%, 4.683% 72.013%, 2.3% 65.574%, 0.748% 58.864%, 0.044% 51.977%, 0.203% 45.011%, 1.241% 38.059%, 3.174% 31.217%, 3.174% 31.217%, 5.829% 25.22%, 9.281% 19.84%, 13.443% 15.084%, 18.229% 10.962%, 23.549% 7.483%, 29.317% 4.654%, 35.445% 2.487%, 41.845% 0.988%, 48.429% 0.167%, 55.11% 0.032%);
  margin: 0 auto;
  margin-top: 6px;
  background: #e8c891;
}

.informatif section.equipe .bubble-back {
  position: relative;
  height: 150px;
  width: 160px;
  clip-path: polygon(55.11% 0.032%, 55.11% 0.032%, 61.042% 0.627%, 66.711% 1.991%, 72.077% 4.072%, 77.1% 6.816%, 81.739% 10.169%, 85.956% 14.079%, 89.711% 18.491%, 92.962% 23.353%, 95.672% 28.611%, 97.799% 34.211%, 97.799% 34.211%, 99.211% 39.84%, 99.911% 45.493%, 99.918% 51.116%, 99.247% 56.651%, 97.919% 62.044%, 95.948% 67.239%, 93.354% 72.18%, 90.154% 76.812%, 86.366% 81.08%, 82.006% 84.926%, 82.006% 84.926%, 76.571% 88.814%, 70.825% 92.227%, 64.838% 95.09%, 58.682% 97.324%, 52.43% 98.85%, 46.152% 99.592%, 39.92% 99.47%, 33.807% 98.407%, 27.883% 96.326%, 22.22% 93.147%, 22.22% 93.147%, 16.667% 88.747%, 11.883% 83.695%, 7.883% 78.085%, 4.683% 72.013%, 2.3% 65.574%, 0.748% 58.864%, 0.044% 51.977%, 0.203% 45.011%, 1.241% 38.059%, 3.174% 31.217%, 3.174% 31.217%, 5.829% 25.22%, 9.281% 19.84%, 13.443% 15.084%, 18.229% 10.962%, 23.549% 7.483%, 29.317% 4.654%, 35.445% 2.487%, 41.845% 0.988%, 48.429% 0.167%, 55.11% 0.032%);
  margin: 0 auto;
  background: transparent;
}

.informatif section.equipe .bubble-back.actif {
  background: var(--color-primary-4);
}

section.aroevens.equipe.team .bubble-back {
  position: relative;
  height: 150px;
  width: 160px;
  clip-path: polygon(55.11% 0.032%, 55.11% 0.032%, 61.042% 0.627%, 66.711% 1.991%, 72.077% 4.072%, 77.1% 6.816%, 81.739% 10.169%, 85.956% 14.079%, 89.711% 18.491%, 92.962% 23.353%, 95.672% 28.611%, 97.799% 34.211%, 97.799% 34.211%, 99.211% 39.84%, 99.911% 45.493%, 99.918% 51.116%, 99.247% 56.651%, 97.919% 62.044%, 95.948% 67.239%, 93.354% 72.18%, 90.154% 76.812%, 86.366% 81.08%, 82.006% 84.926%, 82.006% 84.926%, 76.571% 88.814%, 70.825% 92.227%, 64.838% 95.09%, 58.682% 97.324%, 52.43% 98.85%, 46.152% 99.592%, 39.92% 99.47%, 33.807% 98.407%, 27.883% 96.326%, 22.22% 93.147%, 22.22% 93.147%, 16.667% 88.747%, 11.883% 83.695%, 7.883% 78.085%, 4.683% 72.013%, 2.3% 65.574%, 0.748% 58.864%, 0.044% 51.977%, 0.203% 45.011%, 1.241% 38.059%, 3.174% 31.217%, 3.174% 31.217%, 5.829% 25.22%, 9.281% 19.84%, 13.443% 15.084%, 18.229% 10.962%, 23.549% 7.483%, 29.317% 4.654%, 35.445% 2.487%, 41.845% 0.988%, 48.429% 0.167%, 55.11% 0.032%);
  margin: 0 auto;
  background: transparent;
}

section.aroevens.equipe.team .bubble.team.photo {
  background-color: transparent !important;
}

section.aroevens.equipe.team .bubble-back.actif {
  background: var(--color-primary-4);
}

section.equipe .nom {
  font-size: 20px;
  text-transform: uppercase;
  color: var(--color-ocre-dark);
  font-family: "Barlow semi condensed";
  font-weight: 700;
  text-align: center;
  margin-top: 10px;
  transition: 0.3s;
}

section.equipe .adresse {
  font-size: 20px;
  text-transform: uppercase;
  color: var(--color-ocre-dark);
  font-family: "Barlow semi condensed";
  font-weight: 700;
  text-align: left;
  margin-top: 0;
  max-width: 200px;
  line-height: 20px;
}

section.equipe .info {
  color: var(--color-ocre);
  text-align: center;
  font-size: 16px;
  display: block;
  line-height: 20px;
  font-family: "Barlow semi condensed";
  margin: 0 auto;
  transition: 0.3s;
}

section.equipe p {
  color: var(--color-ocre);
  text-align: left;
  font-size: 18px;
  line-height: 22px;
  font-family: "Barlow semi condensed";
  margin-top: 0;
}

.accueil .team img {
  width: 100px;
  bottom: -15px;
  position: relative;
}

.accueil .team.photo img {
  bottom: auto;
  top: -10px;
  position: relative;
  -o-object-fit: cover;
  object-fit: cover;
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  min-width: 150px;
}

.accueil section.actualites {
  padding-bottom: 0px;
}

.accueil section.posts {
  background: var(--color-primary-4);
  padding: 180px 0;
}

.accueil section.recrutement {
  padding-bottom: 120px;
}

.accueil section.recrutement.last {
  padding-bottom: 300px;
}

.accueil section.temoignages {
  background: var(--color-primary-4);
  padding: 20px 0 100px 0;
  position: relative;
  color: var(--color-primary-2);
  padding-bottom: 200px;
}

.accueil section.temoignages h5 {
  color: var(--color-ocre);
  text-transform: uppercase;
  font-family: "Barlow semi condensed";
  font-size: 30px !important;
}

.accueil section.temoignages img.quotes {
  opacity: 0.15;
  position: absolute;
  top: 0;
  left: calc(50% - 75px);
}

.accueil .tabs__controls {
  position: relative;
  overflow: initial;
  scroll-snap-type: none;
  white-space: nowrap;
}

.slider-nav .vignettes-nav {
  color: var(--color-primary-2);
}

.slider-nav .pagination.-dots.text-border {
  color: var(--color-primary-2);
}

.slider-nav .pagination.-dots .pagination__item.is-active {
  background-color: var(--color-primary-2);
  transform: scale(1.3);
}

.slider-nav .vignettes-nav.swiper-button-disabled {
  color: var(--color-primary-4);
}

.slider-nav .vignettes-nav.arrow-right-hover:hover,
.slider-nav .vignettes-nav.arrow-left-hover:hover {
  color: var(--color-primary-2);
  transform: translateX(5px) scale(1.25);
}

.slider-nav .vignettes-nav.arrow-right-hover.swiper-button-disabled:hover,
.slider-nav .vignettes-nav.arrow-left-hover.swiper-button-disabled:hover {
  transform: none;
  color: var(--color-primary-4);
}

.slider-nav-dark .vignettes-nav {
  color: var(--color-primary-4);
}

.slider-nav-dark .pagination.-dots.text-border {
  color: var(--color-primary-4);
}

.slider-nav-dark .pagination.-dots .pagination__item.is-active {
  background-color: var(--color-primary-4);
  transform: scale(1.3);
}

.slider-nav-dark .vignettes-nav.swiper-button-disabled {
  color: var(--color-primary-2);
}

.slider-nav-dark .vignettes-nav.arrow-right-hover:hover,
.slider-nav-dark .vignettes-nav.arrow-left-hover:hover {
  color: var(--color-primary-4);
  transform: translateX(5px) scale(1.25);
}

.slider-nav-dark .vignettes-nav.arrow-right-hover.swiper-button-disabled:hover,
.slider-nav-dark .vignettes-nav.arrow-left-hover.swiper-button-disabled:hover {
  transform: none;
  color: var(--color-primary-2);
}

.equipe .slider-nav-dark .vignettes-nav.swiper-button-disabled {
  color: #e1c89b;
}

.gallery-a #hero-video,
.gallery-a .hero-video {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.equipe .slider-nav-dark .vignettes-nav.arrow-right-hover.swiper-button-disabled:hover,
.equipe .slider-nav-dark .vignettes-nav.arrow-left-hover.swiper-button-disabled:hover {
  color: #e1c89b;
}

.heading {
  background: var(--color-primary);
  margin-bottom: 4em;
  position: relative;
  color: #fff;
  padding-top: 4em;
}

.heading .curve {
  position: relative;
  display: block;
  height: 140px;
  bottom: -140px;
  margin-top: -100px;
  overflow: hidden;
}

.heading .curve::after {
  border-radius: 50%;
  height: 100px;
  bottom: 0;
  transform: translate(-5%, -100%);
  -ms-transform: translate(-5%, -100%);
  -webkit-transform: translate(-5%, -100%);
  content: "";
  position: absolute;
  width: 110%;
  z-index: -1;
  background: var(--color-primary);
}

.title {
  padding-top: 100px;
}

main > section:last-of-type {
  padding-bottom: 200px !important;
}

.sticky {
  position: sticky;
  top: 20px;
}

.bg-wave {
  background-color: var(--color-white) !important;
  position: relative;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-image: url("../img/backgrounds/bg-wave.svg");
  background-position: bottom;
}

.divider {
  background-color: var(--color-primary-5);
  height: 9px;
  --svg: url("../img/backgrounds/line-wave.svg");
  -webkit-mask-image: var(--svg);
  mask-image: var(--svg);
  background-image: url("../img/backgrounds/line-wave.svg");
  margin: 50px 0;
}

.accueil .enseignant .divider {
  background-color: var(--color-ocre-dark);
  height: 9px;
  --svg: url("../img/backgrounds/line-wave-ocre.svg");
  -webkit-mask-image: var(--svg);
  mask-image: var(--svg);
  background-image: url("../img/backgrounds/line-wave-ocre.svg");
  margin: 50px 20px;
  width: calc(100% - 40px);
}

.separateur {
  color: var(--color-light-1);
  font-family: "Barlow Condensed";
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
}

.encart-2 h4 {
  font-size: 16px;
}

.encart .separateur .divider {
  width: 100%;
  margin: 12px 10px 10px 10px;
  height: 8px;
}

section.carte {
  position: relative;
}

.encart {
  position: sticky;
  max-height: calc(100vh - 150px);
  overflow-y: auto;
  right: 15px;
  width: 360px;
  float: right;
}

.encart-1 {
  margin-bottom: 30px;
}

.encart-1 a.button-primary {
  background-color: var(--color-terracotta);
}

.encart .divider,
.filtres .divider {
  margin: 20px 0;
  padding: 0px;
}

.hebergement .encart {
  font-family: "Barlow Condensed";
  font-style: normal;
  font-weight: 700;
}

.hebergement .encart .form-input {
  padding-top: 5px;
  padding-bottom: 5px;
}

.hebergement .encart .select.mini .select__button {
  height: 32px;
  padding: 5px 10px;
  margin-right: 0px;
  width: 68px;
}

#resultats-contenu .divider {
  margin: 30px 0;
}

.bloc-logo {
  margin-top: -10px;
}

.baseline {
  color: white;
  padding: 5px 15px;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  position: relative;
  text-align: center;
}

.header-logo .baseline {
  color: white;
  padding: 4px;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  position: relative;
  font-size: 10px;
  max-width: 100px;
  display: block;
  line-height: 10px;
  background: rgba(255, 255, 255, 0.1);
  margin-top: 2px;
  text-align: center;
}

.header-logo.hero .baseline {
  color: #a4ccc5;
  padding: 8px 15px;
  font-size: 30px;
  max-width: 320px;
  display: block;
  line-height: 30px;
  background: rgba(255, 255, 255, 0.1);
  margin-top: 10px;
  border-radius: 0px;
  text-align: left;
}

.accueil .heading .header-logo.hero img {
  max-width: 320px;
}

.header-logo.federation .baseline {
  padding: 5px 7px;
  font-size: 11px;
  color: #dce8e6;
  background: #3d858196;
  text-align: center;
}

.header-logo.hero.federation .baseline {
  color: #a4ccc5;
  padding: 8px 15px;
  font-size: 37px;
  max-width: 320px;
  display: block;
  line-height: 30px;
  background: #3d858196;
  margin-top: 10px;
  border-radius: 0px;
  text-align: center;
}

.header.is-menu-opened {
  background-color: var(--color-primary) !important;
  border-bottom: none;
}

.header.is-menu-opened .icon-menu:before {
  font-family: "uicons-solid-straight" !important;
  content: "\f514";
}

.header .header-menu .menu li a {
  text-transform: uppercase;
  font-size: 16px;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  color: var(--color-white) !important;
  transition: 0.3s;
  padding: 5px 12px;
}

.header .header-menu .menu li a:hover {
  text-transform: uppercase;
  font-size: 16px;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  color: var(--color-primary-3) !important;
  cursor: pointer;
  background: none;
  transition: 0.3s;
  background-color: var(--color-primary-2) !important;
  color: var(--color-primary) !important;
}

.header .header-menu .menu li.hover a,
.header .header-menu .menu li.hoveractive a {
  text-transform: uppercase;
  font-size: 16px;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  cursor: pointer;
  background: none;
  transition: 0.3s;
  background-color: var(--color-primary-2) !important;
  color: var(--color-primary) !important;
}

.header .header-menu .menu .subnav a.chapeau {
  background-color: var(--color-primary-4) !important;
  border-radius: 0;
  color: #d9e7e5 !important;
}

.header .header-menu .menu .subnav > li a.chapeau:hover {
  background-color: var(--color-primary-4) !important;
  border-radius: 0;
  color: #d9e7e5 !important;
}

.header .header-menu .menu .subnav li.menu-item-has-children .subnav li a.chapeau {
  background: var(--color-ocre-dark) !important;
  color: #ebe6d5 !important;
}

.header .header-menu .menu .subnav li.menu-item-has-children .subnav li a.chapeau:hover {
  background: var(--color-ocre-dark) !important;
  color: #ebe6d5 !important;
}

a.link.white {
  background: linear-gradient(to right, var(--color-white), var(--color-white)), linear-gradient(to right, var(--color-primary-4), var(--color-primary-4));
  background-size: 100% 3px, 0 3px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 400ms;
  height: 30px;
  display: inline-block !important;
  color: var(--color-white);
}

a.link:hover {
  background-size: 0 3px, 100% 3px;
}

.header-margin {
  position: fixed;
}

.breadcrumb {
  padding-top: 100px !important;
  margin-bottom: 0;
  background-color: var(--color-primary);
  color: var(--color-primary-2);
  font-family: "Barlow semi Condensed", sans-serif;
  font-weight: 600;
}

.breadcrumb a.link.white {
  background: linear-gradient(to right, var(--color-primary-2), var(--color-primary-2)), linear-gradient(to right, var(--color-white), var(--color-white));
  background-size: 100% 3px, 0 3px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 400ms;
  height: 30px;
  display: inline-block !important;
  color: var(--color-primary-2) !important;
}

.breadcrumb a.link:hover {
  background-size: 0 3px, 100% 3px;
  color: var(--color-white) !important;
}

main.accueil {
  background-color: var(--color-primary);
  overflow: hidden;
}

.accueil .footer {
  position: relative;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-image: url(../img/backgrounds/bg-footer.svg);
  background-position: top;
  padding-top: 160px;
  color: var(--color-ocre-light);
  margin-top: -400px;
  background-color: transparent;
}

.padding-footer {
  height: 200px;
}

.accueil .heading {
  background-size: auto 90%;
  background-repeat: no-repeat;
  background-position: -300px 100px;
  background-color: var(--color-primary-4);
  padding-top: 80px;
  margin-bottom: 0;
}

.accueil .heading .carousel-inner {
  overflow: initial;
}

.accueil .heading .carousel-inner.full .carousel-item {
  max-height: 800px;
  overflow: hidden;
}

.accueil .heading .carousel-inner.full .carousel-item img {
  object-fit: cover;
  min-height: 80vh;
}

.accueil .heading .carousel-item {
  height: 120%;
  position: relative;
}

.accueil .heading.detoure .carousel-item.main img {
  float: left;
  margin-left: -300px;
  height: 700px !important;
  width: auto !important;
  margin-top: 100px;
  position: relative;
}

.accueil #hero-video,
.accueil .hero-video {
  height: 100vh;
  width: 100vw;
  object-fit: cover;
}

.accueil .heading .header-logo img {
  max-width: 230px;
  position: relative;
  width: 100%;
}

.accueil .suggestions {
  background-image: none;
  padding-top: 0px;
  margin-top: 0px;
}

.accueil .titre h3 {
  padding-bottom: 15px;
  color: var(--color-primary-2);
  font-size: 58px;
  margin-bottom: 0;
  line-height: 58px;
}

.accueil .titre h4 {
  font-size: 34px;
  color: var(--color-primary-3) !important;
  margin-bottom: 20px;
  line-height: 34px;
}

.accueil .educform .titre h3,
.accueil .bafa .titre h3 {
  color: var(--color-primary-1);
}

.accueil .educform .titre h4,
.accueil .bafa .titre h4 {
  color: var(--color-primary-2) !important;
}

.accueil a.button-outline {
  color: var(--color-primary-2) !important;
  border: 2px solid var(--color-primary-2) !important;
}

.accueil a.button-outline:hover {
  color: var(--color-primary-4) !important;
  background: var(--color-primary-2) !important;
}

.accueil a.button-primary {
  text-transform: uppercase;
  text-align: center;
  font-size: 16px;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  color: var(--color-white) !important;
  background-color: var(--color-ocre-dark);
  border-radius: 200px;
  transition: 0.3s;
  white-space: normal;
  text-align: center;
  width: fit-content;
  padding: 16px 24px;
  line-height: 20px;
}

.accueil footer a.button-primary {
  display: inline-block;
  padding: 12px 22px;
}

.accueil a.link {
  color: var(--color-primary-2);
  text-decoration: none;
  background: linear-gradient(to right, var(--color-primary-3), var(--color-primary-3)), linear-gradient(to right, var(--color-primary-2), var(--color-primary-2));
  background-size: 100% 3px, 0 3px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 400ms;
  height: 35px;
  display: inline !important;
  font-size: 18px;
  font-family: "Barlow semi Condensed";
  margin-right: 15px;
  padding-bottom: 10px;
  line-height: 35px;
}

.accueil a.link:hover {
  background-size: 0 3px, 100% 3px;
}

.accueil .quotes {
  opacity: 0.15;
  position: absolute;
  width: 140px;
  top: 0px;
}

.accueil p {
  color: var(--color-primary-2);
}

.accueil .enseignant {
  position: relative;
  background-color: var(--color-ocre) !important;
  color: var(--color-ocre-light);
  margin-top: 0px;
  background-color: transparent;
  padding-top: 100px;
  padding-bottom: 0px;
  color: #fbf4e7 !important;
}

.accueil .enseignant .ou {
  margin-right: 20px;
}

.accueil .enseignant .divider {
  width: 100%;
}

.accueil .enseignant p {
  color: #fbf4e7 !important;
}

.accueil .enseignant .titre {
  font-family: "Barlow condensed";
  font-size: 18px;
}

.accueil .enseignant .titre h3 {
  color: #fbf4e7 !important;
}

.accueil .enseignant .titre h4 {
  color: #ecd1a0 !important;
  margin-bottom: 0;
}

.accueil .enseignant .formules {
  color: #ecd1a0 !important;
  font-size: 26px;
  text-transform: none;
  font-weight: 500;
}

.accueil .enseignant a.button-primary {
  color: var(--color-primary-1) !important;
  background: var(--color-primary-4) !important;
  width: 100%;
  white-space: normal;
  line-height: 20px;
  padding: 16px 24px;
}

.accueil .enseignant a.button-outline {
  color: var(--color-primary-4) !important;
  border: 2px solid var(--color-primary-4) !important;
  font-size: 16px;
  width: 100%;
  padding: 14px 22px;
  margin-bottom: 0;
  white-space: normal;
  line-height: 20px;
}

.accueil .enseignant a.button-outline:hover {
  color: var(--color-primary-1) !important;
  background: var(--color-primary-4) !important;
  border: 2px solid var(--color-primary-4) !important;
  font-size: 16px;
  width: 100%;
}

.accueil .enseignant .conventionnement a.button-outline {
  color: #fbf4e7 !important;
  border: 2px solid #fbf4e7 !important;
  font-size: 16px;
  width: 100%;
  padding: 6px 12px;
  white-space: normal;
  line-height: 20px;
}

.accueil .enseignant .conventionnement a.button-outline:hover {
  color: var(--color-ocre-dark) !important;
  background: #fbf4e7 !important;
  border: 2px solid #fbf4e7 !important;
  font-size: 16px;
  width: 100%;
}

.accueil .enseignant .bubble.conventionnement {
  min-width: 360px;
  margin: 0;
  padding: 50px;
  text-align: center;
  font-weight: 700;
  position: relative;
  min-height: 340px;
  background: var(--color-ocre-dark);
  border-radius: 40% 60% 70% 30% / 40% 50% 60% 50%;
  overflow: hidden;
  animation: morph 25s linear infinite;
  z-index: 999;
}

.accueil .enseignant .bubble.conventionnement .titre h4 {
  color: #ecd1a0 !important;
  font-size: 26px;
  line-height: 26px;
}

.accueil .enseignant .bloc-newsletter {
  max-width: 600px;
}

.accueil .enseignant .newsletter {
  width: 60px;
  height: 55px;
  border-radius: 100%;
  text-align: center;
  padding: 10px;
}

.accueil .enseignant .newsletter i:before {
  font-size: 30px;
  color: white;
}

.accueil .enseignant .info {
  font-size: 18px;
  color: #ecd1a0 !important;
  margin-top: 20px;
  margin-bottom: 0px;
  display: inline-block;
  line-height: 20px;
  font-family: "Barlow condensed";
}

.curve-before {
  position: relative;
  top: 120px;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
  z-index: 99;
}

.curve-before svg {
  position: relative;
  display: block;
  width: calc(111% + 200px);
  height: 125px;
  left: -150px;
}

.curve-before .shape-fill {
  fill: var(--color-primary);
}

.curve-before-invert {
  position: relative;
  top: 70px;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  z-index: 99;
}

.curve-before-invert svg {
  position: relative;
  display: block;
  width: calc(111% + 200px);
  height: 125px;
  left: -150px;
}

.curve-before-invert .shape-fill {
  fill: var(--color-primary);
}

.curve-after-invert {
  position: relative;
  bottom: 70px;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
  z-index: 99;
}

.curve-after-invert svg {
  position: relative;
  display: block;
  width: calc(111% + 200px);
  height: 125px;
  left: -150px;
}

.curve-after-invert .shape-fill {
  fill: var(--color-primary);
}

.curve-after {
  position: relative;
  bottom: 120px;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  z-index: 9;
}

.curve-after svg {
  position: relative;
  display: block;
  width: calc(111% + 200px);
  height: 125px;
  left: -150px;
}

.curve-after .shape-fill {
  fill: var(--color-primary);
}

.curve-equipe {
  fill: #f0d9ae;
  bottom: 70px;
}

.curve-after.curve-equipe .shape-fill {
  fill: #f0d9ae;
  bottom: 0;
}

.curve-after.curve-actualites {
  bottom: -70px;
}

.curve-after.curve-equipe-fede {
  bottom: 0px;
}

.curve-after.curve-equipe-fede .shape-fill {
  fill: #eff6f5;
}

.curve-after-invert.curve-suggestions {
  bottom: -60px;
}

.curve-after-invert.curve-recrutement {
  bottom: -5px;
}

.curve-after-invert.curve-recrutement .shape-fill {
  fill: var(--color-primary-4);
}

.curve-after.curve-actualites .shape-fill {
  fill: var(--color-primary);
}

.curve-after.curve-enseignant {
  bottom: 0px;
}

.curve-after.curve-enseignant .shape-fill {
  fill: var(--color-ocre);
}

.curve-after-invert.curve-suggestions .shape-fill {
  fill: var(--color-ocre);
}

.clip {
  background-color: red;
  clip-path: polygon(100% 100%, 0% 100%, 0% 84.89%, 2% 85.55%, 4% 86.19%, 6% 86.8%, 8% 87.39%, 10% 87.95%, 12% 88.48%, 14% 88.99%, 16% 89.47%, 18% 89.92%, 20% 90.35%, 22% 90.75%, 24% 91.12%, 26% 91.46%, 28% 91.78%, 30% 92.06%, 32% 92.32%, 34% 92.55%, 36% 92.75%, 38% 92.92%, 40% 93.06%, 42% 93.17%, 44% 93.26%, 46% 93.31%, 48% 93.33%, 50% 93.33%, 52% 93.29%, 54% 93.23%, 56% 93.13%, 58% 93.01%, 60% 92.86%, 62% 92.67%, 64% 92.46%, 66% 92.22%, 68% 91.96%, 70% 91.66%, 72% 91.33%, 74% 90.98%, 76% 90.6%, 78% 90.19%, 80% 89.75%, 82% 89.29%, 84% 88.79%, 86% 88.28%, 88% 87.73%, 90% 87.16%, 92% 86.57%, 94% 85.95%, 96% 85.3%, 98% 84.63%, 100% 83.94%);
  width: 100%;
}

.clipp {
  background-color: #77cce9;
  clip-path: circle(80px at 50% 50%);
}

.div2 {
  margin-top: -100px;
  background: aqua;
  height: 300px;
  clip-path: url(#wave);
}

.footer {
  position: relative;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-image: url("../img/backgrounds/bg-footer.svg");
  background-position: top;
  padding-top: 160px;
  color: var(--color-ocre-light);
  margin-top: -200px;
  background-color: transparent;
  font-family: "Barlow semi condensed", sans-serif;
}

footer .container.contenu {
  background-color: var(--color-ocre);
  margin-top: 100px;
}

.footer-bas {
  position: relative;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-image: url("../img/backgrounds/bg-footer-bas.svg");
  background-position: top;
  background-color: var(--color-ocre);
  font-family: "Barlow semi condensed", sans-serif;
}

.accueil .footer-bas a.link,
.footer-bas a.link {
  margin-right: 0;
  font-size: 16px !important;
}

.footer-bas i[class*=" fi-rr-"]:before {
  font-size: 12px !important;
}

.footer li.liste-simple {
  color: var(--color-ocre-light) !important;
  list-style: none;
  position: relative;
  padding-left: 40px;
  line-height: 28px;
  font-size: 16px;
  font-weight: 600;
}

.footer li.liste-simple:before {
  font-family: uicons-regular-rounded !important;
  position: absolute;
  left: -7px;
  color: var(--color-ocre-dark) !important;
  font-size: 32px;
  content: "\f305";
}

.footer p {
  color: var(--color-ocre-light) !important;
  white-space: pre-wrap;
  line-height: 24px;
}

.footer h5 {
  font-size: 22px;
  color: var(--color-ocre-light);
  text-transform: uppercase;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  margin-bottom: 20px;
}

.footer a.button-primary {
  display: inline-block;
  white-space: normal;
  line-height: 18px;
}

.footer a:hover {
  text-decoration: none;
  cursor: pointer;
  color: var(--color-white) !important;
}

.footer a.social img {
  width: 36px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.footer a.social img:hover {
  cursor: pointer;
  filter: invert(25%) sepia(100%) saturate(591%) hue-rotate(133deg) brightness(55%) contrast(102%);
}

.mx-20 {
  margin-left: 20px;
  margin-right: 20px;
}

.p-20 {
  padding: 20px;
}

.ml-n50 {
  margin-left: -50px;
}

/******* TYPOGRAPHIES ********/
body {
  color: var(--color-text) !important;
  font-weight: 600;
  font-family: "Sen", sans-serif;
  font-size: 16px;
}

a {
  cursor: pointer;
}

.ft-primary {
  font-family: "Barlow Condensed", sans-serif;
}

.ft-secondary {
  font-family: "Jost", sans-serif;
}

.text-primary {
  color: var(--color-primary) !important;
}

p {
  color: #1b706a;
  margin: 20px 0;
  text-align: justify;
}

.from-wysiwyg,
.from-wysiwyg p,
.from-wysiwyg span,
.from-wysiwyg p span,
.from-wysiwyg strong,
.acccordion-item .from-wysiwyg {
  color: #1b706a;
  text-align: justify !important;
  font-weight: 600 !important;
  font-family: "Sen", sans-serif !important;
  font-size: 16px !important;
}

.from-wysiwyg p {
  margin: 0 !important;
}

main .informatif .from-wysiwyg > :first-child {
  margin-top: 0px !important;
}

main .informatif .from-wysiwyg p:last-child {
  margin-bottom: 30px !important;
}

.from-wysiwyg strong {
  font-weight: 800 !important;
}

.from-wysiwyg li {
  color: var(--color-primary-4);
  list-style: none;
  position: relative;
  padding-left: 40px;
  line-height: 28px;
  font-size: 16px;
  font-weight: 600;
  text-align: left;
}

.from-wysiwyg li:before {
  font-family: uicons-regular-rounded !important;
  position: absolute;
  left: -7px;
  color: var(--color-ocre-dark) !important;
  font-size: 32px;
  content: "\f305";
}

.from-wysiwyg li.ql-indent-1 {
  padding-left: 5rem;
}

.from-wysiwyg li.ql-indent-2 {
  padding-left: 8rem;
}

.from-wysiwyg li.ql-indent-1::before {
  margin-left: 1.5em !important;
  color: var(--color-primary-4) !important;
}

.from-wysiwyg li.ql-indent-2::before {
  margin-left: 3em !important;
  color: var(--color-primary-3) !important;
}

b,
strong {
  font-weight: 800;
}

h1 {
  color: var(--color-titre-light);
  font-family: "Barlow Condensed", sans-serif;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;
  text-transform: uppercase;
}

h2 {
  color: var(--color-sub);
  font-family: "Barlow Condensed", sans-serif;
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
  text-transform: uppercase;
}

h3 {
  color: var(--color-ocre-dark);
  font-family: "Barlow Condensed";
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  text-transform: uppercase;
  padding-bottom: 30px;
}

h4 {
  font-size: 18px;
  color: var(--color-primary-4) !important;
  text-transform: uppercase;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  line-height: 0.8;
}

.heading h4 {
  font-size: 34px;
  color: var(--color-primary-3) !important;
  margin-bottom: 20px;
  line-height: 34px;
  font-weight: 700;
}

.suggestions h3 {
  padding-bottom: 15px;
}

.suggestions h4 {
  font-size: 24px;
  color: var(--color-primary-3) !important;
}

.suggestions.contact {
  position: relative;
  background-size: 130% auto;
  background-repeat: no-repeat;
  background-image: url(../img/backgrounds/bg-wave-light.svg);
  background-position: center 0;
  padding-top: 100px;
  margin-top: 100px;
}

.suggestions.contact h2 {
  padding-bottom: 15px;
  color: var(--color-primary-4) !important;
}

.suggestions.contact h3 {
  font-size: 30px;
  color: var(--color-primary-4) !important;
  padding-bottom: 5px;
}

.suggestions.contact h4 {
  color: var(--color-primary-3) !important;
}

.suggestions .separateur i:before {
  vertical-align: middle;
  top: 5px;
  position: relative;
  margin-right: 12px;
  font-size: 35px;
}

.suggestions i.fi-rr-comments-question:before {
  vertical-align: middle;
  top: 0px;
  position: relative;
  margin-right: 5px;
  font-size: 100px;
}

.recrutement h3 {
  font-size: 30px;
  padding-top: 0;
}

h5 {
  color: var(--color-primary-4);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}

.numbers {
  font-family: "Barlow Condensed";
  font-style: normal;
  font-weight: 700;
}

.info {
  color: var(--color-light-1);
  font-size: 13px;
  line-height: 13px;
}

.badge.info {
  text-align: center;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  margin-top: 5px;
}

.badge-duree {
  background-color: #a4bf59;
  border-radius: 0px;
  font-size: 36px;
  padding: 2px 10px;
  margin-top: -10px;
  display: inline-block;
  line-height: 44px;
  vertical-align: middle;
  margin-left: 5px;
}

.badge-duree i[class*=" fi-bs-"]:before {
  vertical-align: middle;
  top: -4px;
  position: relative;
  font-size: 26px;
}

i[class^="fi-rr-"]:before,
i[class*=" fi-rr-"]:before,
span[class^="fi-rr-"]:before,
span[class*="fi-rr-"]:before {
  vertical-align: middle;
  top: -2px;
  position: relative;
  margin-right: 5px;
  font-size: 18px;
}

.info i[class^="fi-rr-"]:before,
.info i[class*=" fi-rr-"]:before {
  font-size: 16px;
}

i[class^="fi-ss-"]:before,
i[class*=" fi-ss-"]:before,
span[class^="fi-ss-"]:before,
span[class*="fi-ss-"]:before {
  vertical-align: middle;
  top: -2px;
  position: relative;
  font-size: 18px;
}

i[class^="fi-bs-"]:before,
i[class*=" fi-bs-"]:before,
span[class^="fi-bs-"]:before,
span[class*="fi-bs-"]:before {
  vertical-align: middle;
  top: -2px;
  position: relative;
  font-size: 18px;
}

.sejour .description ul li {
  color: var(--color-primary-4);
  list-style: none;
  position: relative;
  padding-left: 40px;
  line-height: 28px;
  font-size: 16px;
  font-weight: 600;
}

.sejour .description ul li:before {
  font-family: uicons-regular-rounded !important;
  position: absolute;
  left: -7px;
  color: var(--color-ocre-dark) !important;
  font-size: 32px;
  content: "\f305";
}

li.liste-simple {
  color: var(--color-primary-4);
  list-style: none;
  position: relative;
  padding-left: 40px;
  line-height: 28px;
  font-size: 16px;
  font-weight: 600;
}

li.liste-simple:before {
  font-family: uicons-regular-rounded !important;
  position: absolute;
  left: -7px;
  color: var(--color-ocre-dark) !important;
  font-size: 32px;
  content: "\f305";
}

.sejour .departs h5 {
  color: var(--color-primary-4);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.pill {
  width: 20px;
  height: 20px;
  text-align: center;
  font-size: 13px;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  padding: 1px;
}

/******* ELEMENTS UI TRANSVERSES ********/
.rounded-full i[class^="fi-rr-"]:before,
.rounded-full i[class*=" fi-rr-"]:before {
  font-size: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-right: 0px;
}

.i-rounded {
  width: 20px;
  height: 20px;
  border-radius: 50px;
}

.border-itinerance {
  position: absolute;
  top: 20px;
  bottom: 20px;
  left: 19px;
  z-index: auto;
  border-left: 2px dashed var(--color-primary-3) !important;
}

.suivant:hover i,
.precedent:hover i {
  color: white !important;
}

.accordion__item {
  border-radius: 0px;
  border: none;
  background: none !important;
}

.accordion.-simple .accordion__item.is-active .accordion__icon {
  background-color: transparent !important;
  color: white !important;
}

/******* MENU ********/
.header-logo img {
  max-width: 100px;
}

.header .header-menu .menu li a {
  text-transform: uppercase;
  font-size: 16px;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  color: var(--color-white) !important;
  transition: 0.3s;
}

.mega-menu.recherche {
  width: 100%;
  margin-top: 160px;
  padding: 10px 40px;
  background-color: var(--color-white) !important;
  border-radius: 0px;
  text-transform: uppercase;
  font-size: 16px;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  position: absolute;
  z-index: 999999;
}

.mega-menu.recherche.v2 {
  width: 100%;
  margin-top: 15px;
  padding: 10px 40px;
  background-color: var(--color-white) !important;
  border-radius: 0px;
  text-transform: uppercase;
  font-size: 16px;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  position: absolute;
  z-index: 99;
}

.mega-menu.recherche .mega__grid {
  display: grid;
  gap: 40px 30px;
  grid-template-columns: auto auto auto auto auto;
  justify-content: center;
}

.mega-menu.recherche.educform .mega__grid {
  grid-template-columns: auto auto auto auto;
}

.mega-menu.recherche.sejours .mega__grid {
  grid-template-columns: auto auto auto auto;
}

.mega-menu.recherche.bafa .mega__grid {
  grid-template-columns: auto auto auto auto;
}

.mega-menu.recherche.scolaires .mega__grid {
  grid-template-columns: auto auto auto auto;
}

.mega-menu.recherche .mega__grid.c3 {
  grid-template-columns: auto auto auto;
}

.mega-menu.recherche .mega__grid.c2 {
  grid-template-columns: auto auto;
}

.mega-menu.recherche .mega {
  top: calc(100% - 15px);
  background-color: var(--color-white) !important;
  border-radius: 0px;
  color: var(--color-primary) !important;
  min-width: 240px;
  padding: 25px 0;
  box-shadow: none;
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.mega-menu.recherche .mega a:not(.button-primary) {
  padding: 5px 15px;
  border-radius: 0px;
  cursor: pointer;
  background: none;
  transition: 0.3s;
  background-color: transparent !important;
  color: var(--color-primary) !important;
  line-height: 1.2;
  display: inline-block;
}

.mega-menu.recherche .mega a:not(.button-primary):hover {
  color: var(--color-primary-3) !important;
}

.mega-menu.recherche .mega .n1 a {
  padding: 10px 20px 10px 15px;
  border-radius: 0px;
  color: var(--color-ocre-dark) !important;
  cursor: pointer;
  background: none;
  transition: 0.3s;
  line-height: 1.3;
  background-color: transparent !important;
  text-transform: uppercase;
  font-size: 16px;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
}

.mega-menu.recherche .mega .n1 a:hover {
  color: var(--color-white) !important;
  cursor: pointer;
  background-color: var(--color-ocre-dark) !important;
}

.mega-menu.recherche .mega .n2 a {
  padding: 8px 15px 8px 15px;
  font-size: 15px;
}

.mega-menu.recherche .mega .n2 a:hover {
  cursor: pointer;
  background-color: var(--color-primary-2) !important;
}

.mega-menu.recherche .subnav > li.n2 a {
  padding: 5px 15px 8px 15px;
  font-size: 15px;
}

.mega-menu.recherche .mega .n3 a {
  padding: 5px 20px 5px 15px;
  font-size: 15px;
  border-radius: 0px;
  color: var(--color-primary-3) !important;
  cursor: pointer;
  background: none;
  transition: 0.3s;
  line-height: 1.3;
  background-color: transparent !important;
  text-transform: none;
}

.mega-menu.recherche .mega .n3 a:hover {
  color: var(--color-primary) !important;
}

.mega-menu.recherche .mega a.vignette.mini {
  background-color: var(--color-bloc) !important;
  padding: 0;
  width: 100%;
}

.mega-menu.recherche .mega a.vignette.mini .vignette__content {
  padding: 0 12px;
  position: relative;
  padding-bottom: 10px !important;
}

.mega-menu.recherche .mega a.vignette.mini.educform .vignette__content {
  padding: 5px 12px 5px 12px !important;
  position: relative;
}

.mega-menu.recherche .mega a.vignette.mini.educform .vignette__image {
  height: auto;
}

.mega-menu.recherche i[class*=" fi-rr-"]:before {
  vertical-align: middle;
  top: -2px;
  position: relative;
  margin-right: 5px;
  font-size: inherit;
}

.vignette.mini.-type-1 .vignette__image {
  overflow: hidden;
  border-radius: 0px;
  position: relative;
  height: 100%;
  width: 100px;
  min-width: 100px;
}

.mega-menu.recherche h4 {
  font-size: 16px;
  line-height: 14px !important;
  margin: 5px 0 15px 0;
}

.mega-menu.recherche h5 {
  font-size: 14px;
  line-height: 13px !important;
  margin: 5px 0 8px 0;
  color: var(--color-primary-3) !important;
  font-weight: 700;
}

.mega-menu.recherche ul {
  margin-bottom: 15px;
}

.mega-menu.recherche li.liste-simple {
  color: var(--color-primary-4) !important;
  list-style: none;
  position: relative;
  padding-left: 0px;
  line-height: auto;
}

.mega-menu.recherche li.liste-simple a {
  font-size: 16px;
  font-weight: 600;
  text-transform: none;
  line-height: 14px !important;
  display: block !important;
}

.mega-menu.recherche li.liste-simple:before {
  font-family: "uicons-regular-rounded" !important;
  position: absolute;
  left: -7px;
  color: var(--color-ocre-dark) !important;
  font-size: 22px;
  content: "\f305";
}

.mega-menu.recherche li.liste-simple.theme1:before {
  height: calc(100% - 5px);
  width: 10px;
  position: absolute;
  left: -7px;
  content: "";
  background: #74b7cf;
  top: 3px;
}

.mega-menu.recherche li.liste-simple.theme2:before {
  height: calc(100% - 5px);
  width: 10px;
  position: absolute;
  left: -7px;
  content: "";
  background: #a4bf59;
  top: 3px;
}

.mega-menu.recherche li.liste-simple.theme3:before {
  height: calc(100% - 5px);
  width: 10px;
  position: absolute;
  left: -7px;
  content: "";
  background: #d68d64;
  top: 3px;
}

.mega-menu.recherche .vignette.mini h4 {
  font-size: 15px;
  line-height: 14px !important;
  margin: 5px 0 5px 0;
}

.vignette.mini .date {
  font-size: 12px;
  float: right;
  bottom: 0;
  position: absolute;
  left: 0;
  background: var(--color-primary-3) !important;
  width: 100%;
  color: var(--color-white) !important;
  padding: 2px 8px;
}

.vignette.mini.bafa .date {
  background: var(--color-terracotta) !important;
  width: 100%;
  color: var(--color-white) !important;
}

.vignette.mini.educform .cardImage {
  width: 100%;
  height: auto;
}

.vignette.mini.educform .bandeau {
  top: 0;
  height: 100%;
  width: 20px;
  padding: 0px;
}

.vignette.mini.educform .bandeau.theme1 {
  color: var(--color-primary-1) !important;
  background: #44bb97 !important;
}

.vignette.mini.educform .bandeau.theme2 {
  color: var(--color-primary-1) !important;
  background: #44bb97 !important;
}

.vignette.mini.educform .bandeau.theme3 {
  color: var(--color-primary-1) !important;
  background: #44bb97 !important;
}

.mega-menu.recherche .mega a.vignette.mini.educform {
  width: 100%;
}

.vignette.mini .type {
  padding: 1px 5px;
  font-size: 12px;
  text-transform: uppercase;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  color: var(--color-primary-3) !important;
  border: 1px solid var(--color-primary-3) !important;
  border-radius: 4px;
  display: inline-block;
}

.vignette.mini.educform .type {
  display: block;
  width: fit-content;
  line-height: 12px;
}

.vignette.mini.educform .public {
  line-height: 12px;
  display: inline-block;
  margin-top: 3px;
}

.mega-menu.recherche .mega a.vignette.mini.-type-1 .vignette__image {
  height: auto;
}

.vignette.mini .type.theme1 {
  background: #74b7cf !important;
  border: 2px solid #74b7cf !important;
  color: white !important;
}

.vignette.mini .type.theme2 {
  background: #a4bf59 !important;
  border: 2px solid #a4bf59 !important;
  color: white !important;
}

.vignette.mini .type.theme3 {
  background: #d68d64 !important;
  border: 2px solid #d68d64 !important;
  color: white !important;
}

.vignette.mini.scolaire .date {
  background: #d68d64 !important;
}

.mega-menu.recherche .vignette.mini.scolaire h4 {
  font-size: 14px;
  line-height: 13px !important;
  margin: 3px 0 3px 0;
}

.vignette.mini .tag {
  min-width: 30px;
  height: 20px;
  background-color: var(--color-ocre-dark) !important;
  position: relative;
  padding: 0 5px;
  display: inline-block;
}

.vignette.mini .tag-title {
  line-height: 20px;
  color: #f0f0f0;
  font-size: 13px;
}

.vignette.mini i[class*=" fi-bs-"]:before {
  vertical-align: middle;
  top: -2px;
  position: relative;
  font-size: 12px;
}

.vignette.mini .tag-tail:before,
.vignette.mini .tag-left .tag-tail:before {
  border-right: 10px solid transparent;
  border-top: 10px solid var(--color-ocre-dark) !important;
}

.vignette.mini .tag-tail:after,
.vignette.mini .tag-left .tag-tail:after {
  border-right: 10px solid transparent;
  border-bottom: 10px solid var(--color-ocre-dark) !important;
}

.vignette.mini .cardImage__leftBadge {
  position: absolute;
  top: 10px;
  left: 0;
}

/******* RECHERCHE ********/
.mainSearch {
  border-radius: 100px;
}

.mainSearch__submit {
  width: 50px;
  height: 50px;
}

.mainSearch__submit:hover {
  box-shadow: inset 0 0 100px 100px rgba(0, 0, 0, 0.15);
}

.mainSearch h4 {
  top: 8px;
  color: var(--color-primary-3);
  font-family: "Barlow Condensed";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}

.navscroll .mainSearch {
  border-radius: 0;
}

.navscroll.recherche {
  position: fixed;
  width: 100%;
  top: 60px;
  z-index: 999;
  background: #fff;
  box-shadow: 0 2px 8px 1px rgba(0, 0, 0, 0.1);
}

.mainSearch .select__search {
  border-radius: 0;
}

.mainSearch input.select__search {
  padding-left: 20px;
  margin: 10px 10px 0 10px;
  width: calc(100% - 20px);
}

.mainSearch .select button {
  border-radius: 0px;
  border: none;
  background: none;
  padding-bottom: 0;
}

.mainSearch .select__button span {
  color: var(--color-primary-3);
  font-family: "Barlow Condensed";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  padding-top: 0;
}

.mainSearch .select__button {
  padding-left: 0;
  height: auto;
  padding-top: 0;
}

.mainSearch .libelle {
  color: var(--color-primary-3);
  font-family: "Barlow Condensed";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  padding-top: 0;
}

.mainSearch .select__options__button {
  padding: 5px;
  border-radius: 0px;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  cursor: pointer;
  background: none;
  transition: 0.3s;
  background-color: transparent !important;
  color: var(--color-primary-4) !important;
  font-size: 16px;
  line-height: 20px;
  padding: 15px 20px;
}

.mainSearch .counter {
  min-height: 30px;
}

.form-checkbox__mark {
  border: 2px solid var(--color-primary-4);
}

.form-checkbox input:checked ~ .form-checkbox__mark {
  background-color: var(--color-primary-4);
  border-color: var(--color-primary-4);
}

#sejour.mainSearch .button-grid {
  display: grid;
  grid-template-columns: 1fr 150px 200px 200px auto;
}

#bafa.mainSearch .button-grid {
  display: grid;
  grid-template-columns: 1fr 250px 250px auto;
}

#educform.mainSearch .button-grid {
  display: grid;
  grid-template-columns: 1fr 300px 200px auto;
}

#thematique.mainSearch .button-grid {
  display: grid;
  grid-template-columns: 1fr 200px 300px auto;
}

#sejour.mainSearch {
  max-width: 1000px;
}

#multi.mainSearch .button-grid {
  display: grid;
  grid-template-columns: 1fr auto;
}

#actualites.mainSearch .button-grid {
  display: grid;
  grid-template-columns: 1fr auto;
}

#resultats-filtres a .info {
  border: 2px solid #c7d4d5;
  padding: 12px 20px 7px 20px;
  border-radius: 100px;
  color: #9bb2b4;
}

#resultats-filtres a .info:hover {
  border: 2px solid #c7d4d5;
  background: #c7d4d5;
  color: #73898b;
}

.content.multi #resultats-filtres {
  transition: 0.3s ease;
  position: relative;
  margin-top: 0px;
}

.content.unitaire #resultats-filtres {
  transition: 0.3s ease;
  position: relative;
  margin-top: 0px;
}

.content.unitaire .toggle .button.masquer {
  margin-top: -43px;
}

.content.multi .toggle .button.masquer {
  margin-top: -43px;
}

.publications .content.unitaire .toggle .button.masquer {
  margin-top: 20px;
}

.resultats.actualites.publications .vignette {
  background-color: #f3f4f4;
  padding-bottom: 0;
}

.resultats.actualites.publications .vignette .vignette__content {
  padding: 0px 20px 20px 20px;
}

.resultats.actualites.publications .tarif {
  font-size: 18px;
}

#resultats-contenu {
  transition: 0.3s ease;
}

.w-0 {
  width: 0 !important;
}

/******* BOUTONS ********/
.button.masquer {
  z-index: 9;
  border-radius: 0;
  padding: 10px 15px;
  text-align: left;
  font-family: "Barlow Condensed";
  position: relative;
  position: absolute;
  font-weight: 600;
  top: 20px;
  right: 20px;
}

.recherche .container {
  position: relative;
}

.button.masquer:hover {
  box-shadow: inset 0 0 100px 100px rgba(0, 0, 0, 0.15);
}

.toggle .button.masquer {
  position: relative;
  z-index: 99;
  border-radius: 0;
  padding: 10px 15px;
  top: 0;
  width: 100%;
  right: 0;
  justify-content: start;
  font-family: "Barlow Condensed";
  font-weight: 600;
  margin-top: -110px;
}

#toggle-carte.button.masquer {
  position: relative;
  top: 0;
  right: 0;
}

a.link {
  color: inherit;
  text-decoration: none;
  background: linear-gradient(to right, var(--color-primary-3), var(--color-primary-3)), linear-gradient(to right, var(--color-primary-4), var(--color-primary-4));
  background-size: 100% 3px, 0 3px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 400ms;
  height: 30px;
  display: inline !important;
  font-size: 16px;
  margin-right: 15px;
  padding-bottom: 7px;
  line-height: 35px;
}

a.link.white {
  background: linear-gradient(to right, var(--color-white), var(--color-white)), linear-gradient(to right, var(--color-primary-4), var(--color-primary-4));
  background-size: 100% 3px, 0 3px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 400ms;
  color: var(--color-white);
}

a.link:hover {
  background-size: 0 3px, 100% 3px;
}

.from-wysiwyg a {
  color: inherit;
  text-decoration: none;
  background: linear-gradient(to right, var(--color-primary-3), var(--color-primary-3)), linear-gradient(to right, var(--color-primary-4), var(--color-primary-4));
  background-size: 100% 3px, 0 3px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 400ms;
  height: 30px;
  display: inline !important;
  font-size: 16px;
  margin-right: 0px;
  padding-bottom: 7px;
  line-height: 35px;
}

.from-wysiwyg a:hover {
  background-size: 0 3px, 100% 3px;
}

.tabs a.link {
  height: 30px;
  padding: 0;
  margin: 15px;
  font-family: "Barlow Condensed";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: var(--color-primary-3);
}

.tabs a.link.all {
  background: linear-gradient(to right, var(--color-primary-3), var(--color-primary-3)), linear-gradient(to right, var(--color-primary-4), var(--color-primary-4));
  background-size: 100% 3px, 0 3px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 400ms;
  height: 30px;
  display: inline-block !important;
  color: var(--color-primary-3);
}

a.link.all:hover {
  background-size: 0 3px, 100% 3px;
}

.tabs a.link.active {
  background: linear-gradient(to right, var(--color-ocre-dark), var(--color-ocre-dark)), linear-gradient(to right, var(--color-ocre-dark), var(--color-ocre-dark));
  background-size: 100% 3px, 0 3px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 400ms;
  height: 30px;
  display: inline-block !important;
  color: var(--color-ocre-dark) !important;
  cursor: default;
}

.accueil .tabs a.link {
  color: var(--color-primary-2);
  text-decoration: none;
  background: linear-gradient(to right, var(--color-primary-2), var(--color-primary-2)), linear-gradient(to right, var(--color-ocre), var(--color-ocre));
  background-size: 100% 3px, 0 3px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 400ms;
  height: 30px;
  display: inline-block !important;
  font-size: 17px;
  font-family: "Barlow Condensed";
  margin-right: 10px;
  font-weight: 600;
}

.accueil .tabs a.link.is-tab-el-active {
  background: linear-gradient(to right, var(--color-ocre), var(--color-ocre)), linear-gradient(to right, var(--color-ocre), var(--color-ocre));
  background-size: 100% 3px, 0 3px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 400ms;
  height: 30px;
  display: inline-block !important;
  color: var(--color-ocre) !important;
  cursor: default;
}

.accueil .tabs a.link:hover {
  color: var(--color-ocre);
  background-size: 0 3px, 100% 3px;
}

.accueil .enseignant a.link.white {
  background: linear-gradient(to right, var(--color-white), var(--color-white)), linear-gradient(to right, var(--color-ocre-dark), var(--color-ocre-dark));
  background-size: 100% 3px, 0 3px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 400ms;
  height: 35px;
  display: inline !important;
  font-size: 18px;
  font-family: "Barlow semi Condensed";
  margin-right: 15px;
  padding-bottom: 10px;
  line-height: 35px;
  color: var(--color-white);
}

.accueil .enseignant a.link.white:hover {
  background-size: 0 3px, 100% 3px;
}

.accueil .mainSearch h4 {
  margin-bottom: 0;
  color: var(--color-primary-4) !important;
}

a.button-primary {
  text-transform: uppercase;
  text-align: center;
  font-size: 16px;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  color: var(--color-white) !important;
  background-color: var(--color-primary);
  padding: 10px 20px;
  border-radius: 200px;
  transition: 0.3s;
  white-space: nowrap;
  text-align: center;
}

a.button-primary:hover {
  box-shadow: inset 0 0 100px 100px rgba(0, 0, 0, 0.15);
}

.button-primary {
  text-transform: uppercase;
  text-align: center;
  font-size: 16px;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  color: var(--color-white) !important;
  background-color: var(--color-primary);
  padding: 10px 20px;
  border-radius: 200px;
  transition: 0.3s;
  white-space: nowrap;
  text-align: center;
}

.button-primary:hover {
  box-shadow: inset 0 0 100px 100px rgba(0, 0, 0, 0.15);
}

a.button-primary.secteur {
  border-radius: 0;
  max-width: 300px;
  position: fixed;
  top: 10px;
  z-index: 999;
  line-height: 18px;
  text-align: left;
  white-space: normal;
  background-color: var(--color-ocre-dark);
}

a.button-primary.secteur.sub {
  border-radius: 0;
  max-width: 300px;
  position: fixed;
  top: 10px;
  z-index: 999;
  line-height: 18px;
  text-align: left;
  white-space: normal;
  font-size: 14px;
}

.encart a.button-primary.demande,
.encart .button-primary.demande {
  color: var(--color-white) !important;
  background-color: var(--color-ocre-dark);
}

.encart a.button-primary.devis,
.encart .button-primary.devis {
  color: var(--color-ocre-dark) !important;
  background-color: transparent;
  border: 2px solid var(--color-ocre-dark) !important;
  padding: 8px 18px;
}

.encart a.button-primary.devis:hover,
.encart .button-primary.devis:hover {
  background-color: var(--color-ocre-dark);
  border: 2px solid var(--color-ocre-dark) !important;
  color: var(--color-white) !important;
  box-shadow: none;
}

.encart a.button-primary.demandeprojet,
.encart .button-primary.demandeprojet {
  color: var(--color-primary-4) !important;
  background-color: transparent;
  border: 2px solid var(--color-primary-4) !important;
  padding: 8px 10px;
  font-size: 15px;
}

.encart a.button-primary.demandeprojet:hover,
.encart .button-primary.demandeprojet:hover {
  background-color: var(--color-primary-4);
  border: 2px solid var(--color-primary-4) !important;
  color: var(--color-white) !important;
  box-shadow: none;
}

.button-secondary {
  text-transform: uppercase;
  font-size: 14px;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  color: var(--color-white) !important;
  background-color: var(--color-secondary) !important;
  padding: 10px 15px;
  border-radius: 200px;
  transition: 0.3s;
  white-space: nowrap;
  text-align: center;
}

a.button-secondary {
  text-transform: uppercase;
  font-size: 14px;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  color: var(--color-white) !important;
  background-color: var(--color-secondary) !important;
  padding: 10px 15px;
  border-radius: 200px;
  transition: 0.3s;
  white-space: nowrap;
  text-align: center;
}

a.button-secondary:hover {
  background-color: var(--color-primary-2) !important;
  color: var(--color-secondary) !important;
}

.button-secondary:hover {
  background-color: var(--color-primary-2) !important;
  color: var(--color-secondary) !important;
}

a.button-outline {
  text-transform: uppercase;
  font-size: 14px;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  color: var(--color-primary-4) !important;
  border: 2px solid var(--color-primary-4) !important;
  padding: 10px 20px;
  border-radius: 200px;
  transition: 0.3s;
  white-space: nowrap;
  text-align: center;
  margin-bottom: 8px;
}

a.button-outline.share {
  text-transform: uppercase;
  font-size: 14px;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  color: var(--color-primary-4) !important;
  border: 2px solid var(--color-primary-4) !important;
  padding: 5px 15px 5px 20px;
  border-radius: 200px;
  transition: 0.3s;
  white-space: nowrap;
  text-align: center;
  margin-bottom: 8px;
  z-index: 999;
  max-width: 120px;
}

.button-outline {
  text-transform: uppercase;
  font-size: 14px;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  color: var(--color-primary-4) !important;
  border: 2px solid var(--color-primary-4) !important;
  padding: 10px 20px;
  border-radius: 200px;
  transition: 0.3s;
  white-space: nowrap;
  text-align: center;
  margin-bottom: 8px;
}

a.button-outline:hover {
  background-color: var(--color-primary-4) !important;
  color: var(--color-white) !important;
}

.button-outline:hover {
  background-color: var(--color-primary-4) !important;
  color: var(--color-white) !important;
}

/******* GALERIE ********/
.galleryGrid.gallery4 {
  display: grid;
  grid-template-rows: 250px 250px;
  grid-template-columns: 0.52fr 0.24fr 0.24fr;
  gap: 10px;
}

.galleryGrid.gallery4 > *:nth-child(1) {
  grid-row: 2 span;
}

.galleryGrid.gallery4 > *:nth-child(2) {
  grid-column: 2 span;
}

.galleryGrid.gallery3 {
  display: grid;
  grid-template-rows: 250px 250px;
  grid-template-columns: 0.52fr 0.52fr;
  gap: 10px;
}

.galleryGrid.gallery3 > *:nth-child(1) {
  grid-row: 2 span;
}

.galleryGrid.gallery3 > *:nth-child(2) {
  grid-column: 1 span;
}

.galleryGrid.gallery2 {
  display: grid;
  grid-template-rows: 500px;
  grid-template-columns: 0.52fr 0.52fr;
  gap: 10px;
}

.galleryGrid.gallery2 > *:nth-child(1) {
  grid-row: 1 span;
}

.galleryGrid.gallery2 > *:nth-child(2) {
  grid-column: 1 span;
}

.galleryGrid.gallery1 {
  display: grid;
  grid-template-rows: 500px;
  grid-template-columns: 1fr;
  gap: 10px;
}

.galleryGrid.gallery1 > *:nth-child(1) {
  grid-row: 1 span;
}

.galleryGrid.grid-educform,
.galleryGrid.grid-scolaireactivite {
  grid-template-rows: 350px;
  grid-template-columns: 1fr;
}

.galleryGrid.grid-educform > *:nth-child(1),
.galleryGrid.grid-scolaireactivite > *:nth-child(1) {
  grid-row: auto;
}

.galleryGrid.grid-actualite {
  grid-template-rows: 350px;
  grid-template-columns: 1fr;
}

.galleryGrid.grid-actualite > *:nth-child(1) {
  grid-row: auto;
}

.galleryGrid.grid-actualite .bandeau-actu {
  width: 100%;
  height: 40px;
  background: var(--color-primary-3);
  position: absolute;
  bottom: 0;
}

.galleryGrid.grid-educform .bandeau-theme1,
.galleryGrid.grid-educform .bandeau-theme2,
.galleryGrid.grid-scolaireactivite .bandeau-theme1,
.galleryGrid.grid-scolaireactivite .bandeau-theme2 {
  width: 100%;
  height: 40px;
  background: #74b7cf;
  position: absolute;
  bottom: 0;
}

.galleryGrid.grid-educform .bandeau-theme2,
.galleryGrid.grid-scolaireactivite .bandeau-theme2 {
  background: #a4bf59;
}

.galleryGrid.grid-educform .bandeau-theme3,
.galleryGrid.grid-scolaireactivite .bandeau-theme3 {
  background: #d68d64;
}

.galleryGrid.grid-educform .bandeau-theme1 span,
.galleryGrid.grid-educform .bandeau-theme2 span,
.galleryGrid.grid-scolaireactivite .bandeau-theme1 span,
.galleryGrid.grid-scolaireactivite .bandeau-theme2 span,
.galleryGrid.grid-actualite .bandeau-actu span {
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  color: var(--color-white) !important;
  text-transform: uppercase;
  padding: 5px 20px;
  display: inline-block;
}

h1.scolairethematique i:before {
  font-size: 46px;
  margin-top: -5px;
  display: inline-block;
}

main.actualite h2 {
  color: var(--color-sub);
  font-family: "Barlow Condensed", sans-serif;
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
  text-transform: none;
}

main.actualite h2 i:before {
  color: var(--color-sub);
  font-size: 28px;
}

main.actualite a.link {
  margin-right: 10px;
}

main.actualite .description ul li.liste-simple {
  color: var(--color-text) !important;
}

main.actualite .item-actu {
  margin-bottom: 0px;
}

main.actualite .item-actu a {
  line-height: 15px;
  display: inline-block;
}

main.actualite .item-actu .info {
  margin-left: 0px;
  line-height: 10px;
}

main.actualite .item-actu-img {
  width: 60px;
  height: 60px;
}

main.actualite .item-actu-img img {
  object-fit: cover;
  width: 60px;
  height: 60px;
}

.checkbox-wrapper.actus label {
  display: inline-block;
  color: var(--color-primary-4);
  cursor: pointer;
  position: relative;
  font-family: "Barlow Condensed";
  font-size: 17px;
  text-transform: uppercase;
  font-weight: 700;
}

/******* PAGES INFORMATIVES *******/
main .informatif p {
  margin: 15px 0 20px 0 !important;
}

main .informatif .swiper-slide p {
  margin: 0 !important;
}

main .informatif ul,
main .informatif ol {
  margin: 5px 0 20px 0 !important;
}

main .informatif section {
  padding: 40px 0 !important;
}

main .informatif section.telechargements {
  padding: 20px 0 !important;
}

main .informatif section.texte {
  padding: 40px 0 !important;
  padding-top: 20px !important;
}

main .informatif section.image img {
  width: 100%;
}

main .informatif section.equipe {
  padding: 0 !important;
}

main .informatif section.equipe .bubble.team {
  margin: 0 auto;
}

main .informatif section.equipe .bubble.team.photo {
  margin: 0 auto;
  position: absolute;
  background-color: transparent !important;
}

main .informatif section.equipe .child:hover .bubble.team {
  cursor: pointer;
}

main .informatif section.equipe .child:hover .bubble.team.photo {
  background-color: transparent !important;
  opacity: 1;
  cursor: default;
}

main .informatif section.equipe .child.tous.active .bubble.team {
  background-color: #015f59 !important;
}

main .informatif section.equipe .child.active .bubble.team.photo {
  background-color: #015f59 !important;
  opacity: 0.3;
}

main .informatif section.equipe .child.withhover:hover .bubble.team.photo {
  cursor: pointer;
  background-color: #015f59 !important;
  opacity: 0.3;
}

main .informatif section.equipe .child.withhover:hover .bubble.team,
main .informatif section.equipe .child.withhover.active .bubble.team {
  background-color: #015f59 !important;
  opacity: 0.85;
}

main .informatif section.equipe .child:hover .nom,
main .informatif section.equipe .child:hover .info {
  color: var(--color-primary-3);
  cursor: default;
}

main .informatif section.equipe .child.withhover:hover .nom,
main .informatif section.equipe .child.withhover:hover .info {
  color: var(--color-primary-4);
  cursor: pointer;
  transition: 0.3s;
}

main .informatif section.equipe .child.active .nom:hover,
main .informatif section.equipe .child.active .info:hover,
main .informatif section.equipe .child.active .bubble.team:hover {
  color: var(--color-primary-4);
  cursor: default;
}

main .informatif section.equipe .child .nom {
  line-height: 24px;
  margin-top: 16px;
  transition: 0.3s;
}

main .informatif section.equipe .child.tous.active .nom {
  color: var(--color-primary-4);
  line-height: 24px;
  margin-top: 16px;
}

section.aroevens.equipe.team .swiper-slide a[href="#"] {
  cursor: default !important;
}

section.aroevens.equipe.team .bubble.team {
  margin: 0 auto;
  transition: 0.3s;
}

section.aroevens.equipe.team .bubble.team.photo {
  margin: 0 auto;
  position: absolute;
  background-color: transparent !important;
}

section.aroevens.equipe.team .child.active .bubble.team {
  background-color: #015f59 !important;
  opacity: 0.85;
}

section.aroevens.equipe.team .child.withhover:hover .bubble.team {
  background-color: #015f59 !important;
  opacity: 0.85;
}

section.aroevens.equipe.team .child.active .bubble.team.photo {
  background-color: #015f59 !important;
  opacity: 0.3;
}

section.aroevens.equipe.team .child.withhover:hover .bubble.team.photo {
  background-color: #015f59 !important;
  opacity: 0.3;
}

section.aroevens.equipe.team .child:hover .nom,
section.aroevens.equipe.team .child:hover .info {
  color: var(--color-ocre);
  cursor: default;
}

section.aroevens.equipe.team .child.active .nom:hover,
section.aroevens.equipe.team .child.active .info:hover,
section.aroevens.equipe.team .child.active .bubble.team:hover {
  color: var(--color-primary-4);
  cursor: default;
}

section.aroevens.equipe.team .child.withhover:hover .nom,
section.aroevens.equipe.team .child.withhover:hover .info {
  color: var(--color-primary-4);
  cursor: pointer;
  transition: 0.3s;
}

section.aroevens.equipe.team .child .nom {
  line-height: 24px;
  margin-top: 16px;
}

section.aroevens.equipe.team .child.tous.active .nom {
  color: var(--color-primary-4);
  line-height: 24px;
  margin-top: 16px;
}

main .informatif section.equipe .child:hover .bubble.team {
  cursor: default;
}

main .informatif section.equipe .child.tous:hover .bubble.team {
  cursor: pointer;
}

main .informatif section.equipe .child.withhover:hover .bubble.team {
  cursor: pointer;
}

main .informatif > section:last-of-type {
  padding-bottom: 100px !important;
}

.informatif .divider {
  margin: 30px 0;
}

.informatif .blob {
  background: var(--color-primary-4);
  border-radius: 50% 60% 70% 40% / 50% 50% 60% 50%;
  overflow: hidden;
  animation: none;
  z-index: 999;
  top: 40px;
  aspect-ratio: 9 / 8;
  max-width: 500px;
  width: auto;
  height: auto;
  min-width: 420px;
}

section.content.informatif {
  z-index: 9;
  position: relative;
}

.informatif .parent {
  display: flex;
  gap: 40px;
}

.informatif .telechargements .child {
  flex: 1 0 41%;
}

.informatif .child img,
.informatif .child video {
  object-fit: cover;
  width: 100%;
}

.informatif .child .social img {
  object-fit: cover;
  width: auto;
}

.informatif .texte-galerie .blob img,
.informatif .texte-galerie .blob video {
  object-fit: cover;
  width: 100%;
  aspect-ratio: 1/1;
  transform: scale(1.1);
  transition: -webkit-transform 300ms ease;
  transition: transform 300ms ease;
  transition: transform 300ms ease, -webkit-transform 300ms ease;
}

.informatif .texte-galerie .blob img:hover,
.informatif .texte-galerie .blob video:hover {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}

.informatif .texte-galerie .parent.carre .blob {
  max-width: 300px;
  background: none;
  border-radius: 0;
}

.informatif .texte-galerie .parent.carre .blob img,
.informatif .texte-galerie .parent.carre .blob video {
  object-fit: contain;
  width: 100%;
  aspect-ratio: auto;
  transform: none;
}

.informatif .texte-galerie .blob img a,
.informatif .texte-galerie .blob video a {
  height: auto;
  display: block;
  overflow: hidden;
  margin: auto;
}

main .informatif section.equipe .team img {
  width: 100px;
  bottom: -15px;
  position: relative;
}

main .informatif section.equipe .team.photo img {
  bottom: auto;
  top: -10px;
  position: relative;
  -o-object-fit: cover;
  object-fit: cover;
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  min-width: 150px;
}

main .informatif section.equipe .child.tous .team img {
  width: 100px;
  bottom: initial;
  position: relative;
}

.informatif .wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  position: relative;
  height: 100%;
}

.informatif .texte table.table-4 th:first-child,
.informatif .texte table.table-4 th:last-child {
  border-radius: 0;
}

.informatif .texte-galerie .parent:has(.child) {
  gap: 40px;
  justify-content: space-evenly;
}

.informatif.contact .texte-galerie .parent:has(.child) {
  gap: 20px;
  justify-content: start;
}

.informatif .texte-galerie .parent:has(.child.tiny) {
  gap: 40px;
  justify-content: space-evenly;
}

.informatif .texte-galerie .from-wysiwyg {
  margin-top: 50px;
}

.informatif .texte-galerie .child {
  flex-basis: 100%;
  flex: 1;
  min-width: 185px;
  max-width: 400px;
}

.informatif.contact .texte-galerie .parent .child {
  max-width: 180px;
  min-width: 180px;
  margin-bottom: 0;
}

.informatif .texte-galerie .child.tiny {
  flex: 0 0 185px;
}

.informatif .texte-galerie .wrap {
  height: auto;
  aspect-ratio: 1 / 1;
}

.informatif .texte-galerie .blob {
  height: 100%;
  width: 100%;
  min-width: initial;
}

.informatif h2 {
  color: var(--color-primary-4) !important;
  line-height: 48px;
  margin-bottom: 40px;
}

.informatif h3 {
  color: var(--color-ocre-dark) !important;
  padding-bottom: 5px;
  margin-bottom: 30px;
}

.informatif h4 {
  color: var(--color-primary-3) !important;
  padding: 5px 0;
  font-size: 30px;
  line-height: 28px;
  text-align: left;
  margin: 15px 0;
}

.informatif .from-wysiwyg h5 {
  background: #81b1ac !important;
  text-transform: uppercase;
  font-family: "Barlow condensed", sans-serif;
  font-weight: 700;
  font-size: 18px;
  color: white;
  display: inline-block;
  padding: 5px 15px;
  margin: 20px 0 15px 0;
  text-align: left;
}

.informatif .from-wysiwyg br {
  content: "";
  margin: 4em;
  display: block;
  font-size: 24%;
}

.informatif .link-externe h5 {
  text-transform: uppercase;
  font-family: "Barlow condensed", sans-serif;
  font-weight: 700;
}

.informatif .footer h5 {
  color: var(--color-ocre-light) !important;
}

.informatif .telechargements h5 {
  color: var(--color-primary-4) !important;
  text-transform: uppercase;
  font-family: "Barlow condensed", sans-serif;
  font-weight: 700;
  font-size: 24px;
}

.telechargements h5 a.link {
  text-transform: none !important;
}

.informatif .telechargements .parent {
  gap: 20px;
}

.informatif a.button-primary {
  padding: 10px 40px;
  margin-right: 10px;
}

.informatif a.button-outline {
  padding: 10px 40px;
  margin-right: 10px;
}

.informatif .texte-punchline h2 {
  color: var(--color-primary-4) !important;
  line-height: 40px;
}

.informatif .accordion-button {
  padding-left: 0;
  line-height: 25px;
}

.informatif .accordion-button:not(.collapsed) {
  color: var(--color-primary-4) !important;
  background-color: transparent;
  box-shadow: none;
}

.informatif .accordion-body {
  padding: 1rem 1.25rem;
  padding-left: 0;
  padding-top: 0;
}

.informatif .texte-carousel a.button-outline {
  padding: 10px 20px;
}

.bloc-login .container {
  max-width: 560px;
}

.login {
  margin-top: -200px;
  padding-top: 200px;
}

.enseignant .login {
  margin-top: -200px;
  padding-top: 150px;
}

.login .form-input label {
  top: 32px;
}

.enseignant .heading .curve {
  z-index: 99;
}

.enseignant-image {
  background-size: cover;
  background-position: center center;
  min-height: calc(100vh - 100px);
}

.confirmation-image {
  background-size: cover;
  background-position: center center;
  min-height: 1150px;
}

.enseignant .accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 20px 0px 10px;
  font-size: 22px;
  color: var(--color-primary-4) !important;
  text-transform: uppercase;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
}

.login .footer {
  margin-top: -400px;
}

a.link-enseignant {
  margin-right: 10px;
  text-transform: none;
  color: var(--color-primary-4) !important;
  font-size: 17px;
  margin: 5px 0;
  padding: 15px 20px 15px 30px !important;
  background: #dfe8e6 !important;
  height: auto;
  display: inline-block;
  width: 100%;
}

a.link-enseignant h5 {
  color: var(--color-primary-4) !important;
  text-transform: uppercase;
  color: inherit;
  text-decoration: none;
  background: linear-gradient(to right, var(--color-primary-3), var(--color-primary-3)), linear-gradient(to right, var(--color-primary-4), var(--color-primary-4));
  background-size: 100% 3px, 0 3px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 400ms;
  min-height: 30px;
  display: inline !important;
  line-height: 30px;
  padding-bottom: 7px;
  font-weight: 700;
}

a.link-enseignant:hover h5 {
  background-size: 0 3px, 100% 3px;
}

a.link-enseignant:hover {
  background: #d1dfdd !important;
}

a.link-enseignant i:before,
.enseignant .steps i[class*=" fi-ss-"]:before {
  vertical-align: middle;
  margin-top: -4px;
  position: relative;
  font-size: 26px !important;
  display: inline-block;
}

a.link.voirtout {
  display: inline-block !important;
  font-size: 15px;
  font-family: "Barlow semi Condensed";
}

.container.sous-pages {
  padding-top: 50px;
}

.container.sous-pages .colonnes-2 {
  -webkit-column-count: 2;
  /* Chrome/Opera, Safari */
  -moz-column-count: 2;
  /* Mozilla Firefox */
  column-count: 2;
}

.sous-pages a.link,
.sous-pages h5 {
  margin-right: 10px;
  text-transform: none;
  color: var(--color-primary-4) !important;
  font-size: 17px;
}

.sous-pages .link-externe {
  color: var(--color-primary-4) !important;
  margin: 0 0 10px 0;
  padding: 15px 20px 15px 30px !important;
}

.sous-pages .bg-bloc.n1 {
  color: var(--color-ocre-dark) !important;
  background: #f6f2e8 !important;
}

.sous-pages .bg-bloc:hover {
  background: #e5eeed !important;
}

.sous-pages .bg-bloc.n1:hover {
  background: #f8eed3 !important;
}

.sous-pages .bg-bloc.n1 a.link {
  color: var(--color-ocre-dark) !important;
  text-transform: uppercase;
  color: inherit;
  text-decoration: none;
  background: linear-gradient(to right, var(--color-ocre), var(--color-ocre)), linear-gradient(to right, var(--color-ocre-dark), var(--color-ocre-dark));
  background-size: 100% 3px, 0 3px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 400ms;
  min-height: 30px;
  display: inline !important;
  line-height: 30px;
  padding-bottom: 7px;
}

.sous-pages .bg-bloc.n1 a.link:hover {
  background-size: 0 3px, 100% 3px;
}

.sous-pages .bg-bloc h5 {
  color: var(--color-primary-4) !important;
  text-transform: uppercase;
  color: inherit;
  text-decoration: none;
  background: linear-gradient(to right, var(--color-primary-3), var(--color-primary-3)), linear-gradient(to right, var(--color-primary-4), var(--color-primary-4));
  background-size: 100% 3px, 0 3px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 400ms;
  min-height: 30px;
  display: inline !important;
  line-height: 30px;
  padding-bottom: 7px;
}

.sous-pages .bg-bloc.n1 h5 {
  color: var(--color-ocre-dark) !important;
  text-transform: uppercase;
  color: inherit;
  text-decoration: none;
  background: linear-gradient(to right, var(--color-ocre), var(--color-ocre)), linear-gradient(to right, var(--color-ocre-dark), var(--color-ocre-dark));
  background-size: 100% 3px, 0 3px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 400ms;
  min-height: 30px;
  display: inline !important;
  line-height: 30px;
  padding-bottom: 7px;
}

.sous-pages .bg-bloc:hover h5 {
  background-size: 0 3px, 100% 3px;
}

.sous-pages ul {
  margin-left: 40px;
  margin-bottom: 20px;
}

.sous-pages .liste-simple {
  color: var(--color-primary-3) !important;
  text-decoration: none;
  background-size: 100% 3px, 0 3px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 400ms;
  height: auto;
  display: block !important;
  line-height: 30px;
  font-family: "Barlow Condensed" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  margin-right: 20px;
}

.sous-pages li.liste-simple:before {
  font-family: uicons-regular-rounded !important;
  position: absolute;
  left: -7px;
  color: var(--color-primary-3) !important;
  font-size: 32px;
  content: "\f305";
  top: 5px;
}

.sous-pages .liste-simple a.link {
  color: var(--color-primary-3) !important;
  font-size: 15px;
}

.img-actu-presse {
  margin: 30px 0;
  position: relative;
}

.img-actu-presse .source {
  padding: 12px 20px;
  width: 100%;
  position: absolute;
  bottom: 0;
  color: var(--color-primary-2) !important;
  background: rgba(27, 112, 106, 0.6) !important;
  font-size: 14px;
  line-height: 16px;
}

.img-actu-presse img {
  object-fit: cover;
  width: 100%;
  transition: -webkit-transform 300ms ease;
  transition: transform 300ms ease;
  transition: transform 300ms ease, -webkit-transform 300ms ease;
}

.img-actu-presse img:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.img-actu-presse a {
  height: auto;
  display: block;
  overflow: hidden;
  margin: auto;
}

.informatif .texte-colonne .parent .child {
  min-width: 300px;
  flex: 1;
}

.informatif .texte-image .parent {
  align-items: center;
  gap: 80px;
}

/******* FORMULAIRES ********/
.formulaire {
  background: var(--color-bloc);
  padding: 40px;
}

.form-control:focus {
  color: var(--color-primary-4);
  background-color: inherit;
  border-color: var(--color-primary-4);
  outline: 0;
  box-shadow: none;
}

.form-input input,
.form-input textarea {
  background: #e3e8e7;
  border-radius: 0;
  border: none;
  color: var(--color-primary-4);
  font-weight: 700;
  min-height: 60px;
  font-family: "Barlow Condensed" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  border: 2px solid #e3e8e7 !important;
}

.form-input textarea label {
  top: 0;
}

.form-input label {
  top: 30px;
}

.form-input label {
  font-family: "Barlow Condensed" !important;
  font-style: normal;
  font-weight: 600;
  color: #93abad;
  font-size: 18px;
  line-height: 16px;
}

.formulaire .form-input label,
.informatif .form-input label {
  top: 22px;
}

.formulaire .obligatoire {
  color: var(--color-terracotta) !important;
}

.form-input.date label {
  top: 20px;
}

.form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
  color: var(--color-primary-4);
  padding-top: 14px;
  padding-left: 20px;
  font-size: 16px;
}

input[type="file"]::file-selector-button {
  font-weight: bold;
  color: var(--color-primary-4);
  padding: 0.5em;
  border: none;
  font-size: 16px;
  background: var(--color-bloc);
  background-color: var(--color-bloc);
  border-radius: 0;
}

input[type="file"]::file-selector-button:hover {
  background: var(--color-bloc);
  background-color: var(--color-bloc);
}

.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

input[type="text"]:disabled {
  background: #edf1f0;
}

input[type="text"]:disabled label {
  color: #bfccd1 !important;
}

.form-input:has(> input[type="text"]:disabled) label {
  color: #bfccd1 !important;
}

.form-input input[type="text"]:disabled {
  border-color: #edf1f0 !important;
}

.processus-reservation .steps .select.small button:disabled {
  background: #edf1f0;
}

.processus-reservation .steps .select.small button:disabled span,
.processus-reservation .steps .select.small button:disabled i {
  color: #bfccd1 !important;
}

.processus-reservation .mainSearch__submit:hover {
  box-shadow: none;
}

.form-input textarea:focus,
.form-input input:focus {
  border: 2px solid var(--color-primary-4) !important;
}

.processus-reservation label {
  font-family: "Barlow Condensed" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 16px;
}

img.logos-cb {
  max-width: 300px;
  height: auto;
  margin: 10px 0 20px 0px;
}

.montant a .condition {
  background: #d9e2e1 !important;
  min-width: 200px;
}

.montant a .condition.active {
  background: var(--color-primary-3) !important;
  color: #d9e2e1 !important;
}

.montant a .condition.active h5,
.montant a .condition.active .text-primary {
  color: #fff !important;
}

.recrutement label {
  font-family: "Barlow Condensed" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 16px;
  margin-bottom: 8px !important;
}

.form-switch {
  font-family: "Barlow Condensed" !important;
  font-style: normal;
  font-weight: 600;
  color: var(--color-primary-4) !important;
  font-size: 18px;
}

.recherche input {
  color: var(--color-primary-3);
  font-family: "Barlow Condensed";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  padding-left: 0;
}

.mainSearch input {
  color: var(--color-primary-3);
  font-family: "Barlow Condensed";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  padding-left: 0;
}

.recherche input::placeholder {
  font-family: "Barlow Condensed";
  font-style: normal;
  font-weight: 600;
  color: #bbb;
  font-size: 16px;
  line-height: 16px;
}

.accueil input::placeholder {
  font-family: "Barlow Condensed";
  font-style: normal;
  font-weight: 600;
  color: #bbb;
  font-size: 16px;
  line-height: 16px;
}

.form-input textarea::placeholder {
  font-family: "Barlow Condensed";
  font-style: normal;
  font-weight: 600;
  color: #93abad !important;
  font-size: 18px;
  line-height: 16px;
  padding-top: 5px;
}

.form-input textarea {
  padding-top: 25px;
}

.accueil .enseignant .mainSearch .button-grid {
  display: grid;
  grid-template-columns: 1fr auto;
}

.accueil .enseignant input:not([type="range"]),
textarea {
  border: 0;
  outline: none;
  width: 100%;
  background-color: transparent;
  padding-left: 0;
}

.select__dropdown {
  position: absolute;
  top: calc(100% + 0px);
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
  display: flex;
  flex-direction: column;
  border-radius: 0;
  background-color: white;
  width: 100%;
  min-height: 50px;
  max-height: 300px;
  box-shadow: none;
  border: none;
  transition: all 0.2s ease;
  pointer-events: none;
  opacity: 0;
}

.select-sub {
  font-size: 16px;
  font-weight: 600;
  color: var(--color-primary-3);
  text-align: end;
  line-height: 18px;
}

.select__dropdown {
  padding: 0;
}

.select__options {
  overflow-y: auto;
  padding: 0;
  width: 100%;
}

.mainSearch .select__options {
  padding: 10px 0 0 0;
}

.recherche .select__dropdown {
  width: calc(100% + 40px);
}

.js-rayon-rangeSlider {
  padding: 0 20px;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 600;
  margin-bottom: 20px;
}

.slider-geo {
  padding: 10px 20px 20px 20px;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 600;
  line-height: 16px;
}

.select__options__button {
  padding: 5px;
  border-radius: 0px;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  cursor: pointer;
  background: none;
  transition: 0.3s;
  background-color: transparent !important;
  color: var(--color-primary-4) !important;
  font-size: 17px;
  line-height: 15px;
}

.select__options__button {
  line-height: 20px;
  padding: 15px 20px;
  font-size: 17px;
  line-height: 15px;
}

.select__options__button:hover {
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  cursor: pointer;
  background: none;
  transition: 0.3s;
  background-color: var(--color-primary-1) !important;
  color: var(--color-primary-4) !important;
}

.select.libelle label {
  display: inline-block;
  position: absolute;
  top: 8px;
  color: var(--color-primary-3);
  font-family: "Barlow Condensed";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}

.sidebar__item:not(.-no-border) {
  border: none;
}

.select.libelle .js-button-title {
  color: var(--color-primary-4);
  font-family: "Barlow Condensed";
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  padding-top: 20px;
  line-height: 15px;
}

.mainSearch .select.libelle .js-button-title {
  color: var(--color-primary-4);
  font-family: "Barlow Condensed";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  padding-top: 0px;
  line-height: 15px;
}

.select:not(.libelle) .js-button-title {
  color: var(--color-primary-4);
  font-family: "Barlow Condensed";
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  padding-top: 0px;
  line-height: 15px;
}

.select button {
  border-radius: 0px;
  border: none;
  background: #e3e8e7;
  padding: 5px 15px 5px 15px;
}

.tri {
  padding-left: 5px;
  padding-right: 5px;
}

.select.mini .select__button {
  height: 42px;
}

.select.mini .select__dropdown {
  padding: 0;
}

.select.mini .select__options {
  width: 100%;
}

.select.mini .select__options__button {
  font-size: 15px;
  padding: 5px 15px;
  line-height: 30px;
}

.select.mini .select__options__button:hover {
  background-color: var(--color-primary-1) !important;
}

.select.mini .select__button span {
  color: var(--color-primary-3);
  font-family: "Barlow Condensed";
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  padding-top: 0;
  margin-right: 15px;
}

.filtres .sidebar {
  padding: 25px;
  position: relative;
}

.filtres .sidebar .button.masquer {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
}

.sidebar.filtres a.link {
  display: inline-block !important;
  font-size: 15px;
  font-family: "Barlow semi Condensed";
}

.filtres .select button {
  padding: 12px 18px;
  height: auto !important;
}

.checkbox-wrapper .checkbox {
  display: table-cell;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  text-align: center;
}

.processus-reservation.publications .checkbox-wrapper label span {
  float: left;
  margin-top: 3px;
}

.processus-reservation.publications .accordion-flush .accordion-item {
  background: none;
}

.processus-reservation.publications .accordion-flush .accordion-item .accordion-body {
  padding: 0;
}

.processus-reservation.publications .accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
  text-transform: none;
  font-size: 20px;
  background: none;
  padding: 10px 0px;
}

.processus-reservation.publications .select.mini .select__button {
  height: 32px;
  padding: 5px 10px;
  margin-right: 5px;
  width: 68px;
}

.processus-reservation.publications .checkbox-wrapper label p {
  vertical-align: middle;
  color: var(--color-primary-4);
  margin-top: -5px;
  display: inline;
}

.checkbox-wrapper label {
  display: inline-block;
  color: var(--color-primary-4);
  cursor: pointer;
  position: relative;
  font-family: "Barlow Condensed";
  font-size: 16px;
}

.checkbox-wrapper label.important {
  color: var(--color-ocre-dark);
  font-size: 16px;
}

.checkbox-wrapper label span {
  display: inline-block;
  position: relative;
  background-color: transparent;
  width: 20px;
  height: 20px;
  transform-origin: center;
  border: 2px solid var(--color-primary-4);
  border-radius: 0;
  vertical-align: middle;
  margin-right: 10px;
  transition: background-color 150ms 200ms, transform 350ms cubic-bezier(0.78, -1.22, 0.17, 1.89);
  top: -2px;
}

.checkbox-wrapper label span:before {
  content: "";
  width: 0px;
  height: 2px;
  border-radius: 2px;
  background: var(--color-primary-4);
  position: absolute;
  transform: rotate(45deg);
  top: 9px;
  left: 5px;
  transition: width 50ms ease 50ms;
  transform-origin: 0% 0%;
}

.checkbox-wrapper label span:after {
  content: "";
  width: 0;
  height: 2px;
  border-radius: 2px;
  background: var(--color-primary-4);
  position: absolute;
  transform: rotate(305deg);
  top: 12px;
  left: 6px;
  transition: width 50ms ease;
  transform-origin: 0% 0%;
}

.checkbox-wrapper label:hover span:before {
  width: 5px;
  transition: width 100ms ease;
}

.checkbox-wrapper label:hover span:after {
  width: 10px;
  transition: width 150ms ease 100ms;
}

.checkbox-wrapper label.important span {
  display: inline-block;
  position: relative;
  background-color: transparent;
  width: 20px;
  height: 20px;
  transform-origin: center;
  border: 2px solid var(--color-ocre-dark);
  border-radius: 0;
  vertical-align: middle;
  margin-right: 10px;
  transition: background-color 150ms 200ms, transform 350ms cubic-bezier(0.78, -1.22, 0.17, 1.89);
  top: -2px;
}

.checkbox-wrapper label.important span:before {
  content: "";
  width: 0px;
  height: 2px;
  border-radius: 2px;
  background: var(--color-ocre-dark);
  position: absolute;
  transform: rotate(45deg);
  top: 9px;
  left: 5px;
  transition: width 50ms ease 50ms;
  transform-origin: 0% 0%;
}

.checkbox-wrapper label.important span:after {
  content: "";
  width: 0;
  height: 2px;
  border-radius: 2px;
  background: var(--color-ocre-dark);
  position: absolute;
  transform: rotate(305deg);
  top: 12px;
  left: 6px;
  transition: width 50ms ease;
  transform-origin: 0% 0%;
}

.sidebar.filtres .checkbox-wrapper {
  margin-bottom: 5px;
}

.sidebar.filtres .checkbox-wrapper label {
  line-height: 1.2;
  padding-right: 15px;
}

.checkbox-wrapper input[type="checkbox"] {
  display: none;
}

.checkbox-wrapper input[type="checkbox"]:checked + label span {
  background-color: var(--color-primary-4);
  transform: scale(1.1);
}

.checkbox-wrapper input[type="checkbox"]:checked + label.important span {
  background-color: var(--color-ocre-dark);
  transform: scale(1.1);
}

.checkbox-wrapper input[type="checkbox"]:checked + label span:after {
  width: 10px;
  background: #fff;
  transition: width 150ms ease 100ms;
}

.checkbox-wrapper input[type="checkbox"]:checked + label span:before {
  width: 5px;
  background: #fff;
  transition: width 150ms ease 100ms;
}

.checkbox-wrapper input[type="checkbox"]:checked + label:hover span {
  background-color: var(--color-primary-4);
  transform: scale(1.1);
}

.checkbox-wrapper input[type="checkbox"]:checked + label.important:hover span {
  background-color: var(--color-ocre-dark);
  transform: scale(1.1);
}

.checkbox-wrapper input[type="checkbox"]:checked + label:hover span:after {
  width: 10px;
  background: #fff;
  transition: width 150ms ease 100ms;
}

.checkbox-wrapper input[type="checkbox"]:checked + label:hover span:before {
  width: 5px;
  background: #fff;
  transition: width 150ms ease 100ms;
}

.sidebar-checkbox a.link {
  color: var(--color-primary-3);
  text-decoration: none;
  background: linear-gradient(to right, var(--color-primary-3), var(--color-primary-3)), linear-gradient(to right, var(--color-primary-4), var(--color-primary-4));
  background-size: 100% 3px, 0 3px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 400ms;
  height: 32px;
  display: inline-block !important;
  line-height: 32px;
  margin-top: 5px;
  font-size: 15px;
  font-family: "Barlow semi Condensed";
}

.sidebar-checkbox a.link:hover {
  background-size: 0 3px, 100% 3px;
}

.form-checkbox__mark {
  border-radius: 0;
}

.radio-wrapper .radio {
  display: table-cell;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  text-align: center;
}

.radio-wrapper label {
  display: inline-block;
  color: var(--color-primary-4);
  cursor: pointer;
  position: relative;
  font-family: "Barlow Condensed";
  font-size: 16px;
}

.radio-wrapper label span {
  display: inline-block;
  position: relative;
  background-color: transparent;
  width: 20px;
  height: 20px;
  transform-origin: center;
  border: 2px solid var(--color-primary-4);
  border-radius: 50px;
  vertical-align: middle;
  margin-right: 10px;
  transition: background-color 150ms 200ms, transform 350ms cubic-bezier(0.78, -1.22, 0.17, 1.89);
  top: -2px;
}

.radio-wrapper label span:after {
  content: "";
  width: 0;
  height: 2px;
  border-radius: 10px;
  background: var(--color-primary-4);
  position: absolute;
  transform: rotate(305deg);
  top: 9px;
  left: 2px;
  transition: width 50ms ease;
  transform-origin: 0% 0%;
}

.radio-wrapper label:hover span:before {
  width: 9px;
  height: 9px;
  border-radius: 10px;
  transition: width 100ms ease;
}

.radio-wrapper label:hover span:after {
  width: 9px;
  height: 9px;
  border-radius: 10px;
  transition: width 150ms ease 100ms;
}

.radio-wrapper input[type="radio"] {
  display: none;
}

.radio-wrapper input[type="radio"]:checked + label span {
  background-color: var(--color-primary-4);
  transform: scale(1.1);
}

.radio-wrapper input[type="radio"]:checked + label span:after {
  width: 9px;
  background: #fff;
  transition: width 150ms ease 100ms;
  height: 9px;
}

.radio-wrapper input[type="radio"]:checked + label span:before {
  width: 5px;
  background: #fff;
  transition: width 150ms ease 100ms;
}

.sidebar__item:not(.-no-border) {
  border: none;
}

.noUi-handle {
  border-radius: 100%;
  width: 20px !important;
  height: 20px !important;
  top: calc(50% + 2px) !important;
  transform: translateY(-50%);
  border: 2px solid var(--color-primary);
  box-shadow: none;
  background: var(--color-primary);
  cursor: pointer;
}

.noUi-target {
  background: #e3e8e7;
  border-radius: 4px;
  border: none;
  box-shadow: none;
  outline: none;
  margin-right: 10px;
}

.noUi-connect {
  background-color: var(--color-primary) !important;
  box-shadow: none !important;
}

.form-switch {
  padding-left: 0;
}

.form-switch .switch input {
  cursor: pointer;
}

.form-switch .switch__slider {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: none;
  background-color: rgba(255, 255, 255, 0.2);
  transition: 0.3s;
  border-radius: 30px;
  border: 2px solid var(--color-primary);
}

.form-switch .switch__slider::before {
  position: absolute;
  cursor: pointer;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 3px;
  background-color: var(--color-primary);
  border-radius: 50%;
  transition: 0.3s;
}

.form-switch .switch input:checked + .switch__slider {
  background-color: var(--color-primary);
}

.form-switch .switch {
  position: relative;
  min-width: 50px;
  height: 30px;
  cursor: pointer;
}

/******* CONTENUS ********/
.bubble {
  width: 150px;
  height: 160px;
  font-size: 17px;
  color: var(--color-white) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-transform: uppercase;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
}

.bubble i[class^="fi-rr-"]:before,
.bubble i[class*=" fi-rr-"]:before {
  vertical-align: middle;
  top: -2px;
  position: relative;
  margin-right: 5px;
  font-size: 32px;
}

.bafad .bubble {
  width: 180px;
  height: 160px;
  font-size: 17px;
  color: var(--color-white) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-transform: uppercase;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
}

.bafad .departs h5 {
  font-size: 16px;
}

.bubble.geo {
  padding: 15px 20px 20px 20px;
  text-align: center;
  line-height: 18px;
}

.bubble.geo i {
  margin-bottom: 10px;
  display: inline-block;
}

.bubble.type {
  padding: 15px 20px 20px 20px;
  text-align: center;
  line-height: 18px;
}

.bubble.type i {
  margin-bottom: 10px;
  display: inline-block;
}

.bubble.team {
  background-image: url("../img/contenus/bubble-team.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.bubble.age {
  background-image: url("../img/contenus/bubble-age.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.bubble.type {
  background-image: url("../img/contenus/bubble-type.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.bubble.geo {
  background-image: url("../img/contenus/bubble-geo.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.bubble.thematiquescolaire {
  background-image: url("../img/contenus/bubble-geo.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.bubble.ssthematiquescolaire {
  background-image: url("../img/contenus/bubble-ssthematiquescolaire.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.bubble.hebergement {
  background-image: url("../img/contenus/bubble-hebergement.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.bubble .badge-thematique {
  border-radius: 100px;
  background: rgba(255, 255, 255, 0.3);
  color: white;
  padding: 8px 9px;
  display: inline-block;
  margin: 2px;
  margin-top: 6px;
}

.bubble .badge-thematique i {
  margin-bottom: 0px;
  display: inline-block;
}

.bubble .badge-thematique i:before {
  font-size: 18px;
  top: auto !important;
  margin-right: 0;
}

.tooltip-inner {
  background-color: var(--color-primary);
  box-shadow: none;
  opacity: 1 !important;
  color: var(--color-primary-2) !important;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 500;
  padding: 10px;
  max-width: 280px;
}

.tooltip.show {
  opacity: 1 !important;
}

.tooltip.bs-tooltip-right .tooltip-arrow::before {
  border-right-color: var(--color-primary) !important;
}

.tooltip.bs-tooltip-left .tooltip-arrow::before {
  border-left-color: var(--color-primary) !important;
}

.tooltip.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: var(--color-primary) !important;
}

.tooltip.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: var(--color-primary) !important;
}

.bs-tooltip-auto .tooltip-arrow:before,
.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow:before,
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow:before,
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow:before,
.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow:before,
.bs-tooltip-end .tooltip-arrow:before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-color: var(--color-primary) !important;
}

.thematique {
  font-size: 18px;
  color: var(--color-primary-4) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-transform: uppercase;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  max-width: 100px;
  line-height: 20px;
  text-align: center;
}

.thematique i[class^="fi-rr-"]:before,
.thematique i[class*=" fi-rr-"]:before {
  font-size: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 10px;
}

.thematique img {
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: -5px;
  margin-bottom: 10px;
}

.detail-thematique h3 i:before {
  font-size: 80px;
}

.categorie {
  padding: 5px 15px;
  font-size: 18px;
  text-transform: uppercase;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  color: var(--color-white) !important;
}

.categorie.sejour {
  background: var(--color-primary-3);
}

.categorie.bafa {
  background: var(--color-terracotta);
}

.categorie.educform {
  background: #44bb97;
}

.categorie.hebergement {
  background: #d67164;
}

.categorie.sejourscolaire {
  background: #d68d64;
}

.transport {
  color: var(--color-primary-4) !important;
  position: relative;
  padding-left: 0px;
  line-height: 28px;
  font-size: 16px;
  font-weight: 600;
}

.circle {
  background: var(--color-primary-1) !important;
  border-radius: 100px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
}

.transport i[class^="fi-rr-"]:before,
.transport i[class*=" fi-rr-"]:before {
  font-size: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-right: 0px;
}

.centre {
  width: 200px;
  height: 200px;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.centre img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

ul.documents {
  padding: 30px;
  background: var(--color-bloc) !important;
}

.depart {
  position: relative;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-image: url("../img/backgrounds/bg-depart.svg");
  background-position: top;
  min-width: 263px;
}

.condition {
  color: var(--color-primary-4) !important;
}

.link-externe {
  color: var(--color-primary-4) !important;
  margin: 20px 0;
}

.information {
  color: var(--color-text) !important;
  background: var(--color-bloc) !important;
  position: relative;
  padding-left: 0px;
  line-height: 28px;
  font-size: 16px;
  font-weight: 600;
}

.information i[class^="fi-rr-"]:before,
.information i[class*=" fi-rr-"]:before {
  font-size: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-right: 0px;
}

.informations h4 {
  color: var(--color-primary-3) !important;
  padding: 5px 0;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 20px;
}

.accessibilite i[class^="fi-rr-"]:before,
.accessibilite i[class*=" fi-rr-"]:before {
  font-size: 40px;
}

.accordion h5 {
  font-family: "Barlow Condensed";
}

.accordion__item {
  border-radius: 0px;
  border: none;
  background: #e3e8e7;
}

.accordion.-map .accordion__icon {
  font-family: "Barlow Condensed" !important;
  font-style: normal;
  font-weight: 600;
}

.accordion-button:not(.collapsed)::after {
  background-image: none;
}

.accordion-button::after {
  width: auto;
  height: auto;
  background-image: none;
  font-family: "uicons-bold-straight" !important;
  content: "\f150";
  font-size: 20px;
}

.total {
  font-size: 24px;
  color: var(--color-terracotta) !important;
  text-transform: uppercase;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  line-height: 26px;
}

.confirmation p {
  font-family: "Sen", sans-serif;
  font-weight: 700;
}

.confirmation .bg-bloc {
  background-color: #eaefef !important;
}

.confirmation,
.confirmation .info {
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
}

.confirmation h5 {
  color: var(--color-primary-4);
  font-size: 18px;
  font-style: normal;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
}

.confirmation .total {
  font-size: 20px;
  color: var(--color-primary) !important;
  text-transform: uppercase;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  line-height: 26px;
}

.confirmation .solde {
  font-size: 16px;
  color: var(--color-primary-3) !important;
  text-transform: uppercase;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  line-height: 26px;
}

.col-auto.option {
  max-width: 440px;
}

.suggestions {
  position: relative;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-image: url(../img/backgrounds/bg-wave-light.svg);
  background-position: 0px 0px;
  padding-top: 100px;
  margin-top: 100px;
}

.etape {
  font-size: 18px;
  color: var(--color-primary-4) !important;
  text-transform: uppercase;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
}

.etape .col-auto {
  line-height: 22px;
}

.etape .actif {
  color: var(--color-primary-3) !important;
}

.etape .actif .rounded-full {
  background: var(--color-primary-2) !important;
}

.etape .inactif {
  color: var(--color-primary-2) !important;
}

.etape .inactif .rounded-full {
  background: var(--color-primary-1) !important;
  color: var(--color-primary-2);
}

.etape .courant .rounded-full {
  background: var(--color-primary-4) !important;
}

.etape-line {
  border-bottom: 2px dashed var(--color-primary-2) !important;
}

.minibloc {
  max-width: 250px;
}

.page {
  border: 1px solid #c0d5da;
  border-radius: 4px;
  padding: 0 8px;
  margin-top: 5px;
}

.commodites {
  padding: 30px;
  background: var(--color-bloc);
}

.commodites .divider {
  margin: 30px 0;
}

.commodites h4 {
  margin: 20px 0;
  font-size: 30px;
}

.commodites i:before {
  font-size: 50px;
  padding-right: 10px;
}

.commodites .lesplus {
  color: var(--color-ocre-dark) !important;
}

.commodites .lesplus h4,
.commodites .lesplus ul li {
  color: var(--color-ocre-dark) !important;
}

.step {
  padding: 15px 40px;
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 26px;
  color: var(--color-primary-4) !important;
  text-transform: uppercase;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
}

.accordion-button i[class*=" fi-ss-"]:before {
  vertical-align: middle;
  top: -2px;
  position: relative;
  font-size: 40px;
  margin-right: 8px;
}

.processus-reservation .heading .curve {
  position: relative;
  display: block;
  height: 140px;
  bottom: -140px;
  margin-top: -140px;
  overflow: hidden;
}

.processus-reservation .steps i[class*=" fi-ss-"]:before {
  vertical-align: middle;
  top: 0;
  position: relative;
  font-size: 36px;
}

.processus-reservation .steps .divider {
  margin: 25px auto 20px auto;
  padding: 0px;
  width: calc(100% - 2px);
}

.processus-reservation .info.payeur {
  color: var(--color-light-1);
  font-size: 13px;
  line-height: 15px;
  display: inline-block;
  margin-top: 10px;
}

.processus-reservation .titre-participant {
  font-family: "Barlow Condensed";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  text-transform: uppercase;
  padding: 5px 20px;
  background: var(--color-primary-4) !important;
  color: var(--color-white) !important;
  margin: 30px 0;
}

.accordion-button:focus {
  z-index: 3;
  border-color: transparent;
  outline: 0;
  box-shadow: none;
}

.accordion-button:not(.collapsed) {
  color: var(--color-primary-4) !important;
  background-color: var(--color-primary-2);
  box-shadow: none;
}

.accordion-item {
  background-color: #fff;
  border: none;
}

.processus-reservation h2.accordion-header {
  margin-bottom: 0;
  line-height: 30px;
}

.processus-reservation .connexion {
  display: grid;
  grid-template-columns: 1fr 1fr auto;
  gap: 20px;
  row-gap: 10px;
}

.processus-reservation h3:not(.tag-title) {
  font-size: 22px;
  color: var(--color-primary-3) !important;
  padding-bottom: 0;
  margin-bottom: 10px;
  padding-top: 10px;
}

.processus-reservation .steps .form-input label {
  top: 22px;
}

.recrutement .form-input label {
  top: 22px;
}

.recrutement .encart .form-input label {
  top: 30px;
}

.processus-reservation .steps .form-input .date {
  position: absolute;
  right: 15px;
  top: 15px;
}

.processus-reservation .steps .form-input .date i:before {
  font-size: 24px;
}

.processus-reservation .encart .titre,
.publication .encart .titre {
  padding: 15px 30px;
  background: var(--color-primary-4) !important;
  color: var(--color-white) !important;
  margin-bottom: 20px;
}

.processus-reservation .encart h5,
.publication .encart h5 {
  color: var(--color-primary-3);
  font-family: "Barlow Condensed";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}

.processus-reservation .encart .tarif,
.publication .encart .tarif {
  font-family: "Barlow Condensed";
  font-style: normal;
  font-weight: 700;
  color: var(--color-primary-4) !important;
}

.processus-reservation .encart .info,
.publication .encart .info {
  font-family: "Barlow Condensed";
  font-style: normal;
  font-weight: 700;
}

.processus-reservation .encart .remise,
.publication .encart .remise {
  color: var(--color-primary-3) !important;
}

.obligatoire {
  color: var(--color-terracotta) !important;
}

.processus-reservation .steps .select.small button {
  border-radius: 0px;
  border: none;
  background: #e3e8e7;
  padding: 22px 15px 22px 15px;
  line-height: 10px;
  height: auto;
}

.processus-reservation .steps .select.small .js-button-title {
  color: var(--color-primary-4);
  font-family: "Barlow Condensed";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  padding-top: 20px;
}

.processus-reservation .steps .select.small .js-button-title {
  padding-top: 0;
}

.processus-reservation.devis .select button {
  height: 63px;
}

.processus-reservation.devis .link-externe {
  color: var(--color-primary-l) !important;
  margin: 20px 0;
  background-color: var(--color-primary-4) !important;
}

.processus-reservation.devis .link-externe h5 {
  color: var(--color-primary-1) !important;
  padding: 5px 20px;
  font-size: 18px;
}

/********** MAP **********/
#map {
  height: 500px;
  width: 100%;
  clip-path: ellipse(100% 55% at 48% 44%);
  margin: 0;
  transition: 0.3s;
}

#map.itinerance {
  height: 100%;
  width: 100%;
  margin: 0;
  clip-path: none;
  min-height: 300px;
}

.vignette.map-info .gclose {
  width: 30px;
}

.vignette.map-info h4 {
  font-size: 24px;
  line-height: 18px !important;
  margin: 0;
  font-size: 18px !important;
}

.gm-style-moc {
  opacity: 0 !important;
}

.gm-style-iw-t {
  right: unset !important;
  left: 0 !important;
  bottom: 53px !important;
}

.gm-style-iw-chr {
  display: none;
}

.gm-style .gm-style-iw-d {
  padding-top: 10px;
  padding-bottom: 10px;
  overflow: auto !important;
  max-width: 400px;
}

.gm-style .gm-style-iw-c {
  background-color: var(--color-white) !important;
  box-shadow: 0 2px 8px 1px rgba(0, 0, 0, 0.1) !important;
  border-radius: 0px !important;
  cursor: pointer !important;
}

.zoomin {
  background: var(--color-primary-4) !important;
  padding: 12px !important;
  color: white !important;
  border: none !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  margin-right: 20px;
  margin-bottom: 0px;
}

.zoomout {
  background: var(--color-primary-4) !important;
  padding: 12px !important;
  color: white !important;
  border: none !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  margin-right: 20px;
  margin-bottom: 60px;
}

.lieu .zoomin,
.hebergements .zoomin {
  margin-right: 20px;
  margin-bottom: 0px;
}

.lieu .zoomout,
.hebergements .zoomout {
  margin-right: 20px;
  margin-bottom: 10px;
}

.zoomin:hover,
.zoomout:hover {
  box-shadow: inset 0 0 100px 100px rgba(0, 0, 0, 0.15) !important;
}

.zoomin i[class^="fi-rr-"]:before,
.zoomin i[class*=" fi-rr-"]:before,
.zoomin span[class^="fi-rr-"]:before,
.zoomin span[class*="fi-rr-"]:before,
.zoomout i[class^="fi-rr-"]:before,
.zoomout i[class*=" fi-rr-"]:before,
.zoomout span[class^="fi-rr-"]:before,
.zoomout span[class*="fi-rr-"]:before,
.zoomin i[class*="fi-bs-"]:before,
.zoomout i[class*="fi-bs-"]:before {
  margin-right: 0px;
  top: 0;
  font-size: 16px;
}

/******* VIGNETTES ********/
.swiper-slide {
  background-color: white;
  width: 300px !important;
  height: auto;
}

.swiper-autoheight .swiper-slide {
  height: 100%;
}

.swiper-slide p,
.swiper-slide p i {
  color: var(--color-light-1);
}

.swiper-slide h4 {
  color: var(--color-primary-4) !important;
}

p.last-chance {
  color: var(--color-ocre-dark) !important;
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 8px !important;
  border: 1px solid var(--color-ocre-dark);
  padding: 2px 10px;
  border-radius: 50px;
  width: 175px;
}

.encart p.last-chance {
  color: var(--color-ocre-dark) !important;
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 8px !important;
  border: 1px solid var(--color-ocre-dark);
  padding: 2px 10px;
  border-radius: 50px;
  width: 100%;
  text-align: center;
}

.swiper-slide p.last-chance,
main .informatif .swiper-slide p.last-chance {
  color: var(--color-ocre-dark) !important;
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 8px !important;
  border: 1px solid var(--color-ocre-dark);
  padding: 2px 10px;
  border-radius: 50px;
  width: 175px;
}

main .informatif .swiper-slide p .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: var(--color-primary-4) !important;
  color: var(--color-primary-l);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}

.nav-pills h5 {
  font-family: "Barlow Condensed";
}

.nav-pills .nav-link.active h5 {
  font-family: "Barlow Condensed";
  color: var(--color-white);
}

.nav-pills .accordion__icon {
  background-color: var(--color-primary-4) !important;
  color: var(--color-white);
  font-family: "Barlow Condensed" !important;
  font-style: normal;
  font-weight: 600;
}

.nav-pills .nav-link.active .accordion__icon {
  background-color: var(--color-white) !important;
  color: var(--color-primary-4);
}

.flex-parent {
  display: flex;
}

.flex-item {
  width: 100%;
}

.hebergements .photo-centre {
  width: 100%;
  max-height: 400px;
  object-fit: cover;
  aspect-ratio: 1 / 1;
}

.vignette {
  font-family: "Barlow Condensed", sans-serif;
}

.resultats .vignette,
.resultats-multi .vignette {
  font-family: "Barlow Condensed", sans-serif;
  background-color: #f3f4f4;
  height: 100%;
  display: block;
  transition: 0.3s;
}

.resultats .vignette,
.resultats-multi .vignette:hover {
  background-color: #f8f8f8;
  transition: 0.3s;
}

.vignette .info {
  color: var(--color-light-1);
  font-size: 12px;
  line-height: 13px;
  width: 80px;
  text-align: right;
  margin-top: -5px;
}

.resultats-multi .swiper-slide {
  background-color: #f3f4f4;
}

.resultats-multi.thematiques .swiper-slide {
  background-color: transparent;
  width: 150px !important;
}

.resultats-multi.thematiques .info {
  line-height: 17px;
  display: block;
  text-align: center;
}

.resultats-multi.thematiques .swiper-slide .info {
  line-height: 17px;
  display: inline-block;
  text-align: center;
}

.resultats-multi h3 {
  color: var(--color-primary-4) !important;
}

.sejourscolaire .resultats-multi h3 {
  color: var(--color-ocre-dark) !important;
}

.sejourscolaire .hebergements h4 {
  font-size: 24px;
  color: var(--color-primary-3) !important;
  text-transform: uppercase;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  line-height: 0.8;
  margin: 10px 0 20px 0;
}

.resultats-multi h3.tag-title {
  color: #f0f0f0 !important;
}

.resultats-multi h4 {
  font-size: 18px;
  color: var(--color-primary-3) !important;
  text-transform: uppercase;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  line-height: 0.8;
  margin-top: 5px;
}

.resultats-multi .mainSearch h4 {
  color: var(--color-primary-4) !important;
}

#resultats-contenu .titre h4 {
  font-size: 18px;
  color: var(--color-primary-3) !important;
  text-transform: uppercase;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  line-height: 0.8;
  margin-top: 5px;
}

.resultats-multi .vignette h4 {
  color: var(--color-primary-4) !important;
  margin: 0;
  padding: 10px 0;
}

.resultats.recrutement h4 {
  font-size: 18px;
}

.resultats-unitaire.hebergement .heading .curve {
  z-index: 1;
}

.recrutement h2 {
  color: var(--color-primary-3) !important;
}

.recrutement .vignette .typeposte {
  color: var(--color-ocre-dark) !important;
  padding: 4px 0;
}

.suggestions .vignette h4 {
  margin: 0;
}

.actualites .vignette h4 {
  margin: 15px 0 10px 0;
  color: #f1f5e2 !important;
}

.actualites .swiper-slide {
  background-color: transparent;
  width: 300px !important;
  height: auto;
}

.actualites .swiper-slide p {
  line-height: 18px;
  color: var(--color-primary-2) !important;
  display: -webkit-box;
  line-clamp: 5;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.actualites .swiper-slide p {
  color: var(--color-primary-2) !important;
  margin: 10px 0;
  line-height: 24px;
  font-family: "Sen", sans-serif;
  font-size: 14px;
  line-height: 22px;
  overflow: hidden;
}

.actualites .swiper-slide p.auteur {
  line-height: 18px;
  color: var(--color-primary-3) !important;
  margin: 10px 0;
}

.actualites .swiper-slide p.auteur i {
  color: var(--color-primary-3) !important;
}

.actualites .swiper-slide .bandeau {
  background: transparent !important;
}

.actualites .swiper-slide .tag {
  background: var(--color-primary-3) !important;
}

.actualites .swiper-slide .tag-tail:before,
.actualites .swiper-slide .tag-left .tag-tail:before {
  border-right: 10px solid transparent;
  border-top: 15px solid var(--color-primary-3) !important;
}

.actualites .swiper-slide .tag-tail:after,
.actualites .swiper-slide .tag-left .tag-tail:after {
  border-right: 10px solid transparent;
  border-bottom: 15px solid var(--color-primary-3) !important;
}

.actualites .swiper-slide .vignette__content {
  padding: 0;
}

.actualites .vignette.-type-1 .vignette__image {
  overflow: hidden;
  border-radius: 0px;
  position: relative;
  height: 200px;
}

.informatif .actualites .vignette h4 {
  margin: 15px 0 10px 0;
  color: var(--color-primary-3) !important;
}

.informatif .actualites .swiper-slide p {
  color: var(--color-light-1) !important;
}

.informatif .actualites .swiper-slide .tag {
  background: var(--color-primary-3) !important;
  color: white !important;
}

.informatif .actualites .swiper-slide h3.tag-title {
  color: white !important;
}

.educform .vignette h4,
.recrutement .vignette h4 {
  margin: 0;
  padding-bottom: 0px !important;
}

.educform .vignette h4 {
  margin: 5px 0;
}

.recrutement .swiper-slide h4 {
  font-size: 18px;
}

.vignette.-type-1 .vignette__image {
  overflow: hidden;
  border-radius: 0px;
  position: relative;
  height: 250px;
}

.vignette__content {
  padding: 0 25px;
  height: calc(100% - 35px);
  padding-bottom: 20px !important;
}

.vignette.-type-1:has(> .vignette__image img) .vignette__content {
  height: calc(100% - 250px);
}

.actualites .vignette.-type-1:has(> .vignette__image img) .vignette__content {
  height: calc(100% - 200px);
}

.vignette.-type-1 .vignette__image .cardImage__content .swiper-slide,
.vignette.-type-1 .vignette__image .cardImage__content > img {
  transition: all 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.vignette .cardImage {
  width: 100%;
  height: 100%;
}

.vignette .cardImage__wishlist div {
  padding: 2px 10px;
  background: var(--color-primary-3) !important;
  color: var(--color-white) !important;
  font-size: 13px;
  border-radius: 100px;
}

.vignette .cardImage__wishlist {
  position: absolute;
  top: 20px;
  right: 20px;
  width: auto;
  left: auto;
}

.vignette.map-info {
  position: relative;
}

.vignette.map-info .content {
  padding-right: 10px;
}

.vignette.map-info i {
  margin-top: -3px;
}

.vignette.map-info .tag {
  position: absolute;
  top: 10px;
}

.vignette.map-info .tarif {
  font-size: 15px;
}

.vignette.map-info .type {
  padding: 2px 10px;
  font-size: 13px;
  text-transform: uppercase;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  color: var(--color-primary-3) !important;
  border: 2px solid var(--color-primary-3) !important;
  border-radius: 4px;
  display: inline-block;
  line-height: 17px;
}

.vignette.map-info .lieu {
  padding: 5px 0;
  font-size: 14px;
  text-transform: none;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  line-height: 14px;
}

.tag {
  min-width: 50px;
  height: 30px;
  background-color: var(--color-ocre-dark) !important;
  position: relative;
  padding: 0 10px;
  display: inline-block;
}

.tag-title {
  line-height: 30px;
  color: #fff;
  font-size: 15px;
}

.tag-title i:before {
  color: #fff;
}

.tag-tail,
.tag-left .tag-tail {
  position: absolute;
  top: 0;
  right: -10px;
}

.tag-center .tag-tail-left {
  position: absolute;
  top: 0;
  left: -10px;
}

[class^="tag-tail"]:after,
[class^="tag-tail"].tag-tail:before {
  content: "";
  width: 0;
  height: 0;
  display: block;
}

.tag-tail:after,
.tag-left .tag-tail:after {
  border-right: 10px solid transparent;
  border-bottom: 15px solid var(--color-ocre-dark) !important;
}

.tag-tail:before,
.tag-left .tag-tail:before {
  border-right: 10px solid transparent;
  border-top: 15px solid var(--color-ocre-dark) !important;
}

.vignette.-type-1 .vignette__image .swiper-slide-active {
  position: relative;
  z-index: 2;
}

.vignette.-type-1 .vignette__title span {
  background-image: linear-gradient(transparent 24px, black 20px);
  background-size: 0;
  background-repeat: no-repeat;
  transition: background-size 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
}

.vignette.-type-1:hover .vignette__image .cardImage__content > img,
.vignette.-type-1:hover .vignette__image .swiper-slide {
  transform: scale(1.15);
}

.vignette.-type-1:hover .vignette__title span {
  background-size: 100% !important;
}

.vignette .bandeau {
  font-size: 14px;
  padding: 5px 25px;
  background: var(--color-primary-3) !important;
  color: var(--color-white) !important;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.resultats-multi.thematiques .bubble {
  text-align: center;
  line-height: 18px;
  font-size: 16px;
  padding: 20px 15px;
  text-transform: none;
}

.resultats-multi.thematiques .bubble i[class^="fi-rr-"]:before,
.resultats-multi.thematiques .bubble i[class*=" fi-rr-"]:before {
  top: -8px;
}

.vignette i[class^="fi-rr-"]:before,
.vignette i[class*=" fi-rr-"]:before {
  vertical-align: middle;
  top: -2px;
  position: relative;
  margin-right: 5px;
  font-size: inherit;
}

.vignette .organisation i[class^="fi-rr-"]:before,
.vignette .organisation i[class*=" fi-rr-"]:before {
  vertical-align: middle;
  top: 0px;
  position: relative;
  margin-right: 0px;
  font-size: 10px;
}

.vignette h4 {
  font-size: 24px;
  line-height: 22px !important;
  margin: 0;
}

.vignette .type {
  padding: 5px 15px;
  font-size: 15px;
  text-transform: uppercase;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  color: var(--color-primary-3) !important;
  border: 2px solid var(--color-primary-3) !important;
  border-radius: 4px;
  display: inline-block;
  line-height: 17px;
}

.vignette .tarif {
  font-size: 16px;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  color: var(--color-terracotta) !important;
}

.vignette .lieudepart {
  font-size: 13px;
  font-weight: 700;
  color: var(--color-primary-4) !important;
  font-family: "Barlow Condensed", sans-serif;
  line-height: 15px;
}

.vignette .modalite {
  font-size: 15px;
  font-weight: 700;
  color: #d68d64 !important;
  font-family: "Barlow Condensed", sans-serif;
}

.vignette .organisation {
  font-size: 15px;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  color: var(--color-primary-3) !important;
  margin-top: 10px;
}

.vignette .partenariat {
  font-size: 11px;
  font-weight: 500;
  color: var(--color-light-1) !important;
  line-height: 1;
  font-style: italic;
  margin-top: 0px;
}

.vignette .partenariat a {
  font-size: 11px;
  font-weight: 500;
  color: var(--color-light-1) !important;
  line-height: 1;
  font-style: italic;
  text-decoration: underline;
  margin-top: 0px;
}

.vignette ul.equipements li {
  color: var(--color-primary-3) !important;
  list-style: none;
  position: relative;
  padding-left: 20px;
  line-height: 20px;
  font-size: 14px;
  font-weight: 700;
}

.vignette ul.equipements li:before {
  font-family: uicons-regular-rounded !important;
  position: absolute;
  left: -7px;
  color: #d67164 !important;
  font-size: 26px;
  content: "\f305";
}

.pagination.-dots .pagination__item.is-active {
  background-color: var(--color-primary-4);
  transform: scale(1);
  margin-top: 10px;
}

.pagination.-dots .pagination__item {
  margin-top: 10px;
}

.vignettes-nav {
  margin-top: 10px;
  color: var(--color-primary-3);
}

.vignettes-nav.swiper-button-disabled {
  color: var(--color-border);
  transform: none;
}

.vignettes-nav.arrow-right-hover:hover,
.vignettes-nav.arrow-left-hover:hover {
  color: var(--color-primary-4);
  transform: translateX(5px) scale(1.25);
}

.vignettes-nav.swiper-button-disabled.arrow-right-hover:hover,
.vignettes-nav.swiper-button-disabled.arrow-left-hover:hover {
  color: var(--color-border);
  transform: none;
}

.bafa .vignette .bandeau {
  background: var(--color-terracotta) !important;
}

.bafad .thematique {
  padding: 15px;
  font-size: 30px;
  text-transform: uppercase;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  color: var(--color-primary-3) !important;
  border: 2px solid var(--color-primary-3) !important;
  border-radius: 10px;
  width: auto;
  display: inline-block;
  max-width: fit-content;
  margin-top: 8px;
}

.bafad .dateformation {
  border: none;
  background: #e3e8e7;
  padding: 12px 15px;
  color: var(--color-primary-4);
  font-family: "Barlow Condensed";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 15px;
  line-height: 21px;
}

.bafad .dateformation .duree {
  color: var(--color-primary-3);
  font-family: "Barlow Condensed";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}

.scolaire .vignette .bandeau {
  background: #d68d64 !important;
}

.hebergement .vignette .bandeau {
  background: #d67164 !important;
}

.recrutement .vignette .bandeau {
  background: #5e6d92 !important;
}

.educform .vignette .bandeau.citoyennete {
  background: #44bb97 !important;
}

.educform .vignette .bandeau.bienetre {
  background: #44bb97 !important;
}

.vignette .type.citoyennete {
  background: #74b7cf !important;
  border: 2px solid #74b7cf !important;
  color: white !important;
}

.vignette .type.bienetre {
  background: #a4bf59 !important;
  border: 2px solid #a4bf59 !important;
  color: white !important;
}

.educform .vignette .bandeau.animation {
  background: #d68d64 !important;
}

.vignette .type.animation {
  color: #d68d64 !important;
  border: 2px solid #d68d64 !important;
}

.educform .vignette.-type-1 .vignette__image,
.recrutement .vignette.-type-1 .vignette__image {
  height: 35px;
}

.recrutement .vignette .type {
  color: #5e6d92 !important;
  border: 2px solid #5e6d92 !important;
}

.recrutement .vignette .date {
  color: var(--color-primary-3) !important;
}

.resultats.actualites .vignette {
  background-color: transparent;
  padding-bottom: 20px;
  width: auto !important;
  height: auto;
}

.resultats.actualites .vignette .vignette__content {
  padding: 0;
}

.resultats.actualites .vignette h4 {
  margin: 15px 0 10px 0;
  color: var(--color-primary-4) !important;
}

.resultats.actualites.publications .vignette h4 {
  margin: 0;
}

.resultats.actualites .vignette p {
  color: var(--color-light-1) !important;
  margin: 10px 0;
  line-height: 24px;
  font-family: "Sen", sans-serif;
  font-size: 14px;
  line-height: 22px;
  display: -webkit-box;
  line-clamp: 5;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.resultats.actualites .vignette p.auteur {
  color: var(--color-primary-3) !important;
  margin: 5px 0;
  font-family: "Barlow condensed", sans-serif;
  font-size: 16px;
}

.resultats.actualites .vignette p.auteur i:before {
  color: var(--color-primary-3) !important;
  margin: 5px 0;
}

.actualites .tag {
  background: var(--color-primary-3) !important;
}

.actualites .tag-tail:before,
.actualites .tag-left .tag-tail:before {
  border-right: 10px solid transparent;
  border-top: 15px solid var(--color-primary-3) !important;
}

.actualites .tag-tail:after,
.actualites .tag-left .tag-tail:after {
  border-right: 10px solid transparent;
  border-bottom: 15px solid var(--color-primary-3) !important;
}

/******* TABLES ******/
table.table-2 td:last-child {
  text-align: left;
  padding-right: 0;
  max-width: 250px;
}

table.table-2 th:last-child {
  text-align: left;
  padding-right: 0;
  max-width: 250px;
}

table.table-4 tbody tr:first-child {
  background-color: #e4f0ef !important;
}

/******* ESPACE ENSEIGNANT ******/
.enseignant .nav-pills .nav-link {
  border-radius: 0;
  background: var(--color-bloc);
  margin-right: 10px;
}

.enseignant .nav-pills .accordion__icon {
  background-color: transparent !important;
  color: var(--color-primary-4);
}

.enseignant .nav-pills .accordion__icon i[class*=" fi-rr-"]:before {
  background-color: transparent !important;
  color: var(--color-primary-4);
  font-size: 22px !important;
}

.enseignant .nav-pills .nav-link.active .accordion__icon {
  background-color: transparent !important;
  color: var(--color-primary-1);
}

.enseignant .nav-pills .nav-link.active .accordion__icon i[class*=" fi-rr-"]:before {
  background-color: transparent !important;
  color: var(--color-primary-1);
  font-size: 22px !important;
}

/******* ANIMATIONS ********/
[data-anim^="slide-up"],
[data-anim-child^="slide-up"] {
  transform: translate3d(0, 60px, 0);
}

/****** MODAL ***********/
.modal-footer {
  border-top: none;
  background: #eaf2f2;
  border-radius: 0;
  padding: 10px 20px !important;
}

.modal-footer button.button-outline {
  text-transform: uppercase;
  font-size: 14px;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  color: var(--color-primary-4) !important;
  border: 2px solid var(--color-primary-4) !important;
  padding: 8px 20px;
  border-radius: 200px;
  transition: 0.3s;
  white-space: nowrap;
  text-align: center;
  margin: 5px 0;
}

.modal-footer button.button-outline:hover {
  color: var(--color-white) !important;
}

.modal-footer button.button-primary {
  padding: 8px 20px;
  border-radius: 200px;
  transition: 0.3s;
  white-space: nowrap;
  text-align: center;
  margin: 5px 0;
}

.modal .form-input label {
  top: 22px;
}

.modal-header {
  border-bottom: none;
  border-radius: 0;
}

.modal-content {
  border-radius: 0;
}

/****** FEEDBACKS ********/
.alert-success p,
.alert-success h4 {
  color: var(--color-primary-4) !important;
  margin-bottom: 0;
  line-height: 22px;
}

.alert-danger h4,
.alert-danger p {
  color: var(--color-terracotta) !important;
  margin-bottom: 0;
}

.alert-info {
  background: var(--color-primary-1) !important;
}

.alert-info h4,
.alert-info p {
  color: var(--color-primary-4) !important;
  margin-bottom: 0;
}

.alert-success,
.alert-danger,
.alter-warning,
.alert-info {
  margin-top: 40px;
  border-radius: 0;
  border: none;
  padding: 30px;
}

.f-requis {
  color: var(--color-terracotta) !important;
  font-size: 14px;
  font-family: "Barlow Condensed";
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.encart .f-requis {
  padding: 0;
  margin-top: -10px;
  margin-left: 15px;
  margin-bottom: 6px;
}

.hebergement .encart .f-requis {
  padding: 0;
  margin-top: -5px;
  margin-left: 15px;
  margin-bottom: 6px;
}

.hebergement .encart .select.libelle label {
  display: inline-block;
  position: absolute;
  top: 4px;
  color: var(--color-primary-3);
  font-family: "Barlow Condensed";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}

.hebergement .encart .select.libelle .js-button-title {
  color: var(--color-primary-4);
  font-family: "Barlow Condensed";
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  padding-top: 22px;
  line-height: 15px;
}

input[type="number"]::-webkit-inner-spin-button {
  margin-top: -10px !important;
  color: var(--color-primary-4) !important;
}

.form-input input.requis,
.form-input textarea.requis {
  border: 2px solid var(--color-terracotta) !important;
}

.input-group.form-input .btn-primary {
  background: var(--color-primary-4) !important;
  color: var(--color-white) !important;
  margin-bottom: 0;
  font-size: 16px;
  text-transform: uppercase;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  line-height: 0.8;
  border-radius: 0;
  border: none;
  padding: 20px;
}

.input-group.form-input .btn-primary:hover {
  box-shadow: inset 0 0 100px 100px rgba(0, 0, 0, 0.15);
}

.select button.requis {
  border: 2px solid var(--color-terracotta) !important;
}

.select.bafa {
  border-radius: 0px;
  border: none;
  background: #e3e8e7;
  padding: 10px 15px 10px 15px;
}

.select.libelle .title {
  color: var(--color-primary-4);
  font-family: "Barlow Condensed";
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  display: block;
  line-height: 25px;
  padding-top: 0;
}

.hebergement .select.requis {
  border: 2px solid var(--color-terracotta) !important;
}

.select.requis {
  border: 2px solid var(--color-terracotta) !important;
}

.checkbox-wrapper label.requis {
  color: var(--color-terracotta);
}

.radio-wrapper label.requis {
  color: var(--color-terracotta);
}

.checkbox-wrapper label.requis span {
  border: 2px solid var(--color-terracotta);
}

.radio-wrapper label.requis span {
  border: 2px solid var(--color-terracotta);
}

button.appliquer {
  width: 120px !important;
  line-height: 40px;
  border-radius: 0;
}

.form-input.appliquer {
  min-width: 260px;
}

/****** RESEAUX SOCIAUX ********/
#app {
  background: red !important;
  color: red !important;
}

blockquote.twitter-tweet {
  display: inline-block;
  font-family: "Barlow condensed", sans-serif !important;
  color: red !important;
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  border-color: transparent;
  border-radius: 0px !important;
  border-style: none;
  border-width: 0px;
  box-shadow: none;
  margin: 10px 5px;
  padding: 0 16px 16px 16px;
  max-width: 468px;
}

blockquote.twitter-tweet p {
  font-size: 16px;
  font-weight: normal;
  line-height: 20px;
}

blockquote.twitter-tweet a {
  color: inherit;
  font-weight: normal;
  text-decoration: none;
  outline: 0 none;
}

blockquote.twitter-tweet a:hover,
blockquote.twitter-tweet a:focus {
  text-decoration: underline;
}

.fb_iframe_widget {
  border: 10px solid var(--color-primary-3) !important;
  border-radius: 20px !important;
}

._2lqg {
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  border: none !important;
  padding: 20px;
}

._1drm {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  border: none !important;
  padding: 20px;
}

._1dro ._1drp {
  color: #1b706a;
  display: inline-block;
  font-size: 18px;
  font-weight: 700 !important;
  line-height: 1.358;
  margin-bottom: -5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

._1drm ._1drn {
  border: none;
  margin-right: 5px;
}

._1dro ._1drq {
  color: #5f9c99 !important;
  font-size: 12px;
  line-height: 1.358;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/******* ADAPTATIONS RESPONSIVE ********/
/******* MIN ********/
@media screen and (min-width: 576px) {
  .footer .container,
  .footer .container-sm {
    padding-left: 60px;
    padding-right: 60px;
  }
  .header.is-menu-opened .container {
    max-width: inherit;
    margin: 0;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-none {
    display: none !important;
  }
}

@media screen and (min-width: 768px) {
  .footer .container,
  .footer .container-sm {
    padding-left: 60px;
    padding-right: 60px;
  }
  .header.is-menu-opened .container {
    max-width: inherit;
    margin: 0;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-none {
    display: none !important;
  }
  .glightbox-clean .gslide-media {
    -webkit-box-shadow: 1px 2px 9px 0 rgba(0, 0, 0, 0.65);
    box-shadow: 1px 2px 9px 0 rgba(0, 0, 0, 0.65);
    max-width: 1200px !important;
  }
  .gslide iframe,
  .gslide video {
    max-width: 1200px !important;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
}

@media screen and (min-width: 992px) {
  .footer .container,
  .footer .container-sm {
    padding-left: 60px;
    padding-right: 60px;
  }
  .header.is-menu-opened .container {
    max-width: inherit;
    margin: 0;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-none {
    display: none !important;
  }
}

@media screen and (min-width: 1200px) {
  .d-xl-block {
    display: block !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .header .header-menu .menu .mega {
    min-width: 480px;
    width: auto;
  }
  .header .header-menu .menu .subnav {
    position: absolute;
    top: calc(100% - 15px);
    background-color: white;
    border-radius: 0px;
    color: var(--color-primary) !important;
    min-width: 240px;
    padding: 20px;
    box-shadow: none;
    opacity: 0;
    pointer-events: none;
    transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  .header .header-menu .menu .subnav > li > a {
    padding: 5px 15px;
    border-radius: 0px;
    color: var(--color-primary-3) !important;
    cursor: pointer;
    background: none;
    transition: 0.3s;
    background-color: transparent !important;
    color: var(--color-primary) !important;
  }
  .header .header-menu .menu .subnav > li > a:hover {
    padding: 5px 15px;
    border-radius: 0px;
    cursor: pointer;
    background: none;
    transition: 0.3s;
    background-color: var(--color-primary-2) !important;
    color: var(--color-primary) !important;
  }
  .header .header-menu .menu .mega__grid {
    display: grid;
    gap: 20px;
    grid-template-columns: auto auto auto auto auto;
  }
  .header .header-menu .menu .mega__grid.niv3 {
    display: grid;
    gap: 20px;
    grid-template-columns: auto auto auto;
    min-width: 580px;
  }
  .header .header-menu .menu .mega {
    position: absolute;
    top: calc(100% - 15px);
    background-color: var(--color-bloc) !important;
    border-radius: 0px;
    color: var(--color-primary) !important;
    min-width: 240px;
    padding: 20px;
    box-shadow: none;
    opacity: 0;
    pointer-events: none;
    transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  .header .header-menu .menu .icon-chevron-sm-down {
    display: none;
  }
  .header .header-menu .menu__nav > li .mega::before {
    content: "";
    position: absolute;
    top: -5px;
    left: 20px;
    width: 10px;
    height: 10px;
    background-color: var(--color-bloc) !important;
    transform: rotate(45deg);
  }
  .header .header-menu .menu .mega a {
    padding: 5px 15px;
    border-radius: 0px;
    cursor: pointer;
    background: none;
    transition: 0.3s;
    background-color: transparent !important;
    color: var(--color-primary) !important;
    line-height: 1.2;
  }
  .header .header-menu .menu .mega .n1 a {
    padding: 10px 20px 10px 15px;
    border-radius: 0px;
    color: var(--color-ocre-dark) !important;
    cursor: pointer;
    background: none;
    transition: 0.3s;
    line-height: 1.3;
    background-color: transparent !important;
  }
  .header .header-menu .menu .mega .n1 a:hover {
    color: var(--color-white) !important;
    cursor: pointer;
    background-color: var(--color-ocre-dark) !important;
  }
  .header .header-menu .menu .mega .n2 a {
    padding: 8px 15px 8px 15px;
    font-size: 15px;
  }
  .header .header-menu .menu .mega .n2 a:hover {
    cursor: pointer;
    background-color: var(--color-primary-2) !important;
  }
  .header .header-menu .menu .subnav > li.n2 a {
    padding: 5px 15px 5px 15px;
    font-size: 15px;
  }
  .header .header-menu .menu .mega .n3 a {
    padding: 5px 20px 5px 15px;
    font-size: 15px;
    border-radius: 0px;
    color: var(--color-primary-3) !important;
    cursor: pointer;
    background: none;
    transition: 0.3s;
    line-height: 1.3;
    background-color: transparent !important;
    text-transform: none;
  }
  .header .header-menu .menu .mega .n3 a:hover {
    color: var(--color-primary) !important;
  }
  .mega-menu {
    width: 100%;
    margin-top: 90px;
    padding: 10px 40px;
    background-color: var(--color-bloc) !important;
    position: fixed;
    z-index: 999999;
  }
  .mega-menu .subnav {
    background-color: var(--color-bloc) !important;
    border-radius: 0px;
    color: var(--color-primary) !important;
    min-width: 240px;
    padding: 20px;
    box-shadow: none;
    transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  .mega-menu .subnav > li > a {
    padding: 5px 15px;
    border-radius: 0px;
    color: var(--color-primary-3) !important;
    cursor: pointer;
    background: none;
    transition: 0.3s;
    background-color: transparent !important;
    color: var(--color-primary) !important;
  }
  .mega-menu .subnav > li > a:hover {
    padding: 5px 15px;
    border-radius: 0px;
    cursor: pointer;
    background: none;
    transition: 0.3s;
    background-color: var(--color-primary-2) !important;
    color: var(--color-primary) !important;
  }
  .mega-menu .mega__grid {
    display: grid;
    gap: 40px 20px;
    grid-template-columns: auto auto auto auto auto;
  }
  .mega-menu .mega__grid.c4 {
    grid-template-columns: auto auto auto auto;
  }
  .mega-menu .mega__grid.c3 {
    grid-template-columns: auto auto auto;
  }
  .mega-menu .mega__grid.c2 {
    grid-template-columns: auto auto;
  }
  .mega-menu .mega {
    top: calc(100% - 15px);
    background-color: var(--color-bloc) !important;
    border-radius: 0px;
    color: var(--color-primary) !important;
    min-width: 240px;
    padding: 25px 0;
    box-shadow: none;
    transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  .mega-menu .icon-chevron-sm-down {
    display: none;
  }
  .mega-menu__nav > li .mega::before {
    content: "";
    position: absolute;
    top: -5px;
    left: 20px;
    width: 10px;
    height: 10px;
    background-color: var(--color-bloc) !important;
    transform: rotate(45deg);
  }
  .mega-menu a {
    text-transform: uppercase;
    font-size: 16px;
    font-family: "Barlow Condensed", sans-serif;
    font-weight: 700;
  }
  .mega-menu .mega a:not(.button-primary) {
    padding: 5px 15px;
    border-radius: 0px;
    cursor: pointer;
    background: none;
    transition: 0.3s;
    background-color: transparent !important;
    color: var(--color-primary) !important;
    line-height: 1.2;
    display: inline-block;
  }
  .mega-menu .mega .n1 a {
    padding: 10px 20px 10px 15px;
    border-radius: 0px;
    color: var(--color-ocre-dark) !important;
    cursor: pointer;
    background: none;
    transition: 0.3s;
    line-height: 1.3;
    background-color: transparent !important;
    text-transform: uppercase;
    font-size: 16px;
    font-family: "Barlow Condensed", sans-serif;
    font-weight: 700;
  }
  .mega-menu .mega .n1 a:hover {
    color: var(--color-white) !important;
    cursor: pointer;
    background-color: var(--color-ocre-dark) !important;
  }
  .mega-menu .mega .n2 a {
    padding: 8px 15px 8px 15px;
    font-size: 15px;
  }
  .mega-menu .mega .n2 a:hover {
    cursor: pointer;
    background-color: var(--color-primary-3) !important;
    color: var(--color-white) !important;
  }
  .mega-menu .subnav > li.n2 a {
    padding: 5px 15px 8px 15px;
    font-size: 15px;
  }
  .mega-menu .mega .n3 a {
    padding: 5px 20px 5px 15px;
    font-size: 15px;
    border-radius: 0px;
    color: var(--color-primary-3) !important;
    cursor: pointer;
    background: none;
    transition: 0.3s;
    line-height: 1.3;
    background-color: transparent !important;
    text-transform: none;
  }
  .mega-menu .accordion.voirplus .accordion-button {
    padding: 5px 10px;
    font-size: 15px;
  }
  .mega-menu .accordion.voirplus .accordion-button::after {
    width: auto;
    height: auto;
    background-image: none;
    font-family: "uicons-bold-straight" !important;
    content: "\f150";
    font-size: 12px;
    margin-left: 5px;
    color: var(--color-light-1) !important;
  }
  .mega__item p.collapse:not(.show) {
    height: 42px !important;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .mega__item p.collapsing {
    min-height: 42px !important;
  }
  .mega-menu a.voirplus:after {
    content: "+ Voir plus";
    color: var(--color-light-1) !important;
  }
  .mega-menu a.voirplus:not(.collapsed):after {
    content: "- Voir moins";
    color: var(--color-light-1) !important;
  }
  .mega-menu a.voirplus {
    padding: 5px;
    font-size: 14px;
    color: var(--color-light-1) !important;
    text-decoration: underline;
    text-transform: none;
  }
  .mega-menu a.voirplus[aria-expanded="false"]:after {
    content: "+ Voir plus";
    color: var(--color-light-1) !important;
  }
  .mega-menu .mega a.voirplus:not(.button-primary) {
    color: var(--color-light-1) !important;
  }
  .mega__item .accordion-body {
    padding: 0;
  }
  .mega-menu .mega .n3 a:hover {
    color: var(--color-primary) !important;
  }
  .informatif .texte-punchline .container {
    max-width: 1100px;
  }
}

@media screen and (min-width: 2000px) {
  footer .container.contenu {
    background-color: var(--color-ocre);
    margin-top: 140px;
  }
}

@media screen and (min-width: 1400px) {
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-none {
    display: none !important;
  }
}

/******* MAX ********/
@media screen and (max-width: 1399.98px) {
  .encart .w-360 {
    width: auto;
  }
  .encart .ml-n10 {
    margin-left: -10px !important;
  }
}

@media screen and (max-width: 1199.98px) {
  .header .header-menu .menu .subnav a {
    height: 40px;
    font-size: 18px;
    padding: 0 20px;
    line-height: 40px;
  }
  .header.is-menu-opened {
    background-color: var(--color-primary) !important;
    border-bottom: 1px solid var(--color-primary-3);
  }
  .footer {
    padding-top: 120px;
  }
  .header {
    height: 80px;
  }
  .header .header-menu__content {
    background: var(--color-primary) !important;
  }
  .header.is-menu-opened {
    background-color: var(--color-primary) !important;
    border-bottom: 1px solid var(--color-primary-3);
  }
  .header .header-menu .menu__nav > li > a:hover {
    background-color: var(--color-primary-2) !important;
    color: var(--color-primary) !important;
    border-radius: 0;
    font-size: inherit;
  }
  .header .header-menu .menu li a {
    font-size: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .header .header-menu .menu li a:hover {
    border-radius: 0;
    font-size: 20px;
  }
  .header .header-menu .menu .subnav li {
    padding-left: 20px !important;
  }
  .header .header-menu .menu .subnav > li a {
    color: var(--color-ocre-dark) !important;
  }
  .header .header-menu .menu .subnav > li a:hover {
    background: var(--color-ocre-dark) !important;
    color: #ebe6d5 !important;
    font-size: 18px;
  }
  .header .header-menu .menu .subnav li.menu-item-has-children {
    padding-left: 40px;
  }
  .header .header-menu .menu .subnav li.menu-item-has-children .subnav li {
    padding-left: 40px;
  }
  .header .header-menu .menu .subnav li.menu-item-has-children .subnav li a {
    color: var(--color-primary-2) !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .header .header-menu .menu .subnav li.menu-item-has-children a:hover {
    font-size: 18px;
  }
  .header .header-menu .menu .subnav li.menu-item-has-children .subnav li a:hover {
    background-color: var(--color-primary-3) !important;
    color: var(--color-white) !important;
  }
  .header .header-menu .menu .subnav li.menu-item-has-children .subnav li:first-child a {
    color: var(--color-white) !important;
  }
  .header .header-menu .menu .subnav li.menu-item-has-children .subnav li:first-child a:hover {
    color: var(--color-primary) !important;
    font-size: 20px;
  }
  .header .header-menu .menu .subnav li.menu-item-has-children a:hover {
    color: var(--color-primary) !important;
  }
  .header .header-menu .menu .subnav li.menu-item-has-children .subnav li:first-child {
    padding-left: 20px;
  }
  .header .header-menu .menu .subnav__backBtn a {
    color: var(--color-white);
    background-color: transparent !important;
  }
  .header .header-menu .menu .subnav__backBtn a:hover {
    background-color: var(--color-primary-2) !important;
    color: var(--color-primary) !important;
    border-radius: 0;
    font-size: 20px;
  }
  #sejour.mainSearch .button-grid {
    display: grid;
    grid-template-columns: 1fr 150px 220px 220px auto;
  }
  #bafa.mainSearch .button-grid {
    display: grid;
    grid-template-columns: 1fr 250px 250px auto;
  }
  #educform.mainSearch .button-grid {
    display: grid;
    grid-template-columns: 1fr 260px 150px auto;
  }
  #thematique.mainSearch .button-grid {
    display: grid;
    grid-template-columns: 1fr 180px 250px auto;
  }
  a.button-primary.secteur {
    border-radius: 0;
    max-width: 300px;
    position: fixed;
    top: 10px;
    z-index: 999;
    margin-top: 70px !important;
  }
  .recherche .tabs {
    margin-top: 70px !important;
    padding-left: 220px;
  }
  .recherche.multi .tabs {
    margin-top: 70px !important;
    padding-left: 160px;
  }
  .equipe .child.libelle-region {
    margin-left: 40px;
  }
  main.actualites .recherche .tabs {
    margin-top: 70px !important;
    padding-left: 220px;
  }
  .accueil .recherche.multi .tabs {
    margin-top: 70px !important;
    padding-left: inherit;
  }
  .recherche.thematique .tabs {
    margin-top: 90px !important;
    padding-left: 160px;
  }
  .recherche .container {
    position: relative;
    max-width: fit-content;
  }
  .recherche.multi .container {
    position: relative;
    max-width: 960px;
  }
  .navscroll.recherche {
    top: 0;
  }
  .tabs a.link {
    margin: 10px;
  }
  .mega-menu.recherche .mega__grid {
    display: grid;
    gap: 40px 20px;
    grid-template-columns: auto auto auto;
  }
  main.actualites .recherche .container {
    position: relative;
    max-width: 960px;
    padding-top: 40px;
  }
  .informatif .texte-punchline .container {
    max-width: 960px;
  }
  .informatif .texte-colonne .parent .child {
    min-width: 100%;
  }
  .informatif .texte-galerie .parent:has(.child.tiny) {
    gap: 40px;
    justify-content: start;
  }
}

@media screen and (max-width: 991.98px) {
  .mainSearch {
    border-radius: 0px !important;
  }
  .accueil .mainSearch {
    width: calc(100% - 20px);
    border-radius: 0px !important;
    margin: 10px;
  }
  section.aroevens.equipe h5.selection {
    width: 100%;
    max-width: initial;
  }
  .equipe .child.libelle-region {
    margin-left: 0;
  }
  .mainSearch__submit {
    border-radius: 100px !important;
    width: 100%;
    height: 50px;
    text-transform: uppercase;
    text-align: center;
    font-size: 16px;
    font-family: "Barlow Condensed", sans-serif;
    font-weight: 700;
    color: var(--color-white) !important;
    background-color: var(--color-primary);
    padding: 10px 20px;
    transition: 0.3s;
    white-space: nowrap;
    text-align: center;
  }
  .header .header__container .container {
    padding: 10px;
  }
  .enseignant .mainSearch {
    border-radius: 100px !important;
  }
  .enseignant .mainSearch__submit {
    border-radius: 100px !important;
    padding: 10px 15px;
  }
  .footer {
    padding-top: 80px;
  }
  .galleryGrid.-type-1 {
    grid-template-columns: 1fr 1fr;
  }
  .galleryGrid.-type-1.gallery1 {
    grid-template-columns: 1fr;
  }
  .galleryGrid.-type-1 > *:nth-child(2) {
    grid-column: auto;
  }
  .encart-1 {
    margin-bottom: 0px;
  }
  .encart {
    position: relative;
    top: 0;
    margin-top: auto;
    height: auto;
    width: 100%;
    right: auto;
  }
  #sejour.mainSearch .button-grid {
    display: block;
  }
  #bafa.mainSearch .button-grid {
    display: block;
  }
  #educform.mainSearch .button-grid {
    display: block;
  }
  #thematique.mainSearch .button-grid {
    display: block;
  }
  #multi.mainSearch .button-grid {
    grid-template-columns: 1fr;
  }
  #actualites.mainSearch .button-grid {
    grid-template-columns: 1fr;
  }
  .navscroll {
    display: none;
  }
  .content.unitaire .toggle .button.masquer {
    margin-top: 40px;
  }
  .content.unitaire #resultats-filtres {
    transition: 0.3s ease;
    position: relative;
    margin-top: 0px;
    margin-bottom: 30px;
    padding-right: 0;
  }
  #toggle-carte.button.masquer {
    display: none;
  }
  .galleryGrid.-type-1.grid-educform > *:nth-child(2),
  .galleryGrid.-type-1.grid-scolaireactivite > *:nth-child(2),
  .galleryGrid.-type-1.grid-actualite > *:nth-child(2) {
    grid-column: 2 span;
  }
  .galleryGrid.gallery4 > *:nth-child(4) {
    grid-column: 2 span;
  }
  .recherche .tabs {
    padding-left: 0px;
    padding-top: 40px;
  }
  .recherche .tabs a.link {
    font-size: 15px;
    margin: 8px;
  }
  .recherche.multi .container,
  .recherche .container {
    position: relative;
    max-width: fit-content;
    padding-top: 0px;
  }
  .recherche.multi .tabs,
  .recherche.tabs {
    margin-top: 70px !important;
    padding-left: 0;
  }
  main.actualites .recherche .tabs {
    margin-top: 70px !important;
    padding-left: 0px;
    max-width: 960px;
  }
  main.actualites .recherche .container {
    position: relative;
    padding-top: 40px;
    max-width: 720px;
  }
  .processus-reservation .steps .button i[class*=" fi-ss-"]:before {
    vertical-align: middle;
    top: 0;
    position: relative;
    font-size: 20px;
  }
  .encart-1 {
    margin-bottom: 0px;
    width: 100% !important;
  }
  .encart-2 {
    margin-bottom: 0px;
    width: 100% !important;
  }
  .recrutement .recherche .container {
    max-width: 720px;
  }
  .accueil section.temoignages {
    padding-bottom: 300px;
  }
  .wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 20px 20px 0px;
    position: relative;
  }
  .enseignant .blob {
    width: auto;
    max-width: 500px;
    min-width: 360px;
  }
  .accueil .search {
    position: relative;
  }
  .mega-menu.recherche {
    display: none;
  }
  .accueil .heading .carousel-control-prev,
  .accueil .heading .carousel-control-next {
    display: none !important;
  }
  .accueil .recherche.multi .tabs {
    margin-top: 30px !important;
    padding-left: 0;
  }
  .accueil .heading .carousel-inner.full .carousel-item img {
    -o-object-fit: cover;
    object-fit: cover;
    min-height: 150vh;
  }
  .accueil #hero-video,
  .accueil .hero-video {
    height: 150vh;
    width: 100vw;
    -o-object-fit: cover;
    object-fit: cover;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0;
  }
  .accueil .slogan {
    margin: 0;
    z-index: 9;
    position: relative;
  }
  .accueil .recherche.multi {
    z-index: 9;
    position: relative;
    padding-bottom: 0;
  }
  .accueil .heading .slogan .header-logo img {
    width: calc(100% - 40px);
  }
  .accueil .recherche.multi .container,
  .accueil .recherche .container {
    padding-bottom: 100px;
  }
  .accueil .carousel .overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.55);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 150vh;
    width: 100vw;
    object-fit: cover;
    z-index: 1;
  }
  .parallax {
    background-attachment: scroll !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
  }
  .accueil section.secteur1.bafa {
    background-attachment: scroll !important;
  }
  .accueil section.secteur1.educform {
    background-attachment: scroll !important;
  }
  .accueil section.secteur3.bafa {
    background-attachment: scroll !important;
  }
  .accueil section.secteur3.educform {
    background-attachment: scroll !important;
  }
  .informatif .texte-galerie .child {
    flex-basis: 100%;
    flex: 0 0 33%;
  }
  .select__dropdown.-is-visible {
    position: relative;
  }
  .select.mini .select__dropdown.-is-visible {
    position: absolute;
  }
  .accueil .heading .carousel-inner {
    overflow: hidden;
  }
  .accueil .heading .carousel-inner .carousel-item {
    overflow: initial !important;
  }
  .informatif .texte-punchline .container {
    max-width: 720px;
  }
  .bloc-login .footer {
    margin-top: -300px;
  }
  .tri {
    padding-right: 0;
  }
  .container.sous-pages .colonnes-2 {
    -webkit-column-count: 1;
    /* Chrome/Opera, Safari */
    -moz-column-count: 1;
    /* Mozilla Firefox */
    column-count: 1;
  }
}

@media screen and (max-height: 900px) {
  .encart {
    position: relative;
    top: 0;
  }
}

@media screen and (max-width: 767.98px) {
  .container,
  .container-sm {
    max-width: 640px;
  }
  .header .header-logo img {
    max-width: 100px;
  }
  .searchbar.container.slogan {
    padding-top: 60px;
    padding-bottom: 0px;
  }
  .suggestions h4 {
    margin-bottom: 20px;
  }
  .encart-1 {
    margin-bottom: 30px;
  }
  h1 {
    font-size: 48px;
  }
  h2 {
    font-size: 38px;
  }
  .etape-line {
    display: none;
  }
  .recrutement .recherche .container {
    max-width: 640px;
  }
  .suggestions.contact {
    text-align: center;
  }
  .suggestions.contact h2 {
    text-align: center;
  }
  .suggestions.contact h3 {
    text-align: center;
  }
  .suggestions.contact h4 {
    text-align: center;
  }
  .processus-reservation .connexion {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
  }
  .galleryGrid.gallery4 > *:nth-child(1) {
    grid-column: 2 span;
  }
  .accueil .footer {
    padding-top: 60px;
  }
  .informatif .telechargements .child {
    flex: 1 0 51%;
  }
  .hebergements li.nav-item {
    width: 100%;
  }
  #map {
    clip-path: ellipse(150% 55% at 48% 44%);
  }
  .cartefrance {
    min-width: initial;
    width: calc(100% - 20px);
  }
  .enseignant .nav-item {
    width: 100%;
  }
  .enseignant .nav-item .nav-link {
    width: 100%;
  }
  .bloc-login .footer {
    margin-top: -400px;
  }
  .enseignant-image,
  .confirmation-image {
    display: none;
  }
  .bloc-login {
    align-items: start !important;
    padding-top: 120px !important;
    padding-bottom: 300px !important;
    min-height: initial;
  }
  .enseignant .bloc-login {
    align-items: start !important;
    padding-top: 60px !important;
    padding-bottom: 200px !important;
    min-height: initial;
    height: auto;
  }
  .accueil .titre h3 {
    font-size: 40px;
    line-height: 46px;
  }
  .accueil section.enseignant::before {
    width: 150%;
    left: -15%;
  }
  .accueil section.enseignant::after {
    width: 150%;
    left: -15%;
  }
  .accueil section.sejour::before {
    width: 150%;
    left: -15%;
  }
  .accueil section.sejour::after {
    width: 150%;
    left: -15%;
  }
  .curve-before {
    width: 150% !important;
    left: -25% !important;
  }
  .curve-after {
    left: -25%;
    width: 150%;
  }
  .curve-after-invert {
    left: -25%;
    width: 150%;
  }
  .informatif .texte-galerie .parent {
    justify-content: center;
  }
  .accueil .testimonials {
    font-size: 18px;
    line-height: 22px;
  }
  .accueil .testimonials.slogan {
    font-size: 22px;
    line-height: 26px;
    padding-top: 20px;
  }
  .informatif .texte-galerie .parent:has(.child.tiny) {
    justify-content: center;
  }
}

@media screen and (max-height: 800px) {
  .accueil .heading .carousel-inner.full .carousel-item img {
    -o-object-fit: cover;
    object-fit: cover;
    min-height: 200vh;
  }
  .accueil #hero-video,
  .accueil .hero-video {
    height: 200vh;
  }
}

@media screen and (max-width: 575.98px) {
  main > section:last-of-type {
    padding-bottom: 250px !important;
  }
  section.breadcrumb,
  section.heading,
  section.content {
    padding-left: 0px;
    padding-right: 0px;
  }
  .swiper-bullets {
    display: none;
  }
  .no-page__title {
    font-size: 80px;
    font-weight: 600;
    line-height: 1.2;
  }
  .accueil section.content {
    padding-left: 0px;
    padding-right: 0px;
  }
  footer .container {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 0px;
  }
  .content .button-outline,
  .content .button-primary,
  .content .button-secondary {
    width: 100%;
    display: block;
  }
  .col-auto.option {
    width: 100%;
    max-width: 100%;
  }
  .departs .col-auto {
    width: 100%;
    max-width: 100%;
  }
  .departs .depart {
    background-position: 0 -50px;
  }
  .galleryGrid.-type-1 {
    grid-template-rows: auto;
    grid-template-columns: auto;
  }
  .galleryGrid.-type-1 img {
    height: 300px;
  }
  .galleryGrid.gallery4 > *:nth-child(1) {
    grid-column: auto;
  }
  .galleryGrid.gallery4 > *:nth-child(4) {
    grid-column: auto;
  }
  .accueil a.button-primary {
    width: 100%;
  }
  .accueil section.suggestions,
  .accueil section.bafa,
  .accueil section.educform,
  .accueil section.enseignant,
  .accueil section.equipe,
  .accueil section.actualites,
  .accueil section.posts,
  .accueil section.recrutement,
  .accueil section.temoignages {
    padding-left: 20px;
    padding-right: 20px;
  }
  .accueil .wrap {
    margin: 0px;
  }
  .accueil .pagination i:before {
    font-size: 16px;
  }
  .accueil .pagination.-avatars .pagination__item::before {
    width: 30px;
    height: 30px;
    margin-top: -1px;
  }
  .blob {
    height: 400px;
  }
  .accueil .blob {
    max-height: 400px;
    max-width: none !important;
    min-width: auto !important;
    aspect-ratio: 4 / 5;
    height: auto;
    width: calc(100% + 30px) !important;
  }
  .blob-back {
    min-width: 400px;
    min-height: 400px;
  }
  .blob-back-enseignant {
    min-width: auto;
    min-height: auto;
  }
  section.aroevens.equipe h5 {
    width: 100%;
  }
  .accueil .enseignant .bubble.conventionnement {
    min-width: auto;
    min-height: auto;
    width: auto;
    height: auto;
    aspect-ratio: 1 / 1;
    max-width: 400px;
  }
  .accueil .enseignant .bubble.conventionnement .titre h4 {
    color: #ecd1a0 !important;
    font-size: 20px;
    line-height: 22px;
  }
  .accueil section.heading {
    padding-left: 0px;
    padding-right: 0px;
  }
  section.equipe .swiper-slide {
    background-color: transparent;
    width: 160px !important;
  }
  .informatif .blob {
    min-width: initial;
  }
  .informatif .texte-galerie .child {
    flex-basis: 100%;
    flex: 0 0 50%;
  }
  .accueil section.sejour::before {
    transform: translate(-9%, -100%);
    -ms-transform: translate(-9%, -100%);
    -webkit-transform: translate(-9%, -100%);
  }
  .accueil section.sejour::after {
    transform: translate(-9%, -100%);
    -ms-transform: translate(-9%, -100%);
    -webkit-transform: translate(-9%, -100%);
  }
  .accueil section.enseignant::before {
    transform: translate(-9%, -100%);
    -ms-transform: translate(-9%, -100%);
    -webkit-transform: translate(-9%, -100%);
  }
  .accueil section.enseignant::after {
    transform: translate(-9%, -100%);
    -ms-transform: translate(-9%, -100%);
    -webkit-transform: translate(-9%, -100%);
  }
  img.logos-cb {
    max-width: 100%;
    height: auto;
    margin: 10px 0 20px 0px;
  }
  .galleryGrid__item:not(:first-child) {
    display: none;
  }
  footer .container.contenu {
    margin-top: 30px;
  }
  .accueil .searchbar h1 {
    line-height: 50px;
    margin-top: 0px !important;
    font-size: 40px;
  }
  .accueil .slogan {
    margin: 20px 0 0 0;
  }
  .informatif.contact .texte-galerie .parent:has(.child) {
    gap: 30px;
    justify-content: center;
  }
}

@media screen and (max-width: 400px) {
  .informatif .texte-galerie .child {
    flex-basis: 100%;
    flex: 0 0 100%;
  }
  .header-logo.hero .baseline {
    font-size: 26px;
    width: calc(100% - 40px);
    line-height: 26px;
  }
  .header-logo.hero.federation .baseline {
    font-size: 30px;
    width: calc(100% - 40px);
  }
  .actions-header .enregistrer {
    margin-right: 0 !important;
  }
  .informatif .texte-colonne .parent .child {
    min-width: initial;
  }
}

@media screen and (min-width: 992px) and (max-width: 1400px) {
  .contenu-prestation {
    padding-right: 40px;
  }
  .titre-prestation {
    padding-right: 40px;
  }
}
